<template>
  <!-- HERO -->
  <div class="hero-section">
    <div class="information-col">
      <h1 class="heading">
         <span style="color: black;"> ALMASONS GiNi™ </span><span class="highlight-gr">WORKFLOWS</span>
      </h1>
      <h1 class="subheading">
        Transform Your Worker Apps For Speed And Ease Of Use
      </h1>
      <p class="content">
        Our workflows are modern user-friendly Android apps your workers will
        love. They are designed to be completely voice-driven or can be
        touchscreen-controlled. Our Workflows are pre-built for speed of
        deployment, but easily adapted to your unique needs and processes. Drive
        operational speed, accuracy, and safety to new levels by going Eyes-Up &
        Hands-Free with Voice.
      </p>
      <!-- <div class="button-stack">
        <ButtonPrimary
          :icon="false"
          text="schedule demo"
          iconClass="fa-regular fa-calendar"
          btnLink="/contact-us"
        />
      </div> -->
    </div>
    <div class="image-col">
      <img :src="heroImg" alt="" class="hero-img" />
    </div>
  </div>

  <!-- BANNER -->
  <div class="banner-1">
    <h1 class="heading">
      <span class="highlight-gr">GiNi™ Workflows</span> stands out with its
      commitment to simplicity, rapid deployment, and seamless compatibility
      with Android devices, presenting a user-centric approach to workflow
      management.
    </h1>
  </div>
  <!-- CARDS -->
  <div class="cards-section">
    <h1 class="heading">MAJOR WORKFLOWS</h1>
    <p class="content">
      A wide range of workflow modules covering all stages of the supply chain
      from manufacturing to distribution,<br />
      and into the retail store.
    </p>
    <div class="card-stack">
      <div class="card">
        <h4 class="card-title">MANFACTURING</h4>
        <p class="card-content">
          Receiving, JIT Inventory, Finished Goods, Maintain & Inspect, eSign
        </p>
      </div>
      <div class="card">
        <h4 class="card-title">WAREHOUSE/DC</h4>
        <p class="card-content">
          Receiving, Sorting, PutAway, Picking, Maintain & Inspect, eSign
        </p>
      </div>
      <div class="card">
        <h4 class="card-title">PACK & SHIP</h4>
        <p class="card-content">
          Palletizing, Packing, Labeling, Shipping, Maintain & Inspect, eSign
        </p>
      </div>
      <div class="card">
        <h4 class="card-title">TRANSPORT & LOGISTICS</h4>
        <p class="card-content">Loading, Maintain & Inspect, eSign</p>
      </div>
      <div class="card">
        <h4 class="card-title">RETAIL STORE</h4>
        <p class="card-content">
          Inventory, Pricing, BOPIS, Customer Service, Maintain & Inspect, eSign
        </p>
      </div>
    </div>
  </div>
  <!-- ICONS -->

  <div class="icons-section">
    <h1 class="heading">
      <span class="highlight-gr"> Almasons Workflows</span> offer guaranteed
      improvements in speed, accuracy, and safety, and are fast and easy to
      deploy.
    </h1>
    <div class="icon-stack">
      <div class="icon-box">
        <img :src="icon1" alt="" class="icon-img" />
        <h4 class="icon-title">Rapid<br />deployment</h4>
      </div>
      <div class="icon-box">
        <img :src="icon2" alt="" class="icon-img" />
        <h4 class="icon-title">Minimal IT<br />Resources</h4>
      </div>
      <div class="icon-box">
        <img :src="icon3" alt="" class="icon-img" />
        <h4 class="icon-title">Guaranteed<br />Results</h4>
      </div>
      <div class="icon-box">
        <img :src="icon4" alt="" class="icon-img" />
        <h4 class="icon-title">Faster<br />Training</h4>
      </div>
      <div class="icon-box">
        <img :src="icon5" alt="" class="icon-img" />
        <h4 class="icon-title">Deploy<br />in Weeks</h4>
      </div>
      <div class="icon-box">
        <img :src="icon6" alt="" class="icon-img" />
        <h4 class="icon-title">User<br />Friendly</h4>
      </div>
      <div class="icon-box">
        <img :src="icon7" alt="" class="icon-img" />
        <h4 class="icon-title">Android<br />Based</h4>
      </div>
    </div>
  </div>
  <!-- TABS -->
  <!-- BANNER 2 -->
  <div class="banner-2">
    <h3 class="heading">
      Our Workflows stand out from standard ERP and WMS worker screens for
      simplicity, ease of use, voice-driven options, and adaptability.
    </h3>
  </div>
  <ContactForm />
</template>

<script>
import ButtonPrimary from "@/components/ButtonPrimary.vue";
import ButtonDisable from "@/components/ButtonDisable.vue";
import ContactForm from "@/components/ContactForm.vue"

import heroImg from "../assets/workflows/hero-img.png";

import icon1 from "../assets/workflows/icon-1.png";
import icon2 from "../assets/workflows/icon-2.png";
import icon3 from "../assets/workflows/icon-3.png";
import icon4 from "../assets/workflows/icon-4.png";
import icon5 from "../assets/workflows/icon-5.png";
import icon6 from "../assets/workflows/icon-6.png";
import icon7 from "../assets/workflows/icon-7.png";

export default {
  components: {
    ButtonDisable,
    ButtonPrimary,
    ContactForm,
  },
  data() {
    return {
      heroImg: heroImg,
      icon1: icon1,
      icon2: icon2,
      icon3: icon3,
      icon4: icon4,
      icon5: icon5,
      icon6: icon6,
      icon7: icon7,
    };
  },
  mounted(){
    window.scrollTo({
            top: 0
          });
  }
};
</script>

<style scoped>
.highlight-gr {
  background: linear-gradient(90deg, #f7941d 0%, #f15b2b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button-stack {
  display: flex;
  align-items: center;
  gap: 25px;
}
</style>

<!-- HERO -->
<style scoped>
.hero-section {
  margin-top: 65px;
  padding: 60px 120px;
  /* height: calc(100vh - 200px); */
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  background: url("../assets/workflows/hero-bkg.png");
  position: relative;
  background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-section .image-col .hero-img {
  /* position: absolute; */
  width: 100%;
  top: 0;
  right: 0;
}

.hero-section .information-col {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.hero-section .information-col .heading {
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: uppercase; */
  /* background: linear-gradient(0deg, #f15b2b 14.88%, #f7941d 30.67%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: black;
}

.hero-section .information-col .subheading{
  font-family: Poppins;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: uppercase; */
  color: #000000c5 ;
}

.hero-section .information-col .content {
  color: #171717;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem; /* 160% */
}

@media screen and (max-width: 576px) {
  .hero-section {
    padding: 40px;
    grid-template-columns: 1fr;
    height: 100%;
  }

  .hero-section .image-col .hero-img {
    position: relative;
    width: 100%;
    margin-top: 30px;
  }

  .hero-section .information-col {
    gap: 20px;
  }

  .hero-section .information-col .heading {
    font-size: 24px;
  }

  .hero-section .information-col .subheading {
    font-family: Poppins;
    font-size: 18px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* text-transform: uppercase; */
    color: #000000c5;
}

  .hero-section .information-col .content {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 160% */
  }
}
</style>

<!-- BANNER 1 -->
<style scoped>
.banner-1 {
  display: none;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 120px;
  text-align: center;
}

.banner-1 .heading {
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 576px) {
  .banner-1 {
    padding: 40px;
    text-align: center;
  }

  .banner-1 .heading {
    font-size: 20px;
  }
}
</style>

<!-- CARDS -->
<style scoped>
.cards-section {
  padding: 40px 120px;
  background: url("../assets/workflows/cards-bkg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.cards-section .heading {
  color: #151515;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.cards-section .content {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.cards-section .card-stack {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 40px;
}

.cards-section .card {
  border-top: 5px solid #f7941d;
  background: #fff;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25),
    -4px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.cards-section .card .card-title {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.cards-section .card .card-content {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cards-section .card:nth-child(1) {
  grid-column: 1/3;
  grid-row: 1/2;
}
.cards-section .card:nth-child(2) {
  grid-column: 3/5;
  grid-row: 1/2;
}
.cards-section .card:nth-child(3) {
  grid-column: 5/7;
  grid-row: 1/2;
}
.cards-section .card:nth-child(4) {
  grid-column: 2/4;
  grid-row: 2/3;
}
.cards-section .card:nth-child(5) {
  grid-column: 4/6;
  grid-row: 2/3;
}

@media screen and (max-width: 576px) {
  .cards-section {
    padding: 40px;
  }

  .cards-section .heading {
    text-align: center;
    font-size: 24px;
  }

  .cards-section .content {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .cards-section .card-stack {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .cards-section .card {
    border-top: 5px solid #f7941d;
    background: #fff;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25),
      -4px 0px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 20px;
  }

  .cards-section .card .card-title {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
  }

  .cards-section .card .card-content {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .cards-section .card:nth-child(1) {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .cards-section .card:nth-child(2) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .cards-section .card:nth-child(3) {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .cards-section .card:nth-child(4) {
    grid-column: 1/2;
    grid-row: 4/5;
  }
  .cards-section .card:nth-child(5) {
    grid-column: 1/2;
    grid-row: 5/6;
  }
}
</style>

<!-- ICONS -->
<style scoped>
.icons-section {
  padding: 40px 120px;
}

.icons-section .heading {
  color: #151515;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.icons-section .icon-stack {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 40px;
  margin-top: 30px;
}

.icons-section .icon-stack .icon-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  align-items: center;
  justify-content: center;
  text-align: center;
}

.icons-section .icon-stack .icon-box .icon-title {
  color: #3f3b3b;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.icons-section .icon-stack .icon-box:nth-child(1) {
  grid-column: 1/3;
  grid-row: 1/2;
}
.icons-section .icon-stack .icon-box:nth-child(2) {
  grid-column: 3/5;
  grid-row: 1/2;
}
.icons-section .icon-stack .icon-box:nth-child(3) {
  grid-column: 5/7;
  grid-row: 1/2;
}
.icons-section .icon-stack .icon-box:nth-child(4) {
  grid-column: 7/9;
  grid-row: 1/2;
}
.icons-section .icon-stack .icon-box:nth-child(5) {
  grid-column: 2/4;
  grid-row: 2/3;
}
.icons-section .icon-stack .icon-box:nth-child(6) {
  grid-column: 4/6;
  grid-row: 2/3;
}
.icons-section .icon-stack .icon-box:nth-child(7) {
  grid-column: 6/8;
  grid-row: 2/3;
}

@media screen and (max-width: 576px) {
  .icons-section {
    padding: 40px;
  }

  .icons-section .heading {
    text-align: center;
    font-size: 24px;
  }

  .icons-section .icon-stack {
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 30px;
  }

  .icons-section .icon-stack .icon-box .icon-title {
    font-size: 20px;
  }

  .icons-section .icon-stack .icon-box:nth-child(1) {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .icons-section .icon-stack .icon-box:nth-child(2) {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .icons-section .icon-stack .icon-box:nth-child(3) {
    grid-column: 1/2;
    grid-row: 3/4;
  }
  .icons-section .icon-stack .icon-box:nth-child(4) {
    grid-column: 1/2;
    grid-row: 4/5;
  }
  .icons-section .icon-stack .icon-box:nth-child(5) {
    grid-column: 1/2;
    grid-row: 5/6;
  }
  .icons-section .icon-stack .icon-box:nth-child(6) {
    grid-column: 1/2;
    grid-row: 6/7;
  }
  .icons-section .icon-stack .icon-box:nth-child(7) {
    grid-column: 1/2;
    grid-row: 7/8;
  }
}
</style>

<!-- BANNER 2 -->
<style scoped>
.banner-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 120px;
  text-align: center;
  background: url("../assets/workflows/cards-bkg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.banner-2 .heading {
  color: #2e2e2e;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

@media screen and (max-width: 576px) {
  .banner-2 {
    padding: 40px;
  }

  .banner-2 .heading {
    font-size: 20px;
  }
}
</style>










