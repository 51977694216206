<template>
  <div class="highlighted-post">
    <div class="img-section">
      <span class="post-tag">{{ post.tag }}</span>
      <img :src="post.image" alt="" class="highlight-img" />
    </div>
    <div class="highlight-post-body">
      <div class="post-content">
        <h2 class="post-title">{{ post.title }}</h2>
        <p class="post-body">{{ post.content.slice(0,250) }}...</p>
      </div>
      <p class="date-time">
        <i class="fa-regular fa-clock"></i> {{ post.time }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    post:Object,
  },
};
</script>

<style scoped>
.highlighted-post {
  display: grid;
  grid-template-columns: 3fr 2fr;
  cursor: pointer;
}

.highlighted-post:hover .highlight-img {
  transform: scale(1.1);
}

.highlight-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.img-section {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.highlight-post-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #272827 !important;
  padding: 20px;
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.post-tag {
  position: absolute;
  top: 0px;
  /* width: 100px; */
  background: #f7941d;
  border-radius: 0 0px 30px 0;
  padding: 5px 30px 10px 10px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.post-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  opacity: 1;
}

.post-body {
  font-size: 14px;
  opacity: 0.7;
}

.date-time {
  margin-top: 5px;
  opacity: 0.4;
  font-size: 14px;
}

.fa-clock{
  margin-right: 5px;
}
</style>