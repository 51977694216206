<template>
  <router-link  :to="{ name: 'AlmasonsResourcesPost', params: { id: blog.id } }">
    <div class="blog-card">
      <span class="post-tag">{{ blog.tag }}</span>
      <div class="image-block">
        <img :src="blog.image" alt="" class="blog-img" />
      </div>
      <h5 class="blog-title">{{ blog.title }}</h5>
      <p class="blog-content">{{ blog.content.slice(0, 45) }}...</p>
      <p class="date-time">
        <i class="fa-regular fa-clock"></i> {{ blog.time }}
      </p>
    </div></router-link
  >
</template>

<script>
export default {
  props: {
    blog: Object,
  },
};
</script>

<style scoped>
.blog-card {
  position: relative;
  cursor: pointer;
}

.blog-card:hover .blog-img {
  transform: scale(1.3);
}

.image-block {
  width: 100%;
  height: 170px;
  overflow: hidden;
}

.blog-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.blog-title {
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0 5px;
  color: #272827;
}

.blog-content {
  font-size: 14px;
  color: #272827;
  opacity: 0.7;
}

.post-tag {
  position: absolute;
  top: 0px;
  /* width: 100px; */
  background: #f7941d;
  border-radius: 0 0px 30px 0;
  padding: 5px 30px 10px 10px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.date-time {
  margin-top: 5px;
  opacity: 0.4;
  font-size: 12px;
}

.fa-clock {
  margin-right: 5px;
}
</style>
