<template>
  <!-- HERO SECTION -->
  <div class="hero-section">
    <div class="information-col">
      <h1 class="heading">
         <span style="color: black;"> ALMASONS GiNi™ </span><span class="highlight-gr">VOICE</span>
      </h1>
      <h2 class="subheading"> Focus On What Matters - With A Hands-Free Approach To Your Workflows.</h2>
     
      <p class="content">
        Almasons voice-driven workflows are streamlined, easy-to-learn mobile worker apps.  These cover all major supply chain functions from Manufacturing & Warehousing through Transport & Retail.  And these apps run on the widest range of rugged Android devices from most vendors.  Integration with all major ERP & WMS are assured, and we’re SAP-Certified. 
      </p>
      <!-- <h4 class="sub-heading">
        Focus on what matters - with a hands-free approach to your workflows. 
        Let voice recognition streamline your mobile applications. Run faster,
        more accurate, and safer.
      </h4> -->
    </div>
    <!-- <div class="image-col">
      <img :src="heroImage" alt="" class="hero-img" />
    </div> -->
  </div>

  <!-- WHY VOICE -->
  <section class="why-voice">
    <h1 class="heading">
      Choose with <span class="highlight-gr">confidence</span> — our
      voice-driven workflow for a smooth and productive work experience.
    </h1>
    <div class="image-col">
      <img :src="whyVoice" alt="" class="section-img" />
    </div>
    <div class="information-col">
      <div class="pointer-block">
        <h1 class="pointer">1</h1>
        <div class="content-box">
          <h4 class="box-heading">
            3 DAY POC (Proof of Concept) with zero investment
          </h4>
          <p class="content">
            Explore the Zero Investment Proof of Concept (POC) from Almasons,
            which can be implemented in your distribution center utilizing your
            operators and data in just three days.
          </p>
        </div>
      </div>
      <div class="pointer-block">
        <h1 class="pointer">2</h1>
        <div class="content-box">
          <h4 class="box-heading">6-8 Weeks Typical Implementation</h4>
          <p class="content">
            Achieve rapid impact with Almasons. Our tailored solutions optimize
            operations for seamless productivity, implementing in just 6-8
            weeks.
          </p>
        </div>
      </div>
      <div class="pointer-block">
        <h1 class="pointer">3</h1>
        <div class="content-box">
          <h4 class="box-heading">72 languages & dialects</h4>
          <p class="content">
         
Almasons can hear instructions in a choice of 72 different languages and dialects.  They can speak in any of those out of the box, even more with special training mode.
          </p>
        </div>
      </div>
    </div>
  </section>

  <!-- HOW VOICE WORKS -->
  <section class="how-it-works">
    <h1 class="heading">
      How <span class="highlight-gr">Almasons</span> GiNi™
      <span class="highlight-gr">Voice</span> Works?
    </h1>
    <p class="content">Almasons GiNi™ voice – an interactive approach.​</p>
    <ul class="list">
      <li class="list-item">
        <p class="content">
          Your warehouse workers get a noise-canceling headset that connects to their Android device via Bluetooth.



        </p>
      </li>
      <li class="list-item">
        <p class="content">
          They will hear clear instructions on where to go, what task to do, and how to do it – all in their language of choice.
        </p>
      </li>
      <li class="list-item">
        <p class="content">
          Workers verbally confirm their actions at each step.
        </p>
      </li>
      <li class="list-item">
        <p class="content">
   If they need more guidance just say GiNi Help.

        </p>
      </li>
    </ul>

  </section>

  <!-- STATS SECTION -->
  <StatsSection
    :background="true"
    heading1="Customer"
    heading2="benefits"
    :stats="[
      { marking: '10-30%', markSub: 'Increase Productivity' },
      { marking: '20-90%', markSub: 'Reduced Mispicks' },
      { marking: '50%-75%', markSub: 'Reduced Training Time' },
      { marking: '15-30%', markSub: 'Reduces Employee Turnover' },
      { marking: '10-15%', markSub: 'Reducing Safety Issues' },
    ]"
  />

  <!-- VOICE BENEFITS -->
  <section class="benefits-section">
    <h1 class="title">
      Almasons GiNi™
      <span class="highlight-gr"> Voice</span> Benefits
    </h1>

    <div class="left-info-section">
      <div class="content-box">
        <div class="content-heading">CHOICE OF SUPPORT</div>
        <div class="content-body">
          Comprehensive hardware/software support, from break/fix to tailored
          multi-year packages.
        </div>
      </div>
      <div class="content-box">
        <div class="content-heading">VOICE-RECOGNITION ABILITY</div>
        <div class="content-body">
          Equipped with voice recognition and response technology to understand
          popular languages.
        </div>
      </div>
      <div class="content-box">
        <div class="content-heading">50+ VOICE WORKFLOWS</div>
        <div class="content-body">
          50+ pre-built workflows, reducing project duration. Designed to reduce
          human errors and increase efficiency.
        </div>
      </div>
    </div>
    <div class="middle-img-section">
      <img :src="voiceHero" alt="" />
      <div class="action-btns">
        <a href="/contact-us" class="btn-cta-1">schedule a demo</a>
        <!-- <a href="" class="btn-cta-black">Calculate Cost</a> -->
      </div>
    </div>
    <div class="right-info-section">
      <div class="content-box">
        <div class="content-heading">SUPPORTED 72 LANGUAGES</div>
        <div class="content-body">
          Supports 72 languages, making it versatile and user-friendly for a
          wide spectrum of users.
        </div>
      </div>
      <div class="content-box">
        <div class="content-heading">device agnostic</div>
        <div class="content-body">
          Integrate with various Android devices. Enhance performance and
          optimize warehouse efficiency.
        </div>
      </div>
      <div class="content-box">
        <div class="content-heading">Adaptable solution</div>
        <div class="content-body">
          Avoid costly backend system integrations with GiNi Voice’s easy
          connectivity and workflow integrations.
        </div>
      </div>
    </div>
  </section>

  <!-- SAP CERTIFIED SOLUTION -->
  <section class="sap-solution">
    <h1 class="heading">
      SAP CERTIFIED <span class="highlight-gr">SOLUTION</span>
    </h1>
    <p class="content">
      We are proud to hold SAP certification for our solution, ensuring its
      compatibility and compliance with SAP's rigid standards. You can trust
      that our solution will smoothly integrate with your existing SAP systems,
      maximizing the value of your SAP investment.
    </p>
  </section>
  <ContactForm/>
</template>

<script>
import heroImage from "../assets/Voice/hero-img.png";
import voiceHero from "../assets/voice-hero1.png";
import whyVoice from "../assets/Voice/why-voice.png";

import StatsSection from "@/components/StatsSection.vue";
import ContactForm from "@/components/ContactForm.vue"

export default {
  data() {
    return {
      heroImage: heroImage,
      voiceHero: voiceHero,
      whyVoice: whyVoice,
    };
  },
  components: {
    StatsSection,ContactForm
  },
  mounted(){
    window.scrollTo({
            top: 0
          });
  },
};
</script>

<!-- HERO SECTION -->
<style scoped>
.hero-section {
  margin-top: 70px;
  padding: 60px 120px;
  height: 100%;
  background: url("../assets/Voice/stats-bkg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 120px; */
  display: flex;
  flex-direction: column;
  text-align: center;
}

.hero-section .heading {
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: uppercase; */
  /* background: linear-gradient(0deg, #f15b2b 14.88%, #f7941d 30.67%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: black;
}

.hero-section .subheading{
  font-family: Poppins;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: uppercase; */
  color: #000000c5 ;
}

.hero-section .content {
  color: #171717;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem; /* 160% */
  margin-top: 10px;
}

.hero-section .product-name {
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
  background: linear-gradient(92deg, #f15b2b 1.54%, #f7941d 78.68%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* 
.hero-section .sub-heading {
  color: #424242;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.48px;
  text-transform: capitalize;
} */

.hero-section .image-col {
  text-align: right;
}

.hero-section .image-col .hero-img {
  width: 100%;
}

@media screen and (max-width: 576px) {
  .hero-section {
    margin-top: 70px;
    padding: 40px;
  }

  .hero-section .heading {
    font-size: 24px;
    line-height: normal; /* 133.333% */
  }

  .hero-section .product-name {
    font-size: 35px;
    font-weight: 700;
  }


  .hero-section .subheading {
    font-family: Poppins;
    font-size: 18px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* text-transform: uppercase; */
    color: #000000c5;
}
}
</style>

<!-- WHY VOICE -->
<style scoped>
.why-voice {
  padding: 60px 120px;
  background: url("../assets/Voice/why-voice-bkg.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.why-voice .section-img {
  height: 600px;
}

.why-voice .heading {
  grid-column: 1/3;
  grid-row: 1/2;
  color: #000;
  text-align: left;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.why-voice .pointer-block {
  display: grid;
  grid-template-columns: 80px 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 50px;
}

.why-voice .pointer {
  display: inline-block;
  font-size: 82px;
  font-style: normal;
  font-weight: 600;
  line-height: 82px;
  width: 150px !important;
  text-transform: uppercase;
  background: linear-gradient(180deg, #f15b2b 0%, #f7941d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.why-voice .box-heading {
  color: #424242;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.why-voice .content {
  color: #464646;
  text-align: justify;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 576px) {
  .why-voice {
    padding: 40px;
    grid-template-columns: 1fr !important;
  }
  .why-voice .section-img {
    height: auto;
    width: 100%;
  }
  .why-voice .heading {
    grid-column: 1/2;
    grid-row: 1/2;
    font-size: 18px;
    font-weight: 600;
  }

  .why-voice .pointer-block {
    flex-direction: column;
    gap: 0px;
    margin-bottom: 30px;
  }
  .why-voice .pointer {
    display: block;
    line-height: normal;
    width: fit-content;
  }

  .why-voice .box-heading {
    font-size: 18px;
  }

  .why-voice .content {
    text-align: left;
  }
}
</style>

<!-- BENEFITS SECTION -->
<style scoped>
.benefits-section {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 60px 120px;
  gap: 30px;
}

.highlight-gr {
  background: linear-gradient(90deg, #f7941d 0%, #f15b2b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.benefits-section .title {
  grid-column: 1/5;
  text-align: center;
  font-size: 50px;
  font-weight: 500;
  line-height: 65px;
}

.right-info-section,
.left-info-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.content-box {
  text-align: left;
}

.content-box .content-heading {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.content-box .content-body {
  color: #505050;
  font-size: 18px;
  font-weight: 400;
}

.middle-img-section {
  display: flex;
  height: 100%;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.middle-img-section img {
  object-fit: contain;
  height: 500px;
  width: auto !important;
  margin-bottom: 30px;
}

.action-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.btn-cta-1 {
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  border-radius: 3px;
  background: linear-gradient(90deg, #f7941d 0%, #f04e37 100%);
  transition: padding 0.3s ease-in-out;
}

.btn-cta-black {
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-weight: 700;
  text-decoration: none;
  color: #888;
  border-radius: 3px;
  /* background: linear-gradient(90deg, #f7941d 0%, #f04e37 100%); */
  border: 2px solid #888;
  background-color: #fff;
  transition: padding 0.3s ease-in-out;
}

.btn-cta-2 {
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-weight: 700;
  text-decoration: none;
  color: #f7941d;
  border-radius: 3px;
  /* background: linear-gradient(90deg, #f7941d 0%, #f04e37 100%); */
  border: 2px solid #f7941d;
  background-color: #fff;
  transition: padding 0.3s ease-in-out;
}

@media screen and (max-width: 576px) {
  .benefits-section {
    grid-template-columns: 1fr;
    padding: 40px;
    gap: 30px;
  }

  .benefits-section .title {
    grid-column: 1/2;
    text-align: left;
    font-size: 24px;
    line-height: normal;
  }

  .content-box .content-heading {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .content-box .content-body {
    font-size: 16px;
  }

  .middle-img-section {
    width: 100%;
  }

  .middle-img-section img {
    height: auto;
    width: 100% !important;
    margin-bottom: 0px;
  }

  .action-btns {
    display: none;
  }

  .right-info-section,
  .left-info-section {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 20px;
  }
}
</style>

<!-- SAP -->
<style scoped>
.sap-solution {
  padding: 60px 120px;
  background: url("../assets/Voice/sap-bkg.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.sap-solution .heading {
  color: #000;
  font-size: 36px;
  font-weight: 600;
}

.sap-solution .content {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

@media screen and (max-width: 576px) {
  .sap-solution {
    padding: 40px;
    align-items: flex-start;
  }

  .sap-solution .heading {
    font-size: 24px;
  }

  .sap-solution .content {
    font-size: 16px;
    text-align: left;
  }
}
</style>

<!-- HOW IT WORKS -->
<style scoped>
.how-it-works {
  padding: 60px 120px;
}

.how-it-works .heading {
  color: #000;
  font-size: 36px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.how-it-works .content {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}

.how-it-works .list {
  margin-left: 50px;
}

.how-it-works .list .content {
  margin-bottom: 5px;
}

@media screen and (max-width: 576px) {
  .how-it-works {
    padding: 40px;
  }

  .how-it-works .heading {
    font-size: 24px;
  }

  .how-it-works .content {
    font-size: 16px;
  }

  .how-it-works .list {
    margin-left: 20px;
  }
}
</style>
