<template>
  <section class="solution-hero">
    <div class="new-logo">
      <!-- <img :src="almasonsginiblack" alt="" class="section-logo" />
      <h2 class="heading highlight-gr">VOICE DEV KIT</h2> -->
      <h1 class="heading">
         <span style="color: black;"> ALMASONS GiNi™ </span><span class="highlight-gr">VOICE DEV KIT</span>
      </h1>
    </div>
    <p class="content">
      Convert your worker applications into voice-enabled systems in as little
      as one day; all it takes is a few added lines of Almasons code. No
      adjustments to your backend WMS, ERP, CRM. Streamline apps for higher
      throughput. Make apps easier to learn. Embrace a do-it-yourself approach
      without voice consultants or expensive customizations. Leverage Almasons
      decades of experience in Voice.
    </p>
    <div class="action-btns">
      <a href="/contact-us" class="btn-cta-1">Get Started</a>
    </div>

    <div class="product-highlight">
      <div class="highlight-card">
        <div class="card-icon"><i class="fa-solid fa-database"></i></div>
        <div class="card-title">DIY Approach</div>
        <div class="content">
          No need for consultants or expensive implementations or special
          browsers. Make applications easier to use more efficient and
          multi-lingual.
        </div>
      </div>
      <div class="highlight-card">
        <div class="card-icon"><i class="fa-solid fa-keyboard"></i></div>
        <div class="card-title">One-Day Implementation</div>
        <div class="content">
          Adding voice controls to an existing android app can be completed in
          as little as one day.
        </div>
      </div>
      <div class="highlight-card">
        <div class="card-icon"><i class="fa-solid fa-laptop-code"></i></div>
        <div class="card-title">ROI potential</div>
        <div class="content">
          Streamlining applications means more work can be done in the same
          amount of time. This drives a real- world ROI.
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import almasonsginiblack from "../assets/almasons-gini-black.png";
export default {
  data() {
    return {
      almasonsginiblack: almasonsginiblack,
    };
  },
  mounted(){
    window.scrollTo({
            top: 0
          });
  }
};
</script>

<style scoped>
.solution-hero {
  min-height: calc(70vh - 65px);
  margin-top: 65px;
  padding: 50px 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.new-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.section-logo {
  height: 50px;
}

.heading {
  /* display: inline-block; */
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: uppercase; */
  /* background: linear-gradient(0deg, #f15b2b 14.88%, #f7941d 30.67%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: black;
}

/* .heading {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: end;
} */

.highlight-gr {
  background: linear-gradient(90deg, #f7941d 0%, #f15b2b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 15px;
}

.content {
  color: #171717;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  /* text-transform: capitalize; */
  margin-bottom: 40px;
}

.btn-cta-1 {
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  border-radius: 3px;
  background: linear-gradient(90deg, #f7941d 0%, #f04e37 100%);
  transition: padding 0.3s ease-in-out;
}

.product-highlight {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px 60px;
  margin-top: 50px;
}

.product-highlight .content {
  color: #171717;
  text-align: left !important;
  font-size: 16px;
  line-height: 30px; /* 133.333% */
  text-transform: capitalize;
  margin-bottom: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.product-highlight .card-icon {
  font-size: 60px;
  margin-bottom: 10px;
  color: #f7941d;
}

.product-highlight .card-title {
  font-size: 20px;
  font-weight: 600;
}

@media screen and (max-width: 576px) {
  .solution-hero {
    min-height: 100%;
    padding: 40px;
    text-align: left;
  }

  .heading {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .highlight-gr {
    background: linear-gradient(90deg, #f7941d 0%, #f15b2b 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .content {
    text-align: left;
    font-size: 18px;
    line-height: normal; /* 133.333% */
    margin-bottom: 40px;
  }

  .product-highlight {
    grid-template-columns: 1fr;
    gap: 20px 60px;
    margin-top: 50px;
  }
}
</style>
