<template>
  <section
    :class="[
      backgroundImg == 'one' ? 'bkg-1' : '',
      backgroundImg == 'two' ? 'bkg-2' : '',
      'two-grid',
    ]"
  >
    <div v-if="alignment === 'left'">
      <img :src="image" alt="" class="section-img" />
    </div>
    <div class="information-section">
      <h1 class="section-heading">
        {{ heading1 }} <span class="highlight-gr">{{ heading2 }}</span>
      </h1>
      <h4 class="section-sub-heading">
        {{ subheading }}
      </h4>
      <p class="section-content">
        {{ content }}
      </p>
      <div class="action-btns" v-if="buttons">
        <template v-for="btns in buttons" :key="btns.btnname">
          <a :href="btns.btnlink" class="btn-cta-1">{{ btns.btnname }}</a>
        </template>
        <!-- <a href="" class="btn-cta-black">Calculate Cost</a> -->
      </div>
    </div>
    <div v-if="alignment === 'right'" class="img-section">
      <img :src="image" alt="" class="section-img" />
    </div>
  </section>
</template>

<script>
import placeholder from "../assets/vdk.png";

export default {
  data() {
    return {
      placeholder: placeholder,
    };
  },
  props: {
    backgroundImg: "",
    alignment: String,
    heading1: String,
    heading2: String,
    subheading: String,
    content: String,
    image: String,
    buttons: Object,
  },
};
</script>

<style scoped>
.two-grid {
  padding: 60px 120px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
  color: #272827;
}

.section-heading {
  color: #272827;
  text-align: left;
  font-family: Poppins;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.section-sub-heading {
  color: #272827;
  text-align: justify;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.section-content {
  color: #272827;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: justify;
}

.img-section {
  text-align: right;
}

.section-img {
  max-width: 700px;
  max-height: 500px;
}

.highlight-gr {
  background: linear-gradient(90deg, #f7941d 0%, #f15b2b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bkg-1 {
  background: url("../assets/LM&D/section-bkg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.bkg-2 {
  background: url("../assets/esign/section-bkg-2.png");
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 576px) {
  .two-grid {
    padding: 40px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .section-heading {
    font-size: 22px;
    margin-bottom: 0px;
  }

  .section-content {
    text-align: left;
  }

  .img-section {
    text-align: center;
  }

  .section-img {
    width: 100%;
    height: auto;
  }
}
</style>