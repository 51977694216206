<template>
  <footer class="footer">
    <img :src="footerlogo" alt="" class="footer-logo" />
    <div class="footer-grid">
      <div class="footer-menu-section">
        <h2 class="menu-header">COMPANY</h2>
        <a href="/about-us" class="footer-menu-link">About us</a>
        <!-- <a href="" class="footer-menu-link">Client Login</a> -->
        <!-- <a href="" class="footer-menu-link">Resources</a> -->
        <a href="/contact-us" class="footer-menu-link">Contact us</a>
      </div>
      <div class="footer-menu-section">
        <h2 class="menu-header">SOLUTIONS</h2>
        <!-- <a href="" class="footer-menu-link">GINI Platform</a> -->
        <a href="/gini-voice" class="footer-menu-link">Voice</a>
        <a href="/gini-lm-and-dashboards" class="footer-menu-link">Dashboards</a>
        <a href="/gini-lm-and-dashboards" class="footer-menu-link">Labor Management</a>
        <a href="/gini-m&i" class="footer-menu-link">Maintain & Inspect</a>  
        <a href="/gini-workflows" class="footer-menu-link">Workflows</a>
        <a href="/gini-esign" class="footer-menu-link">eSign</a>
        <a href="/gini-smart-connect" class="footer-menu-link">Smart Connect</a>
        <a href="/voice-development-kit" class="footer-menu-link">Voice Dev Kit</a>
        <!-- <a href="" class="footer-menu-link">GINI WMS</a> -->
      </div>
      <div class="footer-menu-section">
        <h2 class="menu-header">SOCIAL MEDIA</h2>             
        <div class="media-icons">
          <a href="https://www.linkedin.com/company/almasons/mycompany/" class="footer-menu-link"
            ><i class="fa-brands fa-linkedin"></i
          ></a>
          <a href="https://www.youtube.com/@almasons" class="footer-menu-link"
            ><i class="fa-brands fa-youtube"></i
          ></a>
          <!-- <a href="" class="footer-menu-link"
            ><i class="fa-brands fa-square-x-twitter"></i
          ></a> -->
        </div>
      </div>
    </div>
    <div class="copyright">
      <p class="copyright-text">
        Copyright 2023 Almasons Technologies Pvt. Ltd.
      </p>
    </div>
  </footer>
</template>

<script>
import footerlogo from "../assets/footer-logo.png";

export default {
  data() {
    return {
      footerlogo: footerlogo,
    };
  },
};
</script>

<style scoped>
.footer {
  padding: 10px 120px;
  background: #272827;
}

.footer-logo {
  height: 100px;
  margin-bottom: 20px;
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 50px;
}

.footer-menu-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 40px;
}

.footer-menu-section:nth-child(2) {
  grid-column-start: 2;
  grid-column-end: 4;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 40px;
}

.footer-menu-section:nth-child(2) .menu-header {
  grid-column-start: 1;
  grid-column-end: 3;
}

.footer-menu-section .media-icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.media-icons i {
  font-size: 22px !important;
}

.footer-menu-section .menu-header {
  color: #fff;
  font-size: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.footer-menu-section .footer-menu-link {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.49);
  font-size: 14px;
  transition: color 0.2s ease-in-out;
}

.footer-menu-section .footer-menu-link:hover {
  color: #f7941d;
}

.copyright {
  text-align: center;
}

.copyright-text {
  color: #ffffff44;
  font-size: 10px;
}

@media screen and (max-width: 576px) {
  .footer {
    padding: 10px 40px;
  }
  .footer-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  .footer-menu-section {
    width: 100%;
    margin-bottom: 20px;
  }
  .footer-menu-section:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-template-columns: 1fr;
    margin-bottom: 20px;
  }

  .footer-menu-section:nth-child(2) .menu-header {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .footer-menu-section:last-child {
    margin-bottom: 40px;
  }
}
</style>