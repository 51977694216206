<template>
  <div class="post-card">
    <img :src="detail.image" alt="" class="feat-img" />
    <div class="feat content">
      <p class="post-title">{{ detail.title }}</p>
      <p class="post-body">{{ detail.content.slice(0, 35) }}...</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: Object,
  },
};
</script>

<style scoped>
.post-card {
  padding: 10px 5px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  gap: 20px;
  cursor: pointer;
}

.feat-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-card .post-title {
  font-size: 18px;
  font-weight: 600;
  color: #272827;
}

.post-card .post-body {
  font-size: 14px;
  color: #272827;
  opacity: 0.7;
}

hr {
  border-color: rgba(0, 0, 0, 0.1);
}
</style>