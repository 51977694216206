<template>
  <router-link :to="btnLink" href="" class="btn"
    ><i v-if="icon" :class="iconClass"></i>{{ text }}</router-link
  >
</template>
    
    <script>
export default {
  props: {
    text: {
      type: String,
      default: "Button",
    },
    icon: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: "fa-solid fa-play",
    },
    btnLink: {
      type: String,
      default: "/contact-us",
    },
  },
};
</script>
    
    <style scoped>
.btn {
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-weight: 700;
  text-decoration: none;
  color: #777;
  border-radius: 3px;
  border: 2px solid #777;
  transition: padding 0.3s ease-in-out;
}

.btn i {
  display: inline-block;
  margin-right: 15px;
}
</style>