<template>
  <section id="hero-section">
    <!-- 
          DESKTOP : Two column grid approach. First column will have information and second column will have animation to display features. 
          TABLET : Same approach as desktop.
          MOBILE : One column grid approach. Information will be followed by animation.
      -->

    <div class="grid-hero">
      <div class="empty"></div>
      <div class="information-col">
        <h1 class="heading">
         <span style="color: black;"> ALMASONS GiNi™ </span><span class="highlight-gr">ESIGN</span>
      </h1>
      <h2 class="subheading">Sign With Ease, Proceed With Assurance.</h2>
     
      <p class="content">
        Our affordable electronic signature solution optimizes the signing process for smarter and quicker workflows. Enjoy seamless transactions and enhanced efficiency as you expertly manage your business processes with our innovative e-signature solution.
      </p>
        <!-- pppp -->
        <!-- <h1 class="heading">
          ALmasons GiNi™ <span class="highlight-gr">esign</span>
        </h1>
        <p class="sub-heading">Sign with Ease, Proceed with Assurance.</p>
        <p class="content">
          A cost-efficient electronic signature solution- streamlining
          signatures for a smarter, faster workflow.
        </p> -->
      </div>
    </div>
  </section>

  <!-- BANNER -->
  <div class="banner">
    <p class="content">
      From onboarding to signing everyday documents, all done digitally. <br />
      <strong>Sign documents online with legally-binding eSignatures.</strong>
    </p>
  </div>

  <!-- TEXT BLOCK -->
  <div class="text-block-section">
    <h1 class="heading">
      ALMASONS GiNi™ ESIGN - Sign all your supply chain documents electronically.
    </h1>
    <p class="content">
      At Almasons, we recognize the importance of the ability to utilize an
      efficient and reliable electronic signature program. Almasons eSign is
      your SAP-certified electronic signature solution designed to work directly
      with SAP as well as with other ERP systems using a wide range of devices.
    </p>
  </div>

  <!-- TEXT CARDS -->
  <div class="text-card">
    <div class="information-col">
      <h1 class="heading">
        Why choose <br />almasons gini <span class="highlight-gr">esign</span>?
      </h1>
      <p class="content">
        Almasons GiNi™ eSign stands out as a versatile and efficient tool
        designed to sign documents and enhance processes across various
        industries.
      </p>
    </div>
    <div class="card-col">
      <div class="card">
        <img :src="connect" alt="" class="card-icon" />
        <p class="content">Ready-to-deploy solution.</p>
      </div>
      <div class="card">
        <img :src="environment" alt="" class="card-icon" />
        <p class="content">Reduce environmental impact</p>
      </div>
      <div class="card">
        <img :src="doc" alt="" class="card-icon" />
        <p class="content">Expedite documentation process</p>
      </div>
      <div class="card">
        <img :src="globe" alt="" class="card-icon" />
        <p class="content">Anywhere, Anytime Signatures</p>
      </div>
      <div class="card">
        <img :src="multi" alt="" class="card-icon" />
        <p class="content">Multi-Device <br />Signing</p>
      </div>
    </div>
  </div>

  <!-- CARD BLOCK -->
  <div class="card-block">
    <h1 class="heading">
      Sign documents anytime and <br />anywhere,
      <span class="highlight-gr">on any device</span>
    </h1>
    <div class="card-deck">
      <div class="card">
        <p class="card-title">WMS-agnostic Compatibility Assurance</p>
        <p class="card-content">
          Almasons GiNi™ eSign improves the overall effectiveness of warehouse
          operations by ensuring compatibility with any Warehouse Management
          System (WMS)
        </p>
      </div>
      <div class="card">
        <p class="card-title">Compatibility with Various Applications</p>
        <p class="card-content">
          Our solution exhibits versatility by being compatible with various
          applications. This assurance ensures the flexibility to seamlessly
          incorporate our solution into your existing operations
        </p>
      </div>
    </div>
  </div>

  <!-- IMAGE STACK -->
  <div class="image-stack">
    <h1 class="heading">
      INDUSTRY <span class="highlight-gr">APPLICATIONS</span>
    </h1>
    <div class="img-deck">
      <div class="img-card">
        <div class="screen"></div>
        <img :src="app1" alt="" class="card-img" />
        <p class="content">Manufacturing</p>
      </div>
      <div class="img-card">
        <div class="screen"></div>
        <img :src="app2" alt="" class="card-img" />
        <p class="content">Warehouse/DC</p>
      </div>
      <div class="img-card">
        <div class="screen"></div>
        <img :src="app5" alt="" class="card-img" />
        <p class="content">Pack & ship</p>
      </div>
      <div class="img-card">
        <div class="screen"></div>
        <img :src="app3" alt="" class="card-img" />
        <p class="content">transport & logistics</p>
      </div>
      <div class="img-card">
        <div class="screen"></div>
        <img :src="app4" alt="" class="card-img" />
        <p class="content">retail store</p>
      </div>
    </div>
  </div>

  <!-- CERTIFICATION -->
  <ImageTextSection
    backgroundImg="two"
    alignment="right"
    heading1="SAP "
    heading2="Certified"
    subheading=""
    content="Almasons eSign is your SAP-certified electronic signature solution designed to work directly with SAP as well as with other ERP systems using a wide range of devices. SAP certification serves as evidence of its superior quality and compatibility with SAP systems. Businesses that use SAP for enterprise resource planning ought to feel more secure"
    :image="sap"
    :buttons="[]"
  />

  <!-- TEXT IMAGE  -->
  <!-- <ImageTextSection
    backgroundImg=""
    alignment="left"
    heading1="CLOUD OR  "
    heading2="ON-PREMISES"
    subheading="1–2-week deployment"
    content="GiNi™ eSign offers businesses the flexibility to choose between cloud deployment and on-prem installation. The swift 1–2-week deployment timeframe ensures that businesses can quickly adapt to the solution without delay."
    :image="cloud"
    :buttons="[]"
  /> -->

  <ContactForm/>
</template>
  
  <script>
import connect from "../assets/esign/connect.png";
import environment from "../assets/esign/environment.png";
import globe from "../assets/esign/globe.png";
import multi from "../assets/esign/multi.png";
import doc from "../assets/esign/doc.png";

import app1 from "../assets/esign/1.jpg";
import app2 from "../assets/esign/2.jpg";
import app3 from "../assets/esign/3.jpg";
import app4 from "../assets/esign/4.jpg";
import app5 from "../assets/esign/5.jpg";

import sap from "../assets/esign/sap.png";

import cloud from "../assets/esign/cloud.png";

import ImageTextSection from "@/components/ImageTextSection.vue";

import ContactForm from "@/components/ContactForm.vue"

export default {
  data() {
    return {
      sap: sap,
      cloud: cloud,
      connect: connect,
      environment: environment,
      globe: globe,
      multi: multi,
      doc: doc,
      app1: app1,
      app2: app2,
      app3: app3,
      app4: app4,
      app5: app5,
    };
  },
  components: {
    ImageTextSection,
    ContactForm,
  },
  mounted(){
    window.scrollTo({
            top: 0
          });
  }
};
</script>
  
  <style scoped>
.highlight-gr {
  background: linear-gradient(90deg, #f7941d 0%, #f15b2b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
  
<!-- HERO SECTION STYLES -->
<style scoped>
#hero-section {
  width: 100%;
  padding: 40px 120px;
  margin-top: 65px;
  background: #fff;
  height: calc(100vh - 230px);
  background: url("../assets/esign-hero.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.grid-hero {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 120px;
  align-items: center;
}
#hero-section .information-col {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

#hero-section .information-col .heading {
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: uppercase; */
  /* background: linear-gradient(0deg, #f15b2b 14.88%, #f7941d 30.67%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: black;
}

#hero-section .information-col .subheading{
  font-family: Poppins;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: uppercase; */
  color: #000000c5 ;
}

#hero-section .information-col .content {
  color: #171717;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem; /* 160% */
}

/* .information-col .heading {
  color: #3e3e3e;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.information-col .sub-heading {
  color: #000;
  text-align: left;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.information-col .content {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
} */

@media screen and (max-width: 576px) {
  #hero-section {
    padding: 40px;
    height: calc(70vh);
    background-size: contain;
    background-position: left top;
  }

  .grid-hero {
    grid-template-columns: 1fr;
    gap: 0px;
  }

  #hero-section{
    background-image: none;
  }
  #hero-section .information-col .heading {
    font-size: 24px;
  }

  #hero-section .information-col .subheading {
    font-size: 18px;
    margin-top: 10px;
}

  #hero-section .information-col .content {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 160% */
  }

  /* .information-col .heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .information-col .sub-heading {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .information-col .content {
    font-size: 16px;
  } */
}
</style>

<!-- Banner -->
<style scoped>
.banner {
  padding: 40px 120px;
  background: url("../assets/esign/banner-bkg.png");
}

.banner .content {
  color: #272827;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 576px) {
  .banner {
    padding: 40px;
  }

  .banner .content {
    font-size: 20px;
  }
}
</style>

<!-- TEXT SECTION -->
<style scoped>
.text-block-section {
  padding: 60px 120px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 100px;
  align-items: center;
}

.text-block-section .heading {
  color: #000;
  text-align: left;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-block-section .content {
  color: #272827;
  text-align: justify;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 576px) {
  .text-block-section {
    padding: 40px;
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .text-block-section .heading {
    font-size: 20px;
  }

  .text-block-section .content {
    font-size: 16px;
  }
}
</style>

<!-- Card block -->
<style scoped>
.card-block {
  padding: 40px 120px;
}

.card-block .heading {
  color: #272827;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
}

.card-block .card-deck {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.card-block .card-deck .card {
  border-top: 5px solid #f7941d;
  background: linear-gradient(
    180deg,
    #f7941d 0%,
    rgba(247, 148, 29, 0.32) 0.01%,
    rgba(247, 148, 29, 0) 100%
  );
  box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25),
    4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 455px;
  padding: 20px;
  flex-shrink: 0;
  height: 250px;
}

.card-block .card-deck .card .card-title {
  color: #212121;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.card-block .card-deck .card .card-content {
  color: #2b2b2b;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

@media screen and (max-width: 576px) {
  .card-block {
    padding: 40px;
  }

  .card-block .heading {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .card-block .card-deck {
    flex-direction: column;
    gap: 20px;
  }

  .card-block .card-deck .card {
    width: 100%;
    padding: 20px;
    height: fit-content;
  }

  .card-block .card-deck .card .card-title {
    font-size: 18px;
  }

  .card-block .card-deck .card .card-content {
    font-size: 16px;
  }
}
</style>

<!-- text card -->
<style scoped>
.text-card {
  padding: 60px 120px;
  background: url("../assets/esign/why-esign.png");
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 60px;
  align-items: center;
}

.text-card .heading {
  color: #272827;
  text-align: left;
  font-family: Poppins;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.text-card .content {
  color: #272827;
  text-align: left;
  font-family: Poppins;
  font-size: 1rem;
  line-height: 35px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-card .card-col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;
}

.text-card .card-col .card {
  border-radius: 10px;
  background: #fff;
  box-shadow: 4px 4px 4px 0px #f7941d, -4px -4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.text-card .card-col .card:nth-child(1) {
  grid-column: 1/3;
}
.text-card .card-col .card:nth-child(2) {
  grid-column: 3/5;
}
.text-card .card-col .card:nth-child(3) {
  grid-column: 5/7;
}
.text-card .card-col .card:nth-child(4) {
  grid-column: 2/4;
}
.text-card .card-col .card:nth-child(5) {
  grid-column: 4/6;
}

.text-card .card-col .card img {
  margin-bottom: 20px;
}

.text-card .card-col .card .content {
  color: #222;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  line-height: normal !important;
}

@media screen and (max-width: 576px) {
  .text-card {
    padding: 40px;
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .text-card .heading {
    font-size: 24px;
  }

  .text-card .content {
    font-size: 18px;
    line-height: normal;
  }

  .text-card .card-col {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .text-card .card-col .card:nth-child(1),
  .text-card .card-col .card:nth-child(2),
  .text-card .card-col .card:nth-child(3),
  .text-card .card-col .card:nth-child(4),
  .text-card .card-col .card:nth-child(5) {
    grid-column: 1/2;
  }

  .text-card .card-col .card img {
    margin-bottom: 20px;
  }

  .text-card .card-col .card .content {
    color: #222;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    line-height: normal !important;
  }
}
</style>

<!-- image stack -->
<style scoped>
.image-stack {
  padding: 40px 120px;
}

.image-stack .heading {
  color: #272827;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.image-stack .img-deck {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;
  text-align: center;
}

.image-stack .img-deck img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.image-stack .img-deck .img-card {
  position: relative;
}

.image-stack .img-deck .img-card .screen{
  z-index: 4;
  position: absolute;
top: 0;
left: 0;
width: 100%;
height: 350px;
background: #00000055;

}

.image-stack .content {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}

@media screen and (max-width: 576px) {
  .image-stack {
    padding: 40px;
  }

  .image-stack .heading {
    font-size: 24px;
  }

  .image-stack .img-deck {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
</style>


