<template>
  <!-- Hero -->
  <div class="hero-section">
    <div class="image-col">
      <img :src="heroImg" alt="" class="hero-img" />
    </div>
    <div class="information-col">

      <h1 class="heading">
         <span style="color: black;"> ALMASONS GiNi™ </span><span class="highlight-gr">SMART CONNECT</span>
      </h1>
      <h2 class="subheading">Work Untethered; Don't Be Network-Bound.</h2>
     
      <p class="content">
        Free your workers from WiFi dependency. Operate smoothly during WiFi interruptions with workflows backed up by Almasons GiNi™ Smart Connect. Provides caching of worker activity & inputs, and then seamlessly synchronizing with backend ERP & WMS systems once reconnected.
      </p>
      <!-- pp -->
      <!-- <h1 class="heading">Almasons GiNi™ smart connect</h1>
      <p class="content">Work untethered; don't be network-bound.</p>
      <p class="content">
        Let your workforce operate even when you have temporary Wi-Fi
        disconnects. Our solution caches data and lets your team work without
        interruptions.
      </p> -->
      <!-- <div class="button-stack">
        <ButtonPrimary
          :icon="false"
          text="schedule demo"
          iconClass="fa-regular fa-calendar"
          btnLink="/contact-us"
        />
        <ButtonDisable
          :icon="false"
          text="Learn more"
          iconClass="fa-solid fa-circle-info"
          btnLink="/gini-voice"
        />
      </div> -->
    </div>
  </div>
  <!-- IMG TEXT -->
  <ImageTextSection
    backgroundImg=""
    alignment="right"
    heading1="No WiFi Connection?"
    heading2="No Problem!"
    subheading=""
    content="Our Smart Connect tools allow our workflows to continue to operate when temporarily disconnected.  Our apps continue to run, caching instructions to be served and data collected, and then synchs-up with backend ERP & WMS systems when connectivity returns.  No app beeps, no screen freezes, no lost time.  Allows storage in yards and temporary overflow locations where no WiFi is present."
    :image="sectionImg"
    :buttons="[]"
  />

  <!-- CARDS -->
  <div class="card-section">
    <h1 class="heading">
      <span class="highlight-gr">Customer Advantages: </span>No Interruptions,
      No New Investments, No Whining!
    </h1>
    <div class="card-deck">
      <!-- <div class="card">
        <div class="card-title">No warehouse survey</div>
        <div class="card-content">
          Rely on precise data for efficient warehouse management without
          on-site assessments.​
        </div>
      </div> -->

      <div class="card">
        <div class="card-title">No Wasted Time</div>
        <div class="card-content">
          When WiFi is weak in that corner of your plant or warehouse, work
          comes to a crawl or stops entirely. Smart Connect allows everything to
          continue even with no wireless connectivity.
        </div>
      </div>
      <div class="card">
        <div class="card-title">No Network Upgrades Needed</div>
        <div class="card-content">
          Competing workflows are often browser-driven or are network-dependent
          – they die when the network does. Not with native Workflows + Smart
          Connect. No need to add AP’s for Voice.
        </div>
      </div>
      <div class="card">
        <div class="card-title">No Excuses</div>
        <div class="card-content">
          Workers often blame network problems for slow performance. No whining
          when workflows are protected by Smart Connect.
        </div>
      </div>
    </div>
  </div>

  <!-- DEVICE -->
  <div class="device">
    <div class="information-col">
      <!-- <h1 class="heading">
        Device <span class="highlight-gr">Compatibility</span>
      </h1> -->
      <h3 class="sub-heading">android Support</h3>
      <p class="content">
        Smart Connect supports native apps running on Android version 9 and
        higher. Prevents app crashes when WiFi is weak or missing.
      </p>
      <h3 class="sub-heading">Device adaptability</h3>
      <p class="content">
        Smart Connect runs on all popular rugged Android devices, and supports
        the full range of form-factors and screen sizes.
      </p>
    </div>
  </div>



  <!-- ICONS -->
  <div class="icon-section">
    <h1 class="heading">
      <span class="highlight-gr">Smart Connect</span> allows factories and warehouse operations to use extended, seasonal, or temporary inventory storage locations.
    </h1>
    <!-- <h3 class="sub-heading">
      Expand storage beyond the traditional four walls of the warehouse.
    </h3> -->
    <div class="card-deck">
      <div class="card">
        <img :src="icon1" alt="" class="icon" />
        <p class="card-title">Pick from Yard/Trailers</p>
        <p class="card-content">
          Simplifies operations by enabling direct picking from yard/trailers.
          Enhance efficiency, reduce effort, and improve your WMS.
        </p>
      </div>
      <div class="card">
        <img :src="icon2" alt="" class="icon" />
        <p class="card-title">Flexibility in Storage</p>
        <p class="card-content">
          Adapt to inventory fluctuations and optimize space dynamically for
          efficient warehouse management.
        </p>
      </div>
    </div>
  </div>
  <ContactForm/>
</template>

<script>
import heroImg from "../assets/smart-connect/hero-img.png";
import sectionImg from "../assets/smart-connect/section-img.png";
// import sectionImg from '../assets/smart-connect/section-img-2.png'
import icon1 from "../assets/smart-connect/icon1.png";
import icon2 from "../assets/smart-connect/icon2.png";

import ImageTextSection from "@/components/ImageTextSection.vue";

import ButtonPrimary from "@/components/ButtonPrimary.vue";
import ButtonDisable from "@/components/ButtonDisable.vue";
import ContactForm from "@/components/ContactForm.vue"


export default {
  data() {
    return {
      heroImg: heroImg,
      sectionImg: sectionImg,
      // sectionImg1:sectionImg,
      icon1: icon1,
      icon2: icon2,
    };
  },
  components: {
    ImageTextSection,
    ButtonDisable,
    ButtonPrimary,
    ContactForm,
  },
  mounted(){
    window.scrollTo({
            top: 0
          });
  }
};
</script>

<style scoped>
.highlight-gr {
  background: linear-gradient(90deg, #f7941d 0%, #f15b2b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.button-stack {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 25px;
}
</style>

<!-- HERO -->
<style scoped>
.hero-section {
  /* margin-top: 65px; */
  padding: 60px 120px;
  background: url("../assets/smart-connect/hero-bkg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.hero-section .information-col {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.hero-section .information-col .heading {
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: uppercase; */
  /* background: linear-gradient(0deg, #f15b2b 14.88%, #f7941d 30.67%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: black;
}

.hero-section .information-col .subheading{
  font-family: Poppins;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: uppercase; */
  color: #000000c5 ;
}

.hero-section .information-col .content {
  color: #171717;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem; /* 160% */
}

.hero-img{
  width: 100%;
}

/* .hero-section .heading {
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  background: linear-gradient(95deg, #f15b2b 49.2%, #f7941d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;
}

.hero-section .content {
  color: #171717;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; 
  margin-bottom: 20px;
} */
@media screen and (max-width: 576px) {
  .hero-section {
    margin-top: 65px;
    padding: 40px;
    grid-template-columns: 1fr;
  }

  .hero-section .information-col .heading {
    font-size: 24px;
  }

  .hero-section .information-col .subheading {
    font-family: Poppins;
    font-size: 18px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* text-transform: uppercase; */
    color: #000000c5;
}

  .hero-section .information-col .content {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 160% */
  }

  /* .hero-section .heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .hero-section .content {
    font-size: 18px;
    line-height: normal;
    margin-bottom: 20px;
  } */

  .hero-section img {
    width: 100%;
  }
}
</style>

<!-- CARD SECTION -->
<style scoped>
.card-section {
  padding: 20px 120px 60px;
  background: url("../assets/esign/section-bkg-2.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.card-section .heading {
  color: #272827;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-block: 30px;
}

.card-section .card-deck {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.card-section .card-deck .card {
  border-top: 6px solid #f7941d;
  background: #fff;
  box-shadow: 5px 5px 4px 0px rgba(0, 0, 0, 0.25),
    -5px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.card-section .card-deck .card .card-title {
  color: #222121;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.card-section .card-deck .card .card-cotent {
  color: #464646;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 576px) {
  .card-section {
    padding: 40px;
  }

  .card-section .heading {
    text-align: center;
    font-size: 24px;
  }

  .card-section .card-deck {
    grid-template-columns: 1fr;
  }
}
</style>

<!-- DEVICE -->
<style scoped>
.device {
  padding: 60px 120px;
  height: calc(100vh - 65px);
  display: grid;
  grid-template-columns: 2fr 3fr;
  background: url("../assets/smart-connect/smartconnectdevices.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right center;
  align-items: center;
}

.device .heading {
  color: #000;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.device .sub-heading {
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  background: linear-gradient(180deg, #f15b2b 0%, #f7941d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.device .content {
  color: #464646;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 40px;
}

@media screen and (max-width: 576px) {
  .device {
    padding: 40px;
    align-items: flex-start;
    height: calc(100vh - 65px);
    grid-template-columns: 1fr;
    background-position: right bottom;
  }

  .device .sub-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .device .content {
    font-size: 18px;
    margin-bottom: 40px;
  }
}
</style>

<!-- ICON SECTION -->
<style scoped>
.icon-section {
  padding: 40px 120px;
  background: url("../assets/esign/section-bkg-2.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.icon-section .heading {
  text-align: center;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  color: #181818;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.icon-section .sub-heading {
  color: #464646;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 40px;
}

.icon-section .card-deck {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.icon-section .card {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.icon-section .icon {
  margin-bottom: 20px;
}

.icon-section .card-title {
  background: linear-gradient(180deg, #f15b2b 0%, #f7941d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.icon-section .card-content {
  color: #262626;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 576px) {
  .icon-section {
    padding: 40px;
  }

  .icon-section .heading {
    font-size: 20px !important;
    margin-bottom: 10px;
  }

  .icon-section .sub-heading {
    font-size: 16px;
  }

  .icon-section .card-deck {
    /* padding: 0 40px; */
    flex-direction: column;
    gap: 30px;
  }

  .icon-section .card {
    width: 100%;
  }

  .icon-section .icon {
    margin-bottom: 20px;
  }

  .icon-section .card-title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .icon-section .card-content {
    font-size: 16px;
  }
}
</style>
