<template>
  <section class="solutions">
    <!-- STICKY HEADER -->
    <div id="header">
      <ul>
        <!-- <li class="nav-item" data-section="platform" @click="scrollToSection">
            <i class="fa-solid fa-layer-group"></i>
            <p class="p-name">Platform</p>
          </li> -->
        <li class="nav-item" data-section="voice" @click="scrollToSection">
          <i class="fa-solid fa-microphone-lines"></i>
          <p class="p-name">Voice</p>
        </li>

        <li class="nav-item" data-section="lm" @click="scrollToSection">
          <i class="fa-solid fa-chart-line"></i>
          <p class="p-name">Dashboards</p>
        </li>

        
        <li class="nav-item" data-section="lm2" @click="scrollToSection">
          <i class="fa-solid fa-boxes-stacked"></i>
          <p class="p-name">Labor Management</p>
        </li>

        <li class="nav-item" data-section="mi" @click="scrollToSection">
          <i class="fa-solid fa-gears"></i>
          <p class="p-name">Maintain & Inspect</p>
        </li>

        <li class="nav-item" data-section="workflows" @click="scrollToSection">
          <i class="fa-solid fa-bars-staggered"></i>
          <p class="p-name">Workflows</p>
        </li>




        <li class="nav-item" data-section="esign" @click="scrollToSection">
          <i class="fa-solid fa-signature"></i>
          <p class="p-name">eSign</p>
        </li>
        <li class="nav-item" data-section="section7" @click="scrollToSection">
          <i class="fa-solid fa-tower-broadcast"></i>
          <p class="p-name">Smart Connect</p>
        </li>
        <li class="nav-item" data-section="vdt" @click="scrollToSection">
          <i class="fa-solid fa-code"></i>
          <p class="p-name">Voice Dev Kit</p>
        </li>
      </ul>
    </div>

    <!-- HERO SECTION -->
    <!-- 
      Desktop View : 2 col
      Tablet View : 2 col
      Mobile View : 1 Col
      -->
    <div
      class="hero-section"
      data-section="platform"
      id="platform"
      @mouseenter="removeHighlight"
    >
      <div class="mock-information-col">
        <h4 class="mock-top-heading">Complete. Flexible. Affordable.  </h4>
        <h1 class="mock-heading">Supply Chain Solutions Reimagined </h1>
        <p class="content">
          Streamline your supply chain operations with unique & fresh
          methods. Drive notably higher productivity, accuracy, and safety at
          remarkably affordable costs. 
        </p>
        <div class="button-stack">
          <ButtonPrimary
            :icon="true"
            text="schedule demo"
            iconClass="fa-regular fa-calendar"
            btnLink="/contact-us"
          />
          <!-- <ButtonSecondary
              :icon="true"
              text="free Trial"
              btnLink="/contact-us"
            /> -->

          <!-- <ButtonDisable
              :icon="true"
              text="Learn more"
              iconClass="fa-solid fa-circle-info"
              btnLink=""
            /> -->
        </div>
      </div>
      <div class="mock-animation-col mock">
        <img :src="platformLogo" alt="" class="platfomm-img" />
        <div class="mock-images">
          <img :src="graphics1home" alt="" class="mock-graph-img mockimg1" />
          <img :src="graphics2home" alt="" class="mock-graph-img mockimg2" />
        </div>
      </div>
    </div>

    <!-- VOICE -->
    <!-- 
      Desktop View : 3 col
      Tablet View : 3 col
      Mobile View : 1 Col
      -->
    <div
      class="section dark"
      id="voice"
      data-section="voice"
      @mouseenter="highlightNav"
    >
      <div class="voice">
        <div class="information-col">
          <div class="new-logo">
            <img :src="almasonsginiwhite" alt="" class="section-logo" />
            <h2 class="heading highlight-gr">VOICE</h2>
          </div>
          <p class="content">
            Almasons voice-driven workflows are streamlined, easy-to-learn
            mobile worker apps. These cover all major supply chain functions
            from Manufacturing & Warehousing through Transport & Retail. And
            these apps run on the widest range of rugged Android devices from
            most vendors. Integration with all major ERP & WMS are assured, and
            we are SAP-Certified.
          </p>

          <div class="button-stack">
            <ButtonPrimary
              :icon="false"
              text="schedule demo"
              iconClass="fa-regular fa-calendar"
              btnLink="/contact-us"
            />
            <!-- <ButtonSecondary
                  :icon="false"
                  text="free Trial"
                  btnLink="/contact-us"
                /> -->
            <ButtonDisable
              :icon="false"
              text="Learn more"
              iconClass="fa-solid fa-circle-info"
              btnLink="/gini-voice"
            />
          </div>
        </div>
        <div class="icon-col">
          <div class="box-background">
            <ul class="highlight-list">
              <li class="list-element">
                <i class="fa-solid fa-boxes-packing"></i>Receiving
              </li>
              <li class="list-element">
                <i class="fa-solid fa-people-carry-box"></i>Putaway
              </li>
              <li class="list-element">
                <i class="fa-solid fa-cart-flatbed"></i>Replenishment
              </li>
              <li class="list-element">
                <i class="fa-solid fa-truck-ramp-box"></i>Picking
              </li>
              <li class="list-element">
                <i class="fa-solid fa-right-left"></i>Stock-Transfers
              </li>
              <li class="list-element">
                <i class="fa-solid fa-list-check"></i>Loading
              </li>
            </ul>
            <div class="image-col">
              <div class="carousel-container">
                <div class="carousel-slidev">
                  <img class="voice-img" :src="receiving" alt="Slide 1" />
                </div>
                <div class="carousel-slidev">
                  <img class="voice-img" :src="putaway" alt="Slide 2" />
                </div>
                <div class="carousel-slidev">
                  <img class="voice-img" :src="replenishment" alt="Slide 3" />
                </div>
                <div class="carousel-slidev">
                  <img class="voice-img" :src="picking" alt="Slide 4" />
                </div>
                <div class="carousel-slidev">
                  <img class="voice-img" :src="stocktransfer" alt="Slide 5" />
                </div>
                <div class="carousel-slidev">
                  <img class="voice-img" :src="loading" alt="Slide 6" />
                </div>

                <!-- Add more slides as needed -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- DASHBOARDS -->
    <!-- 
      Desktop View : 2 col
      Tablet View : 2 col
      Mobile View : 1 Col
      -->
    <div
      class="section lmd"
      id="lm"
      data-section="lm"
      @mouseenter="highlightNav"
    >
      <div class="info-section">
        <div class="new-logo">
          <img :src="almasonsginiblack" alt="" class="section-logo" />
          <h2 class="heading highlight-gr">DASHBOARDS</h2>
        </div>

        <p class="content">
          Optimize decision-making with user-friendly dashboards. Gain valuable
          insights into operations and track operator performance. Our system
          captures data meticulously, providing accurate, real-time information
          for a comprehensive understanding of your workflow dynamics and
          facilitating informed decision-making.
        </p>
        <div class="button-stack">
          <div class="button-stack">
            <ButtonPrimary
              :icon="false"
              text="schedule demo"
              iconClass="fa-regular fa-calendar"
              btnLink="/contact-us"
            />
            <!-- <ButtonSecondary
                :icon="false"
                text="free Trial"
                btnLink="/contact-us"
              /> -->
            <ButtonDisable
              :icon="false"
              text="Learn more"
              iconClass="fa-solid fa-circle-info"
              btnLink="/gini-lm-and-dashboards"
            />
          </div>
        </div>
      </div>
      <div class="image-grid">
        <!-- C1 R1 -->
        <div class="box">
          <img :src="graph8" alt="" class="dash-img" />
          <div class="text-box">
            <!-- phase 2 -->
            <!-- <h1 class="text-box-heading">TITLE</h1>
              <p class="content">
                TEXT COMES HERE
              </p> -->
          </div>
        </div>
        <!-- C2 R2 -->
        <div class="box">
          <img :src="graph2" alt="" class="dash-img" />
          <div class="text-box">
            <!-- phase 2 -->
            <!-- <h1 class="text-box-heading">TITLE</h1>
              <p class="content">
                TEXT COMES HERE
              </p> -->
          </div>
        </div>
        <!-- C3 R1 -->
        <div class="box">
          <img :src="graph6" alt="" class="dash-img" />
          <div class="text-box">
            <!-- phase 2 -->
            <!-- <h1 class="text-box-heading">TITLE</h1>
              <p class="content">
                TEXT COMES HERE
              </p> -->
          </div>
        </div>
        <!-- C1 R2 -->
        <div class="box">
          <img :src="graph11" alt="" class="dash-img" />
          <div class="text-box">
            <!-- phase 2 -->
            <!-- <h1 class="text-box-heading">TITLE</h1>
              <p class="content">
                TEXT COMES HERE
              </p> -->
          </div>
        </div>
        <!-- C2 R1 -->
        <div class="box">
          <img :src="graph5" alt="" class="dash-img" />
          <div class="text-box">
            <!-- phase 2 -->
            <!-- <h1 class="text-box-heading">TITLE</h1>
              <p class="content">
                TEXT COMES HERE
              </p> -->
          </div>
        </div>
        <!-- C3 R2 -->
        <div class="box">
          <img :src="graph10" alt="" class="dash-img" />
          <div class="text-box">
            <!-- phase 2 -->
            <!-- <h1 class="text-box-heading">TITLE</h1>
              <p class="content">
                TEXT COMES HERE
              </p> -->
          </div>
        </div>
        <!-- C4 R2 -->
        <div class="box">
          <img :src="graph9" alt="" class="dash-img" />
          <div class="text-box">
            <!-- phase 2 -->
            <!-- <h1 class="text-box-heading">TITLE</h1>
              <p class="content">
                TEXT COMES HERE
              </p> -->
          </div>
        </div>
        <!-- C2 R2 -->
        <div class="box">
          <img :src="graph3" alt="" class="dash-img" />
          <div class="text-box">
            <!-- phase 2 -->
            <!-- <h1 class="text-box-heading">TITLE</h1>
              <p class="content">
                TEXT COMES HERE
              </p> -->
          </div>
        </div>
      </div>
    </div>

    <!-- LM -->
    <!--
      Desktop View : 2 col
      Tablet View : 2 col
      Mobile View : 1 Col
      -->
    <div
      class="section workflows lm2"
      id="lm2"
      data-section="lm2"
      @mouseenter="highlightNav"
    >
      <div class="img-cover">
        <img class="section-img" :src="lmImage2" />
      </div>
      <div class="info-section">
        <div
          class="new-logo"
          style="
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: flex-start;
            gap: 0px;
          "
        >
          <img :src="almasonsginiwhite" alt="" class="section-logo" />
          <h2 class="heading highlight-gr">Labor Management</h2>
        </div>

        <p class="content">
          Capture second-by-second data on each worker's status. Use our
          Analytics engine to discover wasted motion, workflows that need to be
          streamlined, and study how one worker, team, or site compares to
          others.
        </p>
        <div class="button-stack">
          <div class="button-stack">
            <ButtonPrimary
              :icon="false"
              text="schedule demo"
              iconClass="fa-regular fa-calendar"
              btnLink="/contact-us"
            />
            <!-- <ButtonSecondary
                :icon="false"
                text="free Trial"
                btnLink="/contact-us"
              /> -->
            <ButtonDisable
              :icon="false"
              text="Learn more"
              iconClass="fa-solid fa-circle-info"
              btnLink="/gini-lm-and-dashboards"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- M&I -->
    <!-- 
      Desktop View : 2 col
      Tablet View : 2 col
      Mobile View : 1 Col
      -->
    <div
      class="section mandi"
      id="mi"
      data-section="mi"
      @mouseenter="highlightNav"
    >
      <div class="img-section">
        <img :src="mniSection" alt="" class="section-img" />
      </div>
      <div class="info-section">
        <div class="new-logo">
          <img :src="almasonsginiblack" alt="" class="section-logo" />
          <h2 class="heading highlight-gr">Maintain & Inspect</h2>
        </div>
        <!-- <img :src="mniLogo" alt="" class="section-logo" /> -->
        <p class="content">
          Transform your operations with our solution, designed to modernize and
          streamline equipment checklists. Embrace the ease of digitization and
          hands-free control, elevating efficiency and precision. Your tasks can
          be made more efficient using the convenience of voice interaction.
        </p>
        <div class="button-stack">
          <div class="button-stack">
            <ButtonPrimary
              :icon="false"
              text="schedule demo"
              iconClass="fa-regular fa-calendar"
              btnLink="/contact-us"
            />
            <!-- <ButtonSecondary
                :icon="false"
                text="free Trial"
                btnLink="/contact-us"
              /> -->
            <ButtonDisable
              :icon="false"
              text="Learn more"
              iconClass="fa-solid fa-circle-info"
              btnLink="/gini-m&i"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- WORKFLOWS -->
    <!-- 
      Desktop View : 2 col
      Tablet View : 2 col
      Mobile View : 1 Col
      -->
    <div
      class="section workflows"
      id="workflows"
      data-section="workflows"
      @mouseenter="highlightNav"
    >
      <div class="img-cover"></div>
      <div class="info-section">
        <div class="new-logo">
          <img :src="almasonsginiwhite" alt="" class="section-logo" />
          <h2 class="heading highlight-gr">Workflows</h2>
        </div>

        <p class="content">
          Our workflows are modern user-friendly Android apps your workers will
          love. They are designed to be completely voice-driven or can be
          touchscreen-controlled. Our Workflows are pre-built for speed of
          deployment, but easily adapted to your unique needs and processes.
          Drive operational speed, accuracy, and safety to new levels by going
          Eyes-Up & Hands-Free with Voice.
        </p>
        <div class="card-grid">
          <div class="icon-card">
            <div class="card-icon">
              <i class="fa-solid fa-industry"></i>
            </div>
            <div class="card-content">
              <h5 class="card-heading">Manufacturing</h5>
              <p class="content"></p>
            </div>
          </div>
          <div class="icon-card">
            <div class="card-icon"><i class="fa-solid fa-warehouse"></i></div>
            <div class="card-content">
              <h5 class="card-heading">Warehouse/DC</h5>
              <p class="content"></p>
            </div>
          </div>
          <div class="icon-card">
            <div class="card-icon">
              <i class="fa-solid fa-boxes-packing"></i>
            </div>
            <div class="card-content">
              <h5 class="card-heading">Pack & Ship</h5>
              <p class="content"></p>
            </div>
          </div>
          <div class="icon-card">
            <div class="card-icon">
              <i class="fa-solid fa-truck-arrow-right"></i>
            </div>
            <div class="card-content">
              <h5 class="card-heading">Transport & Logistics</h5>
              <p class="content"></p>
            </div>
          </div>
          <div class="icon-card">
            <div class="card-icon"><i class="fa-solid fa-store"></i></div>
            <div class="card-content">
              <h5 class="card-heading">Retail Store</h5>
              <p class="content"></p>
            </div>
          </div>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: left;
            gap: 20px;
            margin-top: 20px;
            margin-left: 14px;
          "
        >
          <ButtonDisable
            :icon="false"
            text="Learn More"
            btnLink="/gini-workflows"
          />
          <!-- <ButtonPrimary
                :icon="false"
                text="schedule demo"
                iconClass="fa-regular fa-calendar"
                btnLink="/contact-us"
              /> -->
          <!-- <ButtonSecondary
                :icon="false"
                text="free Trial"
                btnLink="/contact-us"
              /> -->
        </div>
      </div>
    </div>

    <!-- ESIGN -->
    <!-- 
      Desktop View : 2 col
      Tablet View : 2 col
      Mobile View : 1 Col
      -->
    <div
      class="section esign"
      id="esign"
      data-section="esign"
      @mouseenter="highlightNav"
    >
      <div class="info-section">
        <div class="new-logo">
          <img :src="almasonsginiblack" alt="" class="section-logo" />
          <h2 class="heading highlight-gr">eSIGN</h2>
        </div>
        <!-- <img :src="esignLogo" alt="" class="section-logo" /> -->
        <p class="content">
          Our affordable electronic signature solution optimizes the signing
          process for smarter and quicker workflows. Enjoy seamless transactions
          and enhanced efficiency as you expertly manage your business processes
          with our innovative e-signature solution.
        </p>
        <div class="button-stack">
          <div class="button-stack">
            <ButtonPrimary
              :icon="false"
              text="schedule demo"
              iconClass="fa-regular fa-calendar"
              btnLink="/contact-us"
            />
            <!-- <ButtonSecondary
                :icon="false"
                text="free Trial"
                btnLink="/contact-us"
              /> -->
            <ButtonDisable
              :icon="false"
              text="Learn more"
              iconClass="fa-solid fa-circle-info"
              btnLink="/gini-esign"
            />
          </div>
        </div>
      </div>
      <div class="img-section">
        <div class="esign-card-grid">
          <div class="card">
            <div class="esign-card-icon">
              <i class="fa-solid fa-store"></i>
            </div>
            <h4 class="card-title">Retail</h4>
            <p class="content">Customer Pickup, Store Delivery</p>
          </div>
          <div class="card">
            <div class="esign-card-icon">
              <i class="fa-solid fa-industry"></i>
            </div>
            <h5 class="card-title">Manufacturing</h5>
            <p class="content">Inspection, Quality Audit</p>
          </div>
          <div class="card">
            <div class="esign-card-icon">
              <i class="fa-solid fa-warehouse"></i>
            </div>
            <h5 class="card-title">Warehouse</h5>
            <p class="content">Loading, Unloading, PO Receipts</p>
          </div>
          <div class="card">
            <div class="esign-card-icon">
              <i class="fa-solid fa-truck-fast"></i>
            </div>
            <h5 class="card-title">Shipping</h5>
            <p class="content">
              Warehouse Pickup, Store Delivery, Customer Delivery
            </p>
          </div>
          <div class="card">
            <div class="esign-card-icon">
              <i class="fa-solid fa-magnifying-glass"></i>
            </div>
            <h5 class="card-title">M&I</h5>
            <p class="content">
              Forklift Inspection, Equipment Inspection, Audit
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- SMART CONNECT -->
    <!-- 
      Desktop View : 2 col
      Tablet View : 2 col
      Mobile View : 1 Col
      -->
    <div
      class="section sc"
      id="section7"
      data-section="section7"
      @mouseenter="highlightNav"
    >
      <div class="img-section">
        <img :src="sconnect" alt="" class="section-img" />
      </div>

      <div class="info-section">
        <div class="new-logo">
          <img :src="almasonsginiwhite" alt="" class="section-logo" />
          <h2 class="heading highlight-gr">SMART CONNECT</h2>
        </div>
        <p class="content">
          Free your workers from WiFi dependency. Operate smoothly during WiFi
          interruptions with workflows backed up by Almasons GiNi™ Smart
          Connect. Provides caching of worker activity & inputs, and then
          seamlessly synchronizing with backend ERP & WMS systems once
          reconnected.
        </p>

        <ul>
          <li>
            <p class="content">
              Supports worker apps on Android 9.0 and above.
            </p>
          </li>
          <li>
            <p class="content">Continue working when WiFi is weak or down.</p>
          </li>
          <li>
            <p class="content">
              Pick from yard/trailers where no WiFi is present.
            </p>
          </li>
          <li><p class="content">Expand to temporary storage areas.</p></li>
          <li>
            <p class="content">
              No upgrades to WiFi required for voice-driven apps.
            </p>
          </li>
        </ul>

        <div class="button-stack">
          <div class="button-stack">
            <ButtonPrimary
              :icon="false"
              text="schedule demo"
              iconClass="fa-regular fa-calendar"
              btnLink="/contact-us"
            />
            <!-- <ButtonSecondary
                :icon="false"
                text="free Trial"
                btnLink="/contact-us"
              /> -->
            <ButtonDisable
              :icon="false"
              text="Learn more"
              iconClass="fa-solid fa-circle-info"
              btnLink="/gini-smart-connect"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- VDT -->
    <!-- 
      Desktop View : 2 col
      Tablet View : 2 col
      Mobile View : 1 Col
      -->
    <div
      class="section vaas"
      id="vdt"
      data-section="vdt"
      @mouseenter="highlightNav"
    >
      <div class="info-section">
        <!-- <img :src="voice" alt="" class="section-logo" /> -->
        <div class="new-logo">
          <img :src="almasonsginiblack" alt="" class="section-logo" />
          <h2 class="heading highlight-gr">VOICE DEV KIT</h2>
        </div>
        <p class="content">
          Convert your worker applications into voice-enabled systems in as
          little as one day; all it takes is a few added lines of Almasons code.
          No adjustments to your backend WMS, ERP, CRM. Streamline apps for
          higher throughput. Make apps easier to learn. Embrace a do-it-yourself
          approach without voice consultants or expensive customizations.
          Leverage Almasons decades of experience in Voice.
        </p>
        <div class="button-stack">
          <div class="button-stack">
            <ButtonPrimary
              :icon="false"
              text="schedule demo"
              iconClass="fa-regular fa-calendar"
              btnLink="/contact-us"
            />
            <!-- <ButtonSecondary
                :icon="false"
                text="free Trial"
                btnLink="/contact-us"
              /> -->
            <ButtonDisable
              :icon="false"
              text="Learn more"
              iconClass="fa-solid fa-circle-info"
              btnLink="/voice-development-kit"
            />
          </div>
        </div>
      </div>
      <div class="img-section">
        <img :src="vdk" alt="" class="section-img" />
      </div>
    </div>
  </section>

  <!-- CERTIFICATES AND PARTNERS -->
  <section class="certificatesAndPartners">
    <h1 class="heading">
      <!-- Certificates <span class="highlight-gr">&</span> Partners -->
      CERTIFIED
    </h1>

    <div class="slider">
      <div class="slide-hover">
        <div class="slide-track-1">
          <div class="slide">
            <div class="card card-img">
              <img :src="certificate1" alt="LI Datalogic" class="card-photo" />
            </div>
          </div>
          <div class="slide">
            <div class="card card-img">
              <img :src="certificate2" alt="LI Honeywell" class="card-photo" />
            </div>
          </div>
          <div class="slide">
            <div class="card card-img">
              <img :src="certificate3" alt="LI Seuic" class="card-photo" />
            </div>
          </div>
          <div class="slide">
            <div class="card card-img">
              <img :src="certificate4" alt="LI Zebra" class="card-photo" />
            </div>
          </div>
          <div class="slide">
            <div class="card card-img">
              <img :src="certificate5" alt="Sumni" class="card-photo" />
            </div>
          </div>
          <div class="slide">
            <div class="card card-img">
              <img :src="certificate6" alt="Urovo" class="card-photo" />
            </div>
          </div>
          <!-- DUPLICATE -->
          <div class="slide">
            <div class="card card-img">
              <img :src="certificate1" alt="LI Datalogic" class="card-photo" />
            </div>
          </div>
          <div class="slide">
            <div class="card card-img">
              <img :src="certificate2" alt="LI Honeywell" class="card-photo" />
            </div>
          </div>
          <div class="slide">
            <div class="card card-img">
              <img :src="certificate3" alt="LI Seuic" class="card-photo" />
            </div>
          </div>
          <div class="slide">
            <div class="card card-img">
              <img :src="certificate4" alt="LI Zebra" class="card-photo" />
            </div>
          </div>
          <div class="slide">
            <div class="card card-img">
              <img :src="certificate5" alt="Sumni" class="card-photo" />
            </div>
          </div>
          <div class="slide">
            <div class="card card-img">
              <img :src="certificate6" alt="Urovo" class="card-photo" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="img-grid-certificates">
      <div class="certificate-images">
        <img :src="certificate1" alt="LI Datalogic" class="certificate" />
        <img :src="certificate2" alt="LI Honeywell" class="certificate" />
        <img :src="certificate3" alt="LI Seuic" class="certificate" />
        <img :src="certificate4" alt="LI Zebra" class="certificate" />
        <img :src="certificate5" alt="Sumni" class="certificate" />
        <img :src="certificate6" alt="Urovo" class="certificate" />
      </div>
    </div> -->.

    <h1 class="heading">
      <!-- Certificates <span class="highlight-gr">&</span> Partners -->
      PARTNERS
    </h1>

    <div class="img-grid-partners">
        <div class="logo-img-block">
          <img :src="partner3" alt="" class="partner" />
        </div>
        <div class="logo-img-block">
          <img :src="partner2" alt="" class="partner" />
        </div>
        <div class="logo-img-block">
          <img :src="partner1" alt="" class="partner" />
        </div>
        <div class="logo-img-block">
          <img :src="partner6" alt="" class="partner" />
        </div>
        <div class="logo-img-block">
          <img :src="partner5" alt="" class="partner" />
        </div>
        <div class="logo-img-block">
          <img :src="partner4" alt="" class="partner" />
        </div>
        <div class="logo-img-block">
          <img :src="partner9" alt="" class="partner" />
        </div>
        <div class="logo-img-block">
          <img :src="partner8" alt="" class="partner" />
        </div>
        <div class="logo-img-block">
          <img :src="partner7" alt="" class="partner" />
        </div>
      </div>
  </section>

  <!-- CONTACT US FORM -->
  <section class="contact-us" id="contact">
    <div class="information-section">
      <img :src="almasonswhite" alt="" class="almasons-white-logo" />
      <!-- <div class="information"> -->
      <p class="highlight-points">
        <span class="highlight-text">Supply Chain Solutions Reimagined  </span>
      </p>

      <p class="content" style="text-align: left">
        Drive higher throughput, accuracy, and safety with Almasons.
      </p>
      <p class="content" style="text-align: left">
        Give us 30 minutes and we will explain how to achieve up to 30%
        improvements in as little as 30 days.
      </p>
      <div class="social-media">
        <div class="icon-box">
          <a href="https://www.youtube.com/@almasons">
            <i class="fa-brands fa-youtube" style="color: white"></i>
          </a>
        </div>
        <div class="icon-box">
          <a href="https://www.linkedin.com/company/almasons/mycompany/">
            <i class="fa-brands fa-linkedin" style="color: white"></i>
          </a>
        </div>
        <!-- <div class="icon-box">
              <i class="fa-brands fa-square-twitter"></i>
            </div> -->
        <!-- <div class="icon-box">
              <i class="fa-brands fa-blogger"></i>
            </div> -->
      </div>
      <!-- </div> -->
    </div>
    <div class="form-area">
      <h2 class="heading">Get in <span class="highlight-gr">Touch</span></h2>
      <h4 class="sub-heading">
        24/7 We will answer your questions and problems
      </h4>
      <form id="contactForm" @submit.prevent="getFormData()">
        <div class="form-grid">
          <div class="input-container">
            <i class="fa-solid fa-user icon"></i>
            <input
              class="input-field"
              type="text"
              placeholder="Name"
              name="name"
              pattern="[A-Za-z\s]+"
              required
              title="The name should contain only the alphabets.."
              autocomplete="off"
              v-model="userName"
            />
          </div>
          <div class="input-container">
            <i class="fa-solid fa-briefcase icon"></i>
            <input
              class="input-field"
              type="text"
              placeholder="Company Name"
              name="c-name"
              pattern="[A-Za-z\s]+"
              title="The Company name should contain only the alphabets.."
              autocomplete="off"
              v-model="companyName"
            />
          </div>
          <div class="input-container">
            <i class="fa-solid fa-at icon"></i>
            <input
              class="input-field"
              type="email"
              placeholder="Email"
              v-model="email"
              ref="emailfield"
              @input="handleInput"
              name="email"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
              required
              title="The Email ID must match the standard format."
              autocomplete="off"
            />
          </div>
          <div class="input-container">
            <i class="fa-solid fa-phone icon"></i>
            <select
              class="input-field-ph"
              name="countryCode"
              required
              ref="countrycode"
              v-model="userCountryCode"
            >
              <option value="" disabled selected>--</option>
              <template v-for="code in countryCodes" :key="code">
                <option :data-countryCode="code" :value="code">
                  {{ code }}
                </option>
              </template>
            </select>
            <input
              class="input-field"
              type="number"
              placeholder="Phone"
              name="phone"
              v-model="phone"
              required
              @input="handleInput"
              ref="phonefield"
              autocomplete="off"
            />
          </div>
          <div class="input-container">
            <i class="fa-solid fa-desktop icon"></i>
            <select
              id="options"
              name="options[]"
              class="input-field"
              v-model="selectedSolution"
              required
            >
              <option value="" disabled selected>Select a Solution</option>
              <option>Almasons GiNi&#x2122; Workflows</option>
              <option>Almasons GiNi&#x2122; Voice</option>
              <option>Almasons GiNi&#x2122; LM & Dashboards</option>
              <option>Almasons GiNi&#x2122; Maintain and Inspect</option>
              <option>Almasons GiNi&#x2122; eSign</option>
              <option>Almasons GiNi&#x2122; Smart Connect</option>
              <option>Almasons GiNi&#x2122; Voice Dev Kit</option>
              <option>Other Topics</option>
            </select>
          </div>
          <div class="input-container">
            <i class="fas fa-address-card icon"></i>
            <input
              class="input-field"
              type="text"
              placeholder="Job Title"
              name="jobtitle"
              pattern="[A-Za-z\s]+"
              title="The Job title should contain only the alphabets.."
              autocomplete="off"
              v-model="jobTitle"
            />
          </div>
          <div class="input-container">
            <textarea
              name=""
              class="form-textarea"
              rows="4"
              placeholder="Message..."
              v-model="userMessage"
            ></textarea>
          </div>
          <div class="captcha-container">
            <h2>Verify that you are human</h2>
            <p>Type the following CAPTCHA:</p>
            <canvas id="captchaCanvas" width="200" height="50"></canvas>
            <input
              type="text"
              id="userAnswer"
              placeholder="Your answer"
              required
            />
            <!-- <button onclick="checkCaptcha()">Submit</button> -->
          </div>
          <div class="submit-button">
            <button class="btn-form">SUBMIT</button>
          </div>
        </div>
      </form>
    </div>
  </section>

  <!-- FAQS  -->
  <section class="faq">
    <h1 class="heading">
      Frequently Asked <span class="highlight-gr">Questions</span>
    </h1>

    <div class="question-block">
      <button class="accordion">What is the Almasons GiNi™ Platform?</button>
      <div class="panel">
        <p class="content">
          A comprehensive portfolio of solutions, including mobile apps or
          workflows that are often voice-enabled, supervisor controls, labor
          management systems & dashboards, Maintain & Inspect, eSign, Smart Connect, and a
          voice development kit. Backend system integration to ERP, WMS, TMS,
          CRM systems is part of our offerings. We support solutions that work
          with a wide range of worker equipment types including rugged
          handhelds, voice gear, vision-picking headsets, robotics and automated
          material handling equipment controls.
        </p>
      </div>

      <hr />

      <button class="accordion">
        How can I test GiNi Voice in my environment?
      </button>
      <div class="panel">
        <p class="content">
          Begin with a risk-free 3-day Proof-of-Concept test OR a one-month
          traditional pilot.
        </p>
      </div>

      <hr />

      <button class="accordion">
        What insights can Almasons Labor Management provide?
      </button>
      <div class="panel">
        <p class="content">
          Our workflows capture second-by-second data on worker status changes,
          such as specific tasks assigned, travel time, breaks, lunch, idle
          time, assignment to other temporary tasks. This level of detail is not
          typically tracked by most ERP & WMS systems. We then allow easy
          analysis of the raw worker data, and to drive graphical comparisons of
          one worker to others, across teams, or sites in our graphical
          Dashboards. This data can be used to set minimum performance
          standards/quotas, to coach or support disciplinary actions for
          workers, to drive compensation, support gamification challenges, and
          to discover potential process changes to eliminate wasted motion and
          other inefficiencies.
        </p>
      </div>

      <hr />

      <button class="accordion">
        How can Almasons Workflows enhance my operations?
      </button>
      <div class="panel">
        <p class="content">
          Our workflows are designed using modern GUI techniques to make them
          easy to learn and use. They can be driven voice-only, via touchscreen
          inputs, or a hybrid of both – whichever works best for your
          requirements in each function. Almasons easily adapts our apps to your
          specific needs, and we do not dictate how you must operate. Our voice
          solutions support 72 languages and dialects to support your diverse
          workforce.
        </p>
      </div>

      <hr />

      <button class="accordion">
        How does GiNi Maintain & Inspect benefit me?
      </button>
      <div class="panel">
        <p class="content">
          We voice-enable the collection of common inputs for typical
          maintenance and inspection functions. This allows you to work
          hands-free speeding up the process. For example, we digitize
          OSHA-required daily checklists for common warehouse equipment types,
          eliminating paper, and requiring the completion of these daily checks
          at a specified time and in order. We have crosschecks that flag
          falsified inputs. This enforces compliance and simplifies
          record-keeping. Our solution is cloud-based eliminating costly setups
          and servers. We offer a risk-free trial.
        </p>
      </div>

      <hr />

      <button class="accordion">How does your eSign product work?</button>
      <div class="panel">
        <p class="content">
          Almasons eSign embeds capturing digital signatures at key points in
          your workflows. We allow this to occur on mobile phones, on rugged
          handheld devices, on tablets, or central kiosks as part of say a Truck
          Driver’s Check-In Procedure.
        </p>
        <p class="content">
          Our solution is SAP-certified and is compatible with any WMS, ERP, or
          Transport Management System.
        </p>
      </div>

      <hr />

      <button class="accordion">What does your Voice Dev Kit offer?</button>
      <div class="panel">
        <p class="content">
          Our Voice Dev Kit allows you to convert your existing apps to add
          voice-driven functions in as little as a day, with provided code
          segments. We support 72 different languages and dialects – the highest
          in the industry today. You can leverage the decades of voice
          experience from Almasons by following our best practices and tech
          support guidance.
        </p>
      </div>

      <!-- <hr />
  
        <button class="accordion">
          What functions does GiNiTM WMS support in warehouse management?
        </button>
        <div class="panel">
          <p class="content">
            GiNiTM WMS supports operations from receiving and putaway to picking,
            replenishment, loading, cross-docking, and value-added services.
          </p>
        </div> -->

      <hr />

      <button class="accordion">
        Can I use Smart Connect to allow offline storage locations?
      </button>
      <div class="panel">
        <p class="content">
          Yes, using Smart Connect allows users to continue to work when they
          are temporarily disconnected or where no WiFi is present at all. This
          allows you to use outdoor or temporary storage locations where you do
          not have WiFi installed. You can for example send pickers to trailers
          in your yard or overflow storage containers – they’ll simply follow
          normal picking instructions just as if they were connected on WiFi.
        </p>
      </div>
    </div>
    <div class="action-btns" style="margin-top: 30px">
      <ButtonPrimary
        :icon="false"
        text="contact us"
        iconClass="fa-regular fa-calendar"
      />
    </div>
  </section>
</template>

<script>
//demo landing images

import graphics1home from "../assets/MockLanding/graphics3home.png";
import graphics2home from "../assets/MockLanding/graphics2home.png";

// CERTIFICATES IMAGES
import certificate1 from "../assets/Certificates/zebra.png";
import certificate2 from "../assets/Certificates/honeywell.png";
import certificate3 from "../assets/Certificates/seuic.png";
import certificate4 from "../assets/Certificates/datalogic.png";
import certificate5 from "../assets/Certificates/sunmi.png";
import certificate6 from "../assets/Certificates/urovo.png";

// PARTNERS
import partner1 from "../assets/Partners/Accenture-logo.png";
import partner2 from "../assets/Partners/Levata_Logo.png";
import partner3 from "../assets/Partners/connection.png";
import partner4 from "../assets/Partners/DecisionPoint.png";
import partner5 from "../assets/Partners/lowry.png";
import partner6 from "../assets/Partners/portable-intelligence.png";
import partner7 from "../assets/Partners/retsol.png";
import partner8 from "../assets/Partners/scansource.png";
import partner9 from "../assets/Partners/Valutrack.png";

// CLIENTS
import aesculap from "../assets/Clients/aesculap.jpg";
import bunge from "../assets/Clients/bunge.jpg";
import cocacola from "../assets/Clients/cocacola.jpg";
import lenovo from "../assets/Clients/lenovo.jpg";
import macmillan from "../assets/Clients/macmillan.jpg";
import samsung from "../assets/Clients/samsung.jpg";

// LOGO IMAGES
import almasonswhite from "../assets/almasons-white-logo.png";
import almasonsginiblack from "../assets/almasons-gini-black.png";
import almasonsginiwhite from "../assets/almasons-gini-white.png";
import platformLogo from "../assets/platform.png";

// VOICE SECTION
import receiving from "../assets/recieving2.png";
import loading from "../assets/loading2.png";
import putaway from "../assets/putawayvoice.png";
import picking from "../assets/homepagevoicepicking.png";
import replenishment from "../assets/homepagevoicereplenishment.png";
import stocktransfer from "../assets/homepagevoicestock.png";
// import yardmanagement from "../assets/yardmanagement.png";

// GRAPHS
import graph1 from "../assets/graph1.png";
import graph2 from "../assets/graph2.png";
import graph3 from "../assets/graph12.png";
import graph4 from "../assets/graph4.png";
import graph5 from "../assets/graph13.png";
import graph6 from "../assets/graph6.png";
import graph7 from "../assets/graph7.png";
import graph8 from "../assets/graph8.png";
import graph9 from "../assets/graph9.png";
import graph10 from "../assets/graph10.png";
import graph11 from "../assets/graph11.png";

// SECTION IMAGES
import mniSection from "../assets/m&i-img.jpg";
import sconnect from "../assets/sconnect.png";
import vdk from "../assets/vdk.png";
import lmImage2 from "../assets/lm-2.png";

// COMPONENTS
import ButtonPrimary from "@/components/ButtonPrimary.vue";
import ButtonSecondary from "@/components/ButtonSecondary.vue";
import ButtonDisable from "@/components/ButtonDisable.vue";

export default {
  components: {
    ButtonDisable,
    ButtonSecondary,
    ButtonPrimary,
  },
  data() {
    return {
      graphics1home: graphics1home,
      graphics2home: graphics2home,
      almasonsginiblack: almasonsginiblack,
      almasonsginiwhite: almasonsginiwhite,
      almasonswhite: almasonswhite,
      // yardmanagement: yardmanagement,
      receiving: receiving,
      loading: loading,
      picking: picking,
      putaway: putaway,
      replenishment: replenishment,
      stocktransfer: stocktransfer,
      graph1: graph1,
      graph2: graph2,
      graph3: graph3,
      graph4: graph4,
      graph5: graph5,
      graph6: graph6,
      graph7: graph7,
      graph8: graph8,
      graph9: graph9,
      graph10: graph10,
      graph11: graph11,
      lmImage2: lmImage2,
      mniSection: mniSection,
      sconnect: sconnect,
      vdk: vdk,
      aesculap: aesculap,
      bunge: bunge,
      cocacola: cocacola,
      lenovo: lenovo,
      macmillan: macmillan,
      samsung: samsung,
      platformLogo: platformLogo,
      certificate1: certificate1,
      certificate2: certificate2,
      certificate3: certificate3,
      certificate4: certificate4,
      certificate5: certificate5,
      certificate6: certificate6,
      partner1: partner1,
      partner2: partner2,
      partner3: partner3,
      partner4: partner4,
      partner5: partner5,
      partner6: partner6,
      partner7: partner7,
      partner8: partner8,
      partner9: partner9,
      countryCodes: [
        // "--code--",
        "USA (+1)",
        "UK (+44)",
        "Algeria (+213)",
        "Andorra (+376)",
        "Angola (+244)",
        "Anguilla (+1264)",
        "Antigua & Barbuda (+1268)",
        "Argentina (+54)",
        "Armenia (+374)",
        "Aruba (+297)",
        "Australia (+61)",
        "Austria (+43)",
        "Azerbaijan (+994)",
        "Bahamas (+1242)",
        "Bahrain (+973)",
        "Bangladesh (+880)",
        "Barbados (+1246)",
        "Belarus (+375)",
        "Belgium (+32)",
        "Belize (+501)",
        "Benin (+229)",
        "Bermuda (+1441)",
        "Bhutan (+975)",
        "Bolivia (+591)",
        "Bosnia Herzegovina (+387)",
        "Botswana (+267)",
        "Brazil (+55)",
        "Brunei (+673)",
        "Bulgaria (+359)",
        "Burkina Faso (+226)",
        "Burundi (+257)",
        "Cambodia (+855)",
        "Cameroon (+237)",
        "Canada (+1)",
        "Cape Verde Islands (+238)",
        "Cayman Islands (+1345)",
        "Central African Republic (+236)",
        "Chile (+56)",
        "China (+86)",
        "Colombia (+57)",
        "Comoros (+269)",
        "Congo (+242)",
        "Cook Islands (+682)",
        "Costa Rica (+506)",
        "Croatia (+385)",
        "Cuba (+53)",
        "Cyprus North (+90392)",
        "Cyprus South (+357)",
        "Czech Republic (+42)",
        "Denmark (+45)",
        "Djibouti (+253)",
        "Dominica (+1809)",
        "Dominican Republic (+1809)",
        "Ecuador (+593)",
        "Egypt (+20)",
        "El Salvador (+503)",
        "Equatorial Guinea (+240)",
        "Eritrea (+291)",
        "Estonia (+372)",
        "Ethiopia (+251)",
        "Falkland Islands (+500)",
        "Faroe Islands (+298)",
        "Fiji (+679)",
        "Finland (+358)",
        "France (+33)",
        "French Guiana (+594)",
        "French Polynesia (+689)",
        "Gabon (+241)",
        "Gambia (+220)",
        "Georgia (+7880)",
        "Germany (+49)",
        "Ghana (+233)",
        "Gibraltar (+350)",
        "Greece (+30)",
        "Greenland (+299)",
        "Grenada (+1473)",
        "Guadeloupe (+590)",
        "Guam (+671)",
        "Guatemala (+502)",
        "Guinea (+224)",
        "Guinea - Bissau (+245)",
        "Guyana (+592)",
        "Haiti (+509)",
        "Honduras (+504)",
        "Hong Kong (+852)",
        "Hungary (+36)",
        "Iceland (+354)",
        "India (+91)",
        "Indonesia (+62)",
        "Iran (+98)",
        "Iraq (+964)",
        "Ireland (+353)",
        "Israel (+972)",
        "Italy (+39)",
        "Jamaica (+1876)",
        "Japan (+81)",
        "Jordan (+962)",
        "Kazakhstan (+7)",
        "Kenya (+254)",
        "Kiribati (+686)",
        "Korea North (+850)",
        "Korea South (+82)",
        "Kuwait (+965)",
        "Kyrgyzstan (+996)",
        "Laos (+856)",
        "Latvia (+371)",
        "Lebanon (+961)",
        "Lesotho (+266)",
        "Liberia (+231)",
        "Libya (+218)",
        "Liechtenstein (+417)",
        "Lithuania (+370)",
        "Luxembourg (+352)",
        "Macao (+853)",
        "Macedonia (+389)",
        "Madagascar (+261)",
        "Malawi (+265)",
        "Malaysia (+60)",
        "Maldives (+960)",
        "Mali (+223)",
        "Malta (+356)",
        "Marshall Islands (+692)",
        "Martinique (+596)",
        "Mauritania (+222)",
        "Mayotte (+269)",
        "Mexico (+52)",
        "Micronesia (+691)",
        "Moldova (+373)",
        "Monaco (+377)",
        "Mongolia (+976)",
        "Montserrat (+1664)",
        "Morocco (+212)",
        "Mozambique (+258)",
        "Myanmar (+95)",
        "Namibia (+264)",
        "Nauru (+674)",
        "Nepal (+977)",
        "Netherlands (+31)",
        "New Caledonia (+687)",
        "New Zealand (+64)",
        "Nicaragua (+505)",
        "Niger (+227)",
        "Nigeria (+234)",
        "Niue (+683)",
        "Norfolk Islands (+672)",
        "Northern Marianas (+670)",
        "Norway (+47)",
        "Oman (+968)",
        "Palau (+680)",
        "Panama (+507)",
        "Papua New Guinea (+675)",
        "Paraguay (+595)",
        "Peru (+51)",
        "Philippines (+63)",
        "Poland (+48)",
        "Portugal (+351)",
        "Puerto Rico (+1787)",
        "Qatar (+974)",
        "Reunion (+262)",
        "Romania (+40)",
        "Russia (+7)",
        "Rwanda (+250)",
        "San Marino (+378)",
        "Sao Tome & Principe (+239)",
        "Saudi Arabia (+966)",
        "Senegal (+221)",
        "Serbia (+381)",
        "Seychelles (+248)",
        "Sierra Leone (+232)",
        "Singapore (+65)",
        "Slovak Republic (+421)",
        "Slovenia (+386)",
        "Solomon Islands (+677)",
        "Somalia (+252)",
        "South Africa (+27)",
        "Spain (+34)",
        "Sri Lanka (+94)",
        "St. Helena (+290)",
        "St. Kitts (+1869)",
        "St. Lucia (+1758)",
        "Sudan (+249)",
        "Suriname (+597)",
        "Swaziland (+268)",
        "Sweden (+46)",
        "Switzerland (+41)",
        "Syria (+963)",
        "Taiwan (+886)",
        "Tajikstan (+7)",
        "Thailand (+66)",
        "Togo (+228)",
        "Tonga (+676)",
        "Trinidad & Tobago (+1868)",
        "Tunisia (+216)",
        "Turkey (+90)",
        "Turkmenistan (+7)",
        "Turkmenistan (+993)",
        "Turks & Caicos Islands (+1649)",
        "Tuvalu (+688)",
        "Uganda (+256)",
        "Ukraine (+380)",
        "United Arab Emirates (+971)",
        "Uruguay (+598)",
        "Uzbekistan (+7)",
        "Vanuatu (+678)",
        "Vatican City (+379)",
        "Venezuela (+58)",
        "Vietnam (+84)",
        "Virgin Islands - British (+1284)",
        "Virgin Islands - US (+1340)",
        "Wallis & Futuna (+681)",
        "Yemen (North)(+969)",
        "Yemen (South)(+967)",
        "Zambia (+260)",
        "Zimbabwe (+263)",
      ],

      email: "",
      phone: "",
      userName: "",
      companyName: "",
      userCountryCode: "",
      selectedSolution: "",
      userMessage: "",
      jobTitle: "",
      captchastatus: false,
    };
  },
  mounted() {
    window.scrollTo({
      top: 0,
    });
    // Hero highlighting animation
    //   const circles = document.querySelectorAll(
    //     ".hero-section .animation-col .hero-animation-circle"
    //   );

    //   function highlightCircle(index) {
    //     circles[index].classList.add("highlight-hero-animation-ring");
    //     setTimeout(() => {
    //       circles[index].classList.remove("highlight-hero-animation-ring");
    //       const nextIndex = (index + 1) % circles.length;
    //       highlightCircle(nextIndex);
    //     }, 2500);
    //   }

    //   // Start the highlighting animation
    //   highlightCircle(0);

    // Accordian Code
    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("select");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      });
    }

    //DASHBOARDS

    var dashboards = document.querySelectorAll(".lmd .box");
    for (let i = 0; i < dashboards.length; i++) {
      dashboards[i].addEventListener("mouseover", function () {
        for (let j = 0; j < dashboards.length; j++) {
          dashboards[j].style.zIndex = "1";
          dashboards[j].style.filter = "blur(5px)";
        }
        dashboards[i].style.filter = "blur(0)";
        dashboards[i].style.transform = "scale(1.5)";
        dashboards[i].style.zIndex = "5";
      });
      dashboards[i].addEventListener("mouseout", function () {
        for (let j = 0; j < dashboards.length; j++) {
          dashboards[j].style.filter = "blur(0)";
          dashboards[j].style.transform = "scale(1)";
          dashboards[j].style.zIndex = "5";
        }
      });
    }
    for (let i = 0; i < dashboards.length; i++) {
      dashboards[i].addEventListener("mouseout", function () {
        dashboards[i].style.filter = "blur(0)";
        dashboards[i].style.transform = "scale(1)";
        dashboards[i].style.zIndex = "5";
      });
    }

    // Carousel Code
    let currentSlide = 0;
    let currentSlidev = 0;

    const slides = document.querySelectorAll(".carousel-slide");
    const slidesv = document.querySelectorAll(".carousel-slidev");
    const vm = document.querySelectorAll(".list-element");

    const totalSlides = slides.length;
    const totalSlidesv = slidesv.length;

    function showSlide(index) {
      slides.forEach((slide, i) => {
        slide.style.display = i === index ? "block" : "none";
      });
    }

    function showSlidev(index) {
      slidesv.forEach((slide, i) => {
        slide.style.display = i === index ? "block" : "none";
      });
      vm.forEach((v) => {
        v.classList.remove("select");
      });
      // console.log(vm, vm.length);
      vm[index].classList.add("select");
    }

    function nextSlide() {
      currentSlide = (currentSlide + 1) % totalSlides;
      showSlide(currentSlide);
    }

    function nextSlidev() {
      currentSlidev = (currentSlidev + 1) % totalSlidesv;
      showSlidev(currentSlidev);
    }

    function autoSlide() {
      nextSlide();
    }

    function autoSlidev() {
      nextSlidev();
    }

    // Show the initial slide
    showSlide(currentSlide);
    showSlidev(currentSlidev);

    // Automatically switch to the next slide every 3000 milliseconds (3 seconds)
    setInterval(autoSlide, 3000);
    setInterval(autoSlidev, 3000);

    //generate captcha on page load
    this.generateCaptcha();
  },
  methods: {
    getFormData() {
      this.checkCaptcha();
      if (this.captchastatus) {
        console.log("email", this.email);
        console.log("phone", this.phone);
        console.log("userName", this.userName);
        console.log("companyName", this.companyName);
        console.log("userCountryCode", this.userCountryCode);
        console.log("selectedSolution", this.selectedSolution);
        console.log("JobTitle", this.jobTitle);
        console.log("userMessage", this.userMessage);

        const formdata = {
          from_name: this.userName,
          email: this.email,
          countrycode: this.userCountryCode,
          phone: this.phone,
          companyName: this.companyName,
          selectedSolution: this.selectedSolution,
          userMessage: this.userMessage,
          previos_url: document.referrer,
        };
        emailjs
          .send("service_5yf3dqc", "template_pkat10n", formdata)
          .then(
            window.alert(
              "Thank you for the mail, will reach out to you as soon as possible..!"
            )
          );
        // this.$router.push("/");
      } else {
        window.alert("You have entered a wrong captcha.. Please try Again");
        this.generateCaptcha();
      }
    },
    handleInput() {
      //making email or phone as required
      if (this.email != "") {
        this.$refs.phonefield.removeAttribute("required");
        this.$refs.countrycode.removeAttribute("required");
      }
      if (this.phone != "") {
        this.$refs.emailfield.removeAttribute("required");
      }
    },
    generateCaptcha() {
      var captchaText = this.generateRandomLetters();
      var canvas = document.getElementById("captchaCanvas");
      var ctx = canvas.getContext("2d");
      // Clear previous content
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // Draw complex background
      this.drawComplexBackground(ctx, canvas.width, canvas.height);
      // Draw distorted text on the canvas with increased distortion
      ctx.font = "bold 30px Arial";
      ctx.fillStyle = "#333";
      this.drawDistortedText(ctx, captchaText, 20, canvas.height / 2, 15);
      // Set the captcha result as a data attribute for later verification
      canvas.dataset.captchaResult = captchaText;
    },
    generateRandomLetters() {
      var length = Math.random() * (6 - 3) + 3;
      var result = "";
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    },
    drawComplexBackground(ctx, width, height) {
      // Draw more random lines for a complex background
      ctx.strokeStyle = "#ddd";
      for (var i = 0; i < 30; i++) {
        ctx.beginPath();
        ctx.moveTo(Math.random() * width, Math.random() * height);
        ctx.lineTo(Math.random() * width, Math.random() * height);
        ctx.stroke();
      }
    },
    drawDistortedText(ctx, text, x, y, distortion) {
      for (var i = 0; i < text.length; i++) {
        var char = text.charAt(i);
        ctx.save();
        ctx.translate(x + i * 30, y);
        ctx.rotate(Math.random() * 0.4 - 0.2); // Increased rotation
        ctx.translate(-x - i * 30, -y);
        ctx.fillText(
          char,
          x + i * 30 + Math.random() * distortion,
          y + Math.random() * distortion
        ); // Increased distortion
        ctx.restore();
      }
    },
    checkCaptcha() {
      var userAnswer = document.getElementById("userAnswer").value;
      var captchaResult =
        document.getElementById("captchaCanvas").dataset.captchaResult;
      if (userAnswer == captchaResult) {
        // alert("Captcha is correct. You are human!");
        this.captchastatus = true;
        userAnswer = "";
      } else {
        // alert("Captcha is incorrect. Please try again.");
        this.captchastatus = false;
        userAnswer = "";
      }
    },

    translateScroll(move) {
      const scrollContainer = document.getElementById("cardscroll");

      if (move === "right" && this.currentCard < 5) {
        console.log("scrolling right");
        this.currentCard = this.currentCard + 1;
      } else if (move === "left" && this.currentCard > 1) {
        console.log("scrolling left");
        this.currentCard = this.currentCard - 1;
      }

      const targetElement = document.getElementById("card" + this.currentCard);

      if (targetElement && scrollContainer) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
    },

    removeHighlight(event) {
      var navItems = document.querySelectorAll(".nav-item");
      // console.log(event);
      navItems.forEach(function (item) {
        item.classList.remove("select");
      });
    },
    scrollToSection(event) {
      // Use JavaScript to scroll smoothly to the target section
      const targetSection = event.currentTarget.dataset.section;
      const targetElement = document.getElementById(targetSection);

      var navItems = document.querySelectorAll(".nav-item");
      // console.log(event);
      navItems.forEach(function (item) {
        item.classList.remove("select");
      });

      event.currentTarget.classList.add("select");

      if (targetElement) {
        const yOffset =
          targetElement.getBoundingClientRect().top -
          window.innerHeight / 2 +
          targetElement.clientHeight / 2;
        window.scrollTo({
          top: window.scrollY + yOffset - 60,
          behavior: "smooth",
        });
      }
    },
    highlightNav(event) {
      const targetSection = event.currentTarget.dataset.section;
      const selectedItem = document.querySelector(
        '[data-section="' + targetSection + '"]'
      );
      var navItems = document.querySelectorAll(".nav-item");
      // console.log(event);
      navItems.forEach(function (item) {
        item.classList.remove("select");
      });
      selectedItem.classList.add("select");
    },
  },
};
</script>

<!-- mock landing -->
<style scoped>
.mock-graph-img {
  position: absolute;
  width: 34rem;
}
.mock-images {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  height: 50%;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.mockimg1 {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  opacity: 1;
  animation: disapear1 6s ease-in-out infinite;
}

.mockimg2 {
  opacity: 0;
  animation: disapear2 6s ease-in-out infinite;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

@keyframes disapear1 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disapear2 {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
.mock-animation-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  gap: 3vw;
}

.mock-information-col {
  display: flex;
  flex-direction: column;
  gap: 2vw;
}
.mock-top-heading {
  text-align: left;
  font-family: Poppins;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 500;
  /* line-height: 80px; */
  background: linear-gradient(180deg, #f7941d 0%, #f6482f 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.mock-heading {
  text-align: left;
  opacity: 0.75;
  /* font-family: popins; */
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 750;
  line-height: 3rem; /* 67.5% */
  text-transform: uppercase;

  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #231557 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
}

.hero-section .content {
  color: #505050;
  text-align: left;
  font-size: 1rem;
  /* line-height: 28px; */
  line-height: 1.5rem;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.platfomm-img {
  width: 22rem;
}

/* Bigger Screen */
@media screen and (min-width: 1200px) {
  .mock-graph-img {
    position: absolute;
    width: 100%;
  }

  .platfomm-img {
    width: 100%;
  }

  .mock-top-heading {
    font-size: 2.3vw;
  }

  .mock-heading {
    font-size: 3.5vw;
    line-height: 4vw;
  }

  .hero-section .content {
    font-size: 1.5vw;
    line-height: 2vw;
  }
}
</style>

<!-- GENERAL -->
<style scoped>
.highlight-gr {
  background: linear-gradient(90deg, #f7941d 0%, #f15b2b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading {
  font-size: 36px;
  font-weight: 500;
  color: #272827;
  position: relative;
  display: inline-block;
}

.content {
  /* font-size: 16px; */
  color: #505050;
  text-align: justify;
  font-size: 1rem;
  /* line-height: 28px; */
  line-height: 1.5rem;
}

.sub-heading {
  font-size: 20px;
  color: #505050;
  line-height: 35px;
  font-weight: 500;
}

.button-stack {
  display: flex;
  align-items: center;
  gap: 25px;
}

/* MOBILE ALIGNMENTS */

@media screen and (max-width: 500px) {
  /* .button-stack {
      flex-direction: column;
      align-items: flex-start;
    } */
}
</style>

<!-- STICKY AREA  -->
<style scoped>
#header {
  position: sticky;
  z-index: 10000;
  top: 70px;
  background-color: #efefef;
  padding: 0.3vw 7vw;
}
#header ul {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 1vw;
  width: 100%;
}

#header li {
  background-color: #fff;
  border-radius: 7px;
  border: 2px solid #f7941d66;
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: #505050;
  font-family: monospace;
  letter-spacing: 1px;
  font-size: 0.8vw;
  font-weight: 600;
  transition: background 0.2s ease-in-out;
  padding: 10px 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-transform: uppercase;
}
#header li:hover {
  background: #505050;
  color: #fff;
}

#header li.select {
  opacity: 1;
}

#header li i {
  color: #f7941d;
  font-size: 1.5vw;
}

#header .select .p-name,
#header .select i {
  color: #fff !important;
}

#header .select {
  background: linear-gradient(90deg, #f7941d 0%, #f04e37 100%);
  border: none;
}

@media screen and (max-width: 576px) {
  #header {
    display: none;
  }
}
</style>

<!-- COMMON FOR PRODUCTS -->
<style scoped>
.section {
  min-height: 75vh;
  padding: 40px 120px;
}

@media screen and (max-width: 576px) {
  .section {
    min-height: 75vh;
    padding: 40px;
  }
  .new-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start;
    gap: 0px !important;
  }
}

.solutions .section-logo {
  height: 50px;
}

.solutions .img-section {
  text-align: right;
}

.new-logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.lmd,
.mandi,
.esign,
.vaas,
.sc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #fff;
  background-size: cover;
  align-items: center;
  position: relative;
  gap: 40px;
}

.sc {
  grid-template-columns: 2fr 3fr;
}

.voice .content,
.lmd .content,
.mandi .content,
.esign .content,
.vaas .content,
.sc .content {
  margin: 30px 0;
}

.lmd .info-section,
.mandi .info-section,
.esign .info-section,
.vaas .info-section,
.sc .info-section {
  width: 100% !important;
}

.mandi .section-img,
.lmd .section-img,
.vaas .section-img {
  width: 400px;
}
</style>

<!-- PLATFORM/HERO -->
<style scoped>
/* GENERAL */
.hero-section {
  display: grid;
  grid-template-columns: 4fr 3fr;
  align-items: center;
  gap: 5rem;
  padding: 3rem 6rem;
  margin-top: 4rem;
  height: calc(100vh - 130px);
  background: url("../assets/hero-background.png");
  background-size: cover;
  background-position: left bottom;
  background-repeat: no-repeat;
}

/* INFORMATION COLUMN */

.hero-section .information-col {
  width: 100%;
}

.hero-section .information-col .heading {
  font-size: 55px;
  font-weight: 400;
  display: block;
}

.hero-section .information-col .fullstop {
  color: #f7941d;
  font-size: 80px;
  line-height: 0;
}

.hero-section .information-col .sub-heading {
  margin: 30px 0;
}

/* ANIMATION COL */

.hero-section .animation-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  margin: 0;
}

.hero-section .animation-col .hero-animation-ring {
  width: 470px;
  height: 470px;
  border-radius: 50%;
  position: relative;
  border-width: 7px 2px;
  border-style: solid;
  border-color: #ededed;
}

.hero-section
  .animation-col
  .hero-animation-ring
  .hero-animation-circle-container {
  width: 100%;
  height: 100%;
  position: absolute;
}

.hero-section
  .animation-col
  .hero-animation-ring
  .hero-animation-circle-container
  .hero-animation-circle {
  width: 120px;
  height: 120px;
  color: black;
  background-color: #fff;
  border: 5px solid #ededed;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  text-align: center;
}

.hero-section
  .animation-col
  .hero-animation-ring
  .hero-animation-circle-container
  .hero-animation-circle:nth-child(1) {
  top: 0%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.hero-section
  .animation-col
  .hero-animation-ring
  .hero-animation-circle-container
  .hero-animation-circle:nth-child(2) {
  top: 15%;
  right: 0;
  transform: translateY(-50%);
}

.hero-section
  .animation-col
  .hero-animation-ring
  .hero-animation-circle-container
  .hero-animation-circle:nth-child(3) {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}

.hero-section
  .animation-col
  .hero-animation-ring
  .hero-animation-circle-container
  .hero-animation-circle:nth-child(4) {
  top: 85%;
  right: 0;
  transform: translateY(-50%);
}

.hero-section
  .animation-col
  .hero-animation-ring
  .hero-animation-circle-container
  .hero-animation-circle:nth-child(5) {
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.hero-section
  .animation-col
  .hero-animation-ring
  .hero-animation-circle-container
  .hero-animation-circle:nth-child(6) {
  top: 85%;
  left: 0;
  transform: translateY(-50%);
}

.hero-section
  .animation-col
  .hero-animation-ring
  .hero-animation-circle-container
  .hero-animation-circle:nth-child(7) {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.hero-section
  .animation-col
  .hero-animation-ring
  .hero-animation-circle-container
  .hero-animation-circle:nth-child(8) {
  top: 15%;
  left: 0;
  transform: translateY(-50%);
}

.hero-section
  .animation-col
  .hero-animation-ring
  .hero-animation-circle-container
  .highlight-hero-animation-ring {
  background-color: #f7941d;
  color: #fff;
  border-color: #ffbf70;
  transition: all 0.6s ease-in-out;
}

.hero-section .animation-col .hero-animation-logo {
  position: absolute;
  height: 90px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* MOBILE ALIGNMENTS */

@media screen and (max-width: 500px) {
  .hero-section {
    grid-template-columns: 1fr;
    gap: 0px;
    padding: 30px;
    height: 100%;
  }

  .hero-section .platfomm-img {
    width: 100%;
  }
  .hero-section .button-stack {
    margin-top: 20px;
  }

  .hero-section .information-col .heading {
    font-size: 35px;
  }

  .hero-section .information-col .content {
    display: none;
  }

  .mock-graph-img {
    position: relative;
    width: 100%;
  }

  .hero-section .animation-col {
    padding: 40px 0;
    justify-content: center;
  }

  .hero-section .animation-col .hero-animation-ring {
    width: calc(100vw - 80px);
    height: calc(100vw - 80px);
    border-width: 3px;
  }

  .hero-section
    .animation-col
    .hero-animation-ring
    .hero-animation-circle-container
    .hero-animation-circle {
    width: 90px;
    height: 90px;
    font-size: 12px;
    border-width: 3px;
  }

  .hero-section .animation-col .hero-animation-logo {
    height: auto;
    width: calc(100vw - 40px);
  }
}

/* <!-- Bigger screen  --> */

@media screen and (min-width: 1200px) {
  .hero-section {
    display: grid;
    grid-template-columns: 4fr 3fr;
    align-items: center;
    gap: 5vw;
    padding: 3vw 6vw;
    /* margin-top: 4vw; */
    height: calc(100vh - 130px);
    background: url("../assets/hero-background.png");
    background-size: cover;
    background-position: left bottom;
    background-repeat: no-repeat;
  }
}
</style>

<!-- VOICE -->
<style scoped>
.voice {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  height: 100%;
  width: 100%;
  min-height: calc(75vh - 100px);
  align-items: center;
}

.section#voice.dark {
  background: #000;
}

.section#voice.dark .content {
  color: #dedede;
}

.voice .information-col {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  /* gap: 30px; */
}

.voice .image-col .voice-img {
  width: 100%;
  border-radius: 5px;
  height: 400px;
  object-fit: cover;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.4);
}

.voice .icon-col,
.voice .image-col {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* width: 400px; */
  position: relative;
}

.voice .icon-col {
  width: 100%;
}

.voice .icon-col .box-background {
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, #f7941d, #f15b2b);
  border-radius: 10px;
  display: flex;
  gap: 30px;
  align-items: center;
  padding: 40px;
}

/* .dark .voice .image-col {
    background: url("../assets/blob.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  } */

.voice .icon-col .highlight-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  list-style: none;
  width: 200px;
}

.voice .icon-col .list-element {
  font-size: 18px;
  opacity: 0.7;
  transition: opacity 0.3 ease-in-out;
  cursor: pointer;
  z-index: 2;
  font-weight: 600;
}

.voice .list-element .fa-solid {
  font-size: 25px;
  color: #000;
  margin-right: 20px;
  opacity: 1;
}

.dark .voice .icon-col .list-element {
  color: #000;
  width: 13rem;
}

.voice.dark .list-element .fa-solid {
  color: #000;
}

.voice .list-element:hover {
  opacity: 1;
}

.voice .select {
  font-size: 18px;
  opacity: 1 !important;
  color: #fff !important;
}

.dark .voice .icon-col .list-element .select {
  color: #fff;
}

.voice .select .fa-solid {
  color: #fff;
}

@media screen and (max-width: 576px) {
  .voice {
    grid-template-columns: 1fr !important;
    height: fit-content !important;
  }

  .voice .image-col .voice-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 5px;
  }

  .dark .voice .icon-col .list-element {
    width: 100%;
  }

  .voice .image-col {
    width: 100%;
  }

  .voice .icon-col .box-background {
    width: 100%;
    flex-direction: column;
  }

  .voice .icon-col .highlight-list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px 10px;
  }

  .voice .icon-col .list-element {
    font-size: 14px;
    display: flex;
    flex-direction: column;
  }

  .voice .list-element .fa-solid {
    font-size: 16px;
    margin-right: 10px;
  }
}
</style>

<!-- DASHBOARDS  -->
<style scoped>
.lmd {
  background: url("../assets/light-bkg.png");
  background-position: right bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.lmd .new-logo {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.lmd {
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 4fr;
  align-items: center;
  gap: 60px;
}

.lmd .info-section {
  display: flex;
  flex-direction: column;
}

.lmd .image-grid {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 15px;
}

.lmd .text-box {
  position: absolute;
  z-index: 5;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  cursor: default;
  user-select: none;
}

.lmd .text-box .text-box-heading {
  font-size: 30px;
}

.lmd .text-box .content {
  font-size: 10px;
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 20px;
}

.lmd .image-grid .box:hover .text-box {
  opacity: 1;
}

.lmd .image-grid .box {
  position: relative;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background: #fff;
  transition-property: filter transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.lmd .image-grid .box .dash-img {
  width: 100%;
  height: auto;
}

.lmd .image-grid .box:nth-child(1) {
  grid-column: 1/3;
  grid-row: 1/3;
  transform-origin: top left;
}

.lmd .image-grid .box:nth-child(1) .text-box {
  top: 50%;
  transform: translateY(-50%);
  right: -310px;
  width: 300px;
}

.lmd .image-grid .box:nth-child(5) {
  grid-column: 3/5;
  grid-row: 1/2;
  transform-origin: top center;
}

.lmd .image-grid .box:nth-child(5) .text-box {
  bottom: 0;
  transform: translateY(110%);
  left: 0;
  width: 300px;
}

.lmd .image-grid .box:nth-child(8) {
  grid-row: 2/3;
  grid-column: 3/5;
}

.lmd .image-grid .box:nth-child(8) .text-box {
  bottom: 0;
  transform: translateY(110%);
  left: 0;
  width: 300px;
}

.lmd .image-grid .box:nth-child(3) {
  grid-column: 5/7;
  grid-row: 1/3;
  transform-origin: top right;
}

.lmd .image-grid .box:nth-child(3) .text-box {
  top: 50%;
  transform: translateY(-50%);
  left: -310px;
  width: 300px;
}

.lmd .image-grid .box:nth-child(4) {
  grid-column: 1/2;
  grid-row: 3/4;
  transform-origin: bottom left;
}

.lmd .image-grid .box:nth-child(4) .text-box {
  top: 0%;
  /* transform: translateY(-50%); */
  right: -310px;
  width: 300px;
}

.lmd .image-grid .box:nth-child(2) {
  grid-column: 2/4;
  grid-row: 3/4;
  transform-origin: bottom left;
}

.lmd .image-grid .box:nth-child(2) .text-box {
  top: 0%;
  transform: translateY(-100%);
  left: 0px;
  width: 300px;
}

.lmd .image-grid .box:nth-child(6) {
  grid-column: 4/6;
  grid-row: 3/4;
  transform-origin: bottom right;
}

.lmd .image-grid .box:nth-child(6) .text-box {
  top: 0%;
  transform: translateY(-100%);
  left: 0px;
  width: 300px;
}

.lmd .image-grid .box:nth-child(7) {
  grid-column: 6/7;
  grid-row: 3/4;
  transform-origin: bottom right;
}

.lmd .image-grid .box:nth-child(7) .text-box {
  top: 0%;
  /* transform: translateY(-50%); */
  left: -310px;
  width: 300px;
}

@media screen and (max-width: 576px) {
  .lmd {
    grid-template-columns: 1fr;
    gap: 40px;
    background: none;
  }

  .lmd .new-logo img {
    height: 45px;
    margin-bottom: 10px;
  }

  .lmd .image-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;
  }

  .lmd .image-grid .box .text-box {
    display: none;
  }

  .lmd .image-grid .box {
    padding: 0px;
    border-radius: 0px;
  }
}
</style>

<!-- LM -->
<style scoped>
.lm2.workflows {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../assets/lm-bkg.jpg");
  background-size: cover;
  background-position: left center;
  height: 100%;
  width: 100%;
}

.lm2.workflows .img-cover {
  padding-left: 120px;
  background: none;
  height: 100%;
  width: 100%;
}

.lm2.workflows .img-cover .section-img {
  width: 100%;
}

@media screen and (max-width: 576px) {
  .lm2.workflows .img-cover {
    padding-left: 0px;
  }
}
</style>

<!-- WORKFLOWS -->
<style scoped>
.wms .btn-cta-2,
.wms .btn-cta-black {
  background-color: #000 !important;
}

.workflows,
.wms {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  background: #000;
  color: #fff;
  padding: 0;
}

.workflows .content,
.wms .content {
  margin: 30px 0;
}

.workflows .info-section,
.wms .info-section {
  padding: 40px 120px 40px 40px;
}

.workflows .img-cover {
  background: url("../assets/workflow.jpg");
  background-size: cover;
  background-position: left center;
  height: 100%;
  width: 100%;
}

/* .wms .img-cover {
    background: url("../assets/wms-bkg.jpg");
    background-size: cover;
    background-position: left center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  } */

.workflows .section-logo,
.wms .section-logo {
  height: 60px;
  display: inline-block;
}

.voice .heading,
.lmd .heading,
.mi .heading,
.esign .heading,
.vaas .heading,
.workflows .heading,
.wms .heading {
  display: inline-block;
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 700;
}

.workflows .content,
.wms .content {
  color: #fff;
  opacity: 0.9;
}

.workflows .card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.workflows .card-heading {
  font-size: 18px;
  color: #f7941d;
  margin-bottom: 5px;
}

.workflows .icon-card .content {
  margin: 0;
}

.workflows .icon-card {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 10px;
}

.workflows .card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f7941d;
  font-size: 30px;
}

@media screen and (max-width: 576px) {
  .workflows,
  .wms {
    grid-template-columns: 1fr;
    align-items: center;
  }

  .workflows .info-section,
  .wms .info-section {
    padding: 40px;
  }

  .workflows .content,
  .wms .content {
    width: 100%;
  }

  .workflows .card-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
</style>

<!-- M&I -->
<style scoped>
.mandi {
  background: url("../assets/m&I.png");
  background-position: left bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.mandi .img-section {
  text-align: left;
}

.mandi .img-section .section-img {
  width: 600px;
}

.mandi .new-logo {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.mandi .new-logo .heading {
  font-weight: 700;
  font-size: 38px;
  text-transform: uppercase;
  margin-bottom: 0;
}

@media screen and (max-width: 576px) {
  .mandi {
    grid-template-columns: 1fr;
    background: none;
  }

  .mandi .img-section .section-img {
    width: 100%;
  }

  .mandi .new-logo {
    gap: 15px;
  }

  .mandi .new-logo img {
    height: 45px;
    margin-bottom: 10px;
  }

  .mandi .new-logo .heading {
    font-size: 36px;
  }
}
</style>

<!-- ESIGN -->
<style scoped>
.esign {
  background: linear-gradient(0deg, #ffffffd6, #ffffffd6),
    url("../assets/esignBkg.jpg");
  background-position: left bottom;
  grid-template-columns: 2fr 3fr;
  gap: 60px;
}

.esign .new-logo {
  /* flex-direction: column; */
  gap: 5px;
  align-items: flex-start;
  font-size: 38px;
}

.esign .esign-card-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px;
}

.esign .card {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
  text-align: left;
}

.esign .card .esign-card-icon {
  font-size: 40px;
  opacity: 0.5;
  color: #f15b2b;
}

.esign .card .card-title {
  margin: 15px 0 0;
  font-size: 18px;
  font-weight: 500;
  color: #272827;
}

.esign .card .content {
  font-size: 14px;
  margin: 0;
  text-align: left;
}

.esign .card:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 3;
}

.esign .card:nth-child(2) {
  grid-column-start: 3;
  grid-column-end: 5;
}

.esign .card:nth-child(3) {
  grid-column-start: 5;
  grid-column-end: 7;
}

.esign .card:nth-child(4) {
  grid-column-start: 2;
  grid-column-end: 4;
}

.esign .card:nth-child(5) {
  grid-column-start: 4;
  grid-column-end: 6;
}

@media screen and (max-width: 576px) {
  .esign {
    grid-template-columns: 1fr;
    gap: 60px;
  }

  .esign .new-logo {
    gap: 15px;
  }

  .esign .new-logo img {
    height: 45px;
    margin-bottom: 10px;
  }

  .esign .new-logo .heading {
    font-size: 36px;
  }

  .esign .esign-card-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .esign .card .card-title {
    margin: 10px 0 0;
  }

  .esign .card:nth-child(n) {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}
</style>

<!-- SMART CONNECT -->
<style scoped>
.sc {
  background: #000;
  grid-template-columns: 1fr 1fr;
}

.sc .new-logo {
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  font-size: 38px;
}

.sc .new-logo .heading {
  font-weight: 700 !important;
}

.sc .new-logo img {
  height: 55px;
}

.sc ul {
  margin-left: 50px;
  margin-bottom: 30px;
  list-style: none; /* Remove default bullets */
}

.sc ul li::before {
  content: "\2022";
  color: #f7941d;
  font-weight: bold;
  display: inline-block;
  width: 1em;
}

.sc .content {
  color: #dedede;
}

.sc ul .content {
  margin: 5px 0;
  display: inline-block;
}

.sc .img-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.sc .img-section img {
  filter: drop-shadow(0 0 20px #ffffff66);
}

@media screen and (max-width: 576px) {
  .sc {
    grid-template-columns: 1fr;
  }

  .sc ul {
    margin-left: 10px;
    list-style: circle !important;
  }

  .sc ul li::before {
    display: none;
  }

  .sc .img-section img {
    filter: drop-shadow(0 0 20px #ffffff66);
    width: 100%;
  }
}
</style>

<!-- VDK -->
<style scoped>
.vaas {
  background: url("../assets/light-bkg.png");
  background-position: right bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.vaas .new-logo {
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  font-size: 38px;
}

.vaas .new-logo .heading {
  font-weight: 700 !important;
}

.vaas .img-section img {
  width: 600px;
}

@media screen and (max-width: 576px) {
  .vaas {
    grid-template-columns: 1fr;
    background: none;
  }

  .vaas .new-logo img {
    height: 45px;
  }

  .vaas .img-section img {
    width: 100%;
  }
}
</style>

<!-- CERTIFICATES & PARTNERS  -->
<style scoped>
.certificatesAndPartners {
  padding: 80px 120px;
  background: url("../assets/certificate-bkg.png");
  background-size: cover;
  background-position: right center;
}

.certificatesAndPartners .img-grid-certificates .certificate-images {
  display: flex;
  /* justify-content: space-between; */
  gap: 44px;
  justify-content: center;
  /* transform: translateX(50%); */
  align-items: center;
  margin: 30px 0 40px;
  width: 100%;
  height: 300px;
  /* overflow-x: scroll; */
  /* -ms-overflow-style: none;  IE and Edge */
  /* scrollbar-width: none;  Firefox */
  animation: scrollanimation 12s infinite;
}

.certificatesAndPartners .img-grid-certificates .certificate-images:hover {
  animation-play-state: paused;
}

.certificatesAndPartners .img-grid-certificates {
  display: flex;
  /* justify-content: space-between; */
  gap: 44px;
  /* justify-content: center; */
  align-items: center;
  margin: 30px 0 40px;
  width: 100%;
  height: 300px;
  overflow: hidden;
  /* -ms-overflow-style: none;  IE and Edge */
  /* scrollbar-width: none;  Firefox */
  /* animation: scrollanimation ease-in-out 6s infinite; */
}

@keyframes scrollanimation {
  0% {
    transform: translateX(0%);
  }
  /* 50%{
      transform: translateX(-50%);
    } */
  25% {
    transform: translateX(50%);
  }

  50% {
    transform: translateX(0%);
  }

  75% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0%);
  }
}

.certificatesAndPartners .img-grid-certificates::-webkit-scrollbar {
  display: none;
}

.certificatesAndPartners .certificate {
  width: 250px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease-in-out;
}

.certificatesAndPartners .certificate:hover,
.certificatesAndPartners .logo-img-block:hover {
  transform: scale(1.1);
}

.certificatesAndPartners .img-grid-partners {
  display: grid;
    width: 100%;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr 1fr;
    gap: 40px 70px;
    margin-top: 30px;

}




.certificatesAndPartners .logo-img-block {
  background: #fff;
  padding: 25px 55px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in-out;
}

.certificatesAndPartners .logo-img-block:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 3;
}

.certificatesAndPartners .logo-img-block:nth-child(2) {
  grid-column-start: 3;
  grid-column-end: 5;
}

.certificatesAndPartners .logo-img-block:nth-child(3) {
  grid-column-start: 5;
  grid-column-end: 7;
}

.certificatesAndPartners .logo-img-block:nth-child(4) {
  grid-column-start: 7;
  grid-column-end: 9;
}

.certificatesAndPartners .logo-img-block:nth-child(5) {
  grid-column-start: 9;
  grid-column-end: 11;
}

.certificatesAndPartners .logo-img-block:nth-child(6) {
  grid-column-start: 2;
  grid-column-end: 4;
}

.certificatesAndPartners .logo-img-block:nth-child(7) {
  grid-column-start: 4;
  grid-column-end: 6;
}

.certificatesAndPartners .logo-img-block:nth-child(8) {
  grid-column-start: 6;
  grid-column-end: 8;
}

.certificatesAndPartners .logo-img-block:nth-child(9) {
  grid-column-start: 8;
  grid-column-end: 10;
}

.certificatesAndPartners .partner {
  width: 140%;
}

@media screen and (max-width: 576px) {
  .certificatesAndPartners {
    padding: 40px;
    background: transparent;
    /* background-size: contain;
      background-repeat: repeat; */
  }

  .certificatesAndPartners .img-grid-certificates {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 30px 0 20px;
  }

  .certificatesAndPartners .logo-img-block {
    padding: 25px;
  }

  .certificatesAndPartners .certificate {
    width: 100%;
  }

  .certificatesAndPartners .img-grid-partners {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 20px;
  }

  .certificatesAndPartners .logo-img-block:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .certificatesAndPartners .logo-img-block:nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 5;
  }

  .certificatesAndPartners .logo-img-block:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .certificatesAndPartners .logo-img-block:nth-child(4) {
    grid-column-start: 3;
    grid-column-end: 5;
  }

  .certificatesAndPartners .logo-img-block:nth-child(5) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .certificatesAndPartners .logo-img-block:nth-child(6) {
    grid-column-start: 3;
    grid-column-end: 5;
  }

  .certificatesAndPartners .logo-img-block:nth-child(7) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .certificatesAndPartners .logo-img-block:nth-child(8) {
    grid-column-start: 3;
    grid-column-end: 5;
  }

  .certificatesAndPartners .logo-img-block:nth-child(9) {
    grid-column-start: 2;
    grid-column-end: 4;
  }
}

.slider {
  /* background: white; */
  /* height: 550px; */
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

/* .slide-hover::before {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 250px;
  position: absolute;
  width: 150px;
  z-index: 2;
} */
/* 
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
} */

.slide-track {
  /* animation: scroll 25s linear infinite; */
  display: flex;
  width: calc(250px * 12);
}

.slide-track-1 {
  position: relative;
  animation: scroll-reverse 25s linear infinite;
  display: flex;
  width: calc(250px * 12);
}

.slide {
  height: 250px;
  width: 250px;
  padding: 20px;
}

.slide-track .slide {
  /* height: 250px; */
  width: 200px;
  padding: 20px;
}

.slide-track-1 .slide {
  height: 250px;
  width: 250px;
  padding: 20px;
}

.slide .card {
  width: 100%;
  height: 100%;
  /* border-radius: 10px; */
  border-width: 3px;
  border-style: solid;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-color: transparent;
}

.beta .card-img .card-photo {
  width: 90%;
  height: auto;
  opacity: 0.7;
}

.card-img {
  padding: 0;
  overflow: hidden;
  position: relative;
}

.card-img .card-photo {
  height: 100%;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 6));
  }
}

@keyframes scroll-reverse {
  0% {
    transform: translateX(calc(-250px * 6));
  }

  100% {
    transform: translateX(0);
  }
}
</style>

<!-- CONTACT US FORM  -->
<style scoped>
.contact-us {
  display: grid;
  grid-template-columns: 2fr 5fr;
  /* height: calc(100vh - 120px); */
}
.information-section {
  background: url("../assets/contactus-bkg.png");
  background-position: right center;
  background-size: cover;
  padding: 40px 40px 40px 120px;
  color: #fff;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 20px;
  align-items: start;
}

.information-section .highlight-points {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 300;
}

.information-section .highlight-text {
  font-weight: 600;
  text-transform: uppercase;
}

.information-section .content {
  color: #fff;
  opacity: 0.9;
}

.information-section .social-media {
  display: flex;
  justify-content: left;
  gap: 50px;
  width: 100%;
}

.information-section .icon-box {
  font-size: 30px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.information-section .icon-box:hover {
  transform: scale(1.1);
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.3));
}

.form-area {
  padding: 40px 120px 40px 40px;
  background: url("../assets/contact-form.png");
  background-size: cover;
  background-position: right center;
}

/* .form-area .heading {
    color: #272827;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  } */

.form-area .sub-heading {
  color: #505050;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 20px;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.sup {
  vertical-align: super;
  font-size: smaller;
}
.input-container {
  display: flex;
  width: 100%;
}

.input-container:nth-child(7),
.submit-button {
  grid-column-start: 1;
  grid-column-end: 3;
}

.icon {
  padding: 10px;
  background: white;
  color: #27282733;
  min-width: 50px;
  text-align: center;
  border-width: 2px 0px 2px 2px;
  border-style: solid;
  border-color: #27282733;
  border-radius: 5px 0 0 5px;
}

.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
  border-width: 2px 2px 2px 0px;
  border-style: solid;
  border-color: #27282733;
  border-radius: 0 5px 5px 0;
}

.input-field-ph {
  width: 70px;
  padding: 10px;
  outline: none;
  border-width: 2px 0px 2px 0px;
  border-style: solid;
  border-color: #27282733;
  border-radius: 0;
}

.form-textarea {
  width: 100%;
  padding: 10px;
  outline: none;
  border-width: 2px;
  border-style: solid;
  border-color: #27282733;
  border-radius: 5px;
}

.input-field:focus {
  border: 2px solid #f7941d;
}

.btn-form {
  /* width: 100% !important; */
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-weight: 800;
  border: none;
  text-decoration: none;
  color: #fff;
  border-radius: 3px;
  background: linear-gradient(90deg, #f7941d 0%, #f04e37 100%);
  transition: padding 0.3s ease-in-out;
}

@media screen and (max-width: 576px) {
  .contact-us {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
  }
  .information-section {
    padding: 40px;
  }

  .contact-us .information-section .almasons-white-logo {
    margin-bottom: 30px;
  }

  .information-section .highlight-points {
    margin-bottom: 5px;
  }
  .contact-us .information-section .highlight-points:nth-child(3) {
    margin-bottom: 20px;
  }

  .information-section .content {
    margin-bottom: 20px;
  }

  .information-section .social-media {
    justify-content: flex-start;
    gap: 30px;
  }

  .form-area {
    padding: 40px;
    background: none;
  }

  .form-area .sub-heading {
    font-size: 15px;
    text-transform: uppercase;
  }

  .form-grid {
    grid-template-columns: 1fr !important;
    gap: 20px;
  }

  .input-container {
    display: flex;
    width: 100% !important;
  }

  .input-container:nth-child(n) {
    grid-column: 1/3;
  }

  .btn-form {
    width: 100% !important;
  }
}
</style>

<!-- FAQs -->
<style scoped>
.faq {
  padding: 60px 120px;
}

.question-block {
  margin-top: 30px;
}

.faq hr {
  opacity: 0.4;
  margin: 10px 0;
}

.accordion {
  background-color: transparent;
  color: #272827;
  font-weight: 600;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 50px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.accordion:after {
  content: "\02795"; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #bbb !important;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2796"; /* Unicode character for "minus" sign (-) */
  color: #f7941d;
}

.panel .content {
  margin: 10px 0;
}

.panel li .content {
  margin: 0;
}

@media screen and (max-width: 576px) {
  .faq {
    padding: 40px;
  }
}
</style>

<!-- captcha style -->
<style scoped>
canvas {
  border: 2px solid #333;
  margin: 20px 0;
  background-color: #fff;
}

.captcha-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 1 / 3;
  justify-content: center;
}
</style>
