<template>
  <!-- HERO SECTION -->
  <section class="hero-section">
    <h1 class="title">Get to know us better</h1>
    <p class="content">
Welcome to our Supply Chain Solutions hub. We blend expertise and innovation to streamline operations, ensuring efficient, responsive, and client-centric solutions that drive success in a dynamic business landscape.
    </p>
  </section>

  <!-- HISTORY -->
  <section class="history">
    <div class="grid">
      <h1 class="heading">Our <span class="highlight-gr">History</span></h1>
      <p class="content">
        Established in the year 2013, Almasons set out with the objective of delivering simplified warehouse management solutions to supply industries such as power tools, electronic appliances, healthcare, power distribution, food processing, and dairy. In our pursuit of offering streamlined solutions for warehouse operators, we've created the Almasons GiNi Solutions. These innovations not only enable voice functionality for any WMS but have also seen successful implementation across numerous customer sites.
      </p>
      <img :src="history" alt="" class="history-img" />
    </div>
  </section>

  <!-- MISSION STATEMENT -->
  <section class="mission">
    <div class="grid">
      <div class="information-col">
        <h1 class="heading">
          Mission <span class="highlight-gr">statement</span>
        </h1>
        <p class="content">
          At Almasons, our goal is to improve the logistics experience for our clients. We are dedicated to delivering dependable and innovative warehousing solutions that go beyond storage to optimize supply chains and drive business success. Through close collaboration with our clients, we aim to understand and address their unique needs, fostering long-term partnerships built on trust and mutual growth. Our constant commitment to excellence sets the standard for the future of warehousing, where efficiency, innovation, and productivity come together.
        </p>
      </div>
      <img :src="missionImg" alt="" class="mission-img" />
    </div>
  </section>

  <!-- MEET OUR TEAM -->
  <section class="meet-our-team">
    <h1 class="heading">meet <span class="highlight-gr">our team</span></h1>
    <p class="content">
      Almasons takes great pride in both its corporate culture and its level of expertise. Meet the team that makes IT easy.
    </p>
    <div class="img-grid">
      <div class="profile-card">
        <div class="social-icon-box">
          <!-- <i class="fa-brands fa-linkedin"></i> -->
          <a href="https://www.linkedin.com/in/janet-mascarenhas-1bb98a1a0/"> <i class="fa-brands fa-linkedin" style="color: #f7941d;"></i> </a>
          <!-- <i class="fa-solid fa-paper-plane"></i> -->
        </div>
        <img
          src="https://almasons.com/wp-content/uploads/2021/09/JANET-MASCARENHAS.jpg"
          alt=""
          class="profile-img"
        />
        <h5 class="profile-name">Janet MASCARENHAS</h5>
        <p class="profile-designation">Chief executive officer</p>
      </div>
      <div class="profile-card">
        <div class="social-icon-box">
          <!-- <i class="fa-brands fa-linkedin"></i> -->
          <a href="https://www.linkedin.com/in/rajesh-mendonca-1071937/"> <i class="fa-brands fa-linkedin" style="color: #f7941d;"></i> </a>
          <!-- <i class="fa-solid fa-paper-plane"></i> -->
        </div>
        <img
          src="https://almasons.com/wp-content/uploads/2021/09/RAJESH-MENDONCA.jpg"
          alt=""
          class="profile-img"
        />
        <h5 class="profile-name">Rajesh MENDONCA</h5>
        <p class="profile-designation">Chief Architect</p>
      </div>
      <div class="profile-card">
        <div class="social-icon-box">
          <!-- <i class="fa-brands fa-linkedin"></i> -->
          <!-- <i class="fa-solid fa-paper-plane"></i> -->
          <a href="https://www.linkedin.com/in/jeromemascarenhas/"> <i class="fa-brands fa-linkedin" style="color: #f7941d;"></i> </a>
        </div>
        <img
          src="https://almasons.com/wp-content/uploads/2022/05/jerome_picture_new-1.jpg"
          alt=""
          class="profile-img"
        />
        <h5 class="profile-name">Jerome MASCARENHAS</h5>
        <p class="profile-designation">Chief Technology Officer</p>
      </div>
      <div class="profile-card">
        <div class="social-icon-box">
          <!-- <i class="fa-brands fa-linkedin"></i> -->
          <!-- <i class="fa-solid fa-paper-plane"></i> -->
          <a href="https://www.linkedin.com/in/joe-vinod-mascarenhas-26a3243/"> <i class="fa-brands fa-linkedin" style="color: #f7941d;"></i> </a>
        </div>
        <img
          src="https://almasons.com/wp-content/uploads/2022/05/joe-mascarenhas.jpg"
          alt=""
          class="profile-img"
        />
        <h5 class="profile-name">Joe MASCARENHAS</h5>
        <p class="profile-designation">CHIEF INFORMATION OFFICER</p>
      </div>
      <div class="profile-card">
        <div class="social-icon-box">
          <!-- <i class="fa-brands fa-linkedin"></i> -->
          <!-- <i class="fa-solid fa-paper-plane"></i> -->
          <a href="https://www.linkedin.com/in/issberner/"> <i class="fa-brands fa-linkedin" style="color: #f7941d;"></i> </a>
        </div>
        <img
          src="https://almasons.com/wp-content/uploads/2022/07/barry.jpg"
          alt=""
          class="profile-img"
        />
        <h5 class="profile-name">BARRY ISSBERNER</h5>
        <p class="profile-designation">CHIEF MARKETING OFFICER</p>
      </div>
      <div class="profile-card">
        <div class="social-icon-box">
          <!-- <i class="fa-brands fa-linkedin"></i> -->
          <!-- <i class="fa-solid fa-paper-plane"></i> -->
          <a href="https://www.linkedin.com/in/frank-borghese-955196b/"> <i class="fa-brands fa-linkedin" style="color: #f7941d;"></i> </a>
        </div>
        <img
          src="https://almasons.com/wp-content/uploads/2021/09/FRANK-BORGHESE.jpg"
          alt=""
          class="profile-img"
        />
        <h5 class="profile-name">FRANK BORGHESE</h5>
        <p class="profile-designation">CHIEF REVENUE OFFICER</p>
      </div>
      <div class="profile-card">
        <div class="social-icon-box">
          <!-- <i class="fa-brands fa-linkedin"></i> -->
          <!-- <i class="fa-solid fa-paper-plane"></i> -->
          <a href="https://www.linkedin.com/in/roopesh020/"> <i class="fa-brands fa-linkedin" style="color: #f7941d;"></i> </a>
        </div>
        <img
          src="https://almasons.com/wp-content/uploads/2023/06/roopesh.webp"
          alt=""
          class="profile-img"
        />
        <h5 class="profile-name">ROOPESH RAMAKRISHNAN</h5>
        <p class="profile-designation">VICE PRESIDENT SALES-INDIA</p>
      </div>
    </div>
  </section>

  <!-- BANNER -->
  <section class="banner">
    <p class="content">
      By working with Almasons, you can help your business grow faster while also saving time and money. Our warehouse automation solutions make use of the most effective SAP infrastructure available in the USA and beyond, all while providing quick turnaround times. Our role as a Total Solution Provider (TSP) is to assist you in achieving operational excellence. Make an appointment for a live remote demo or more information by getting in touch with us right now. Let Almasons be your reliable partner in working towards success.
    </p>
    <a href="/contact-us" class="btn-cta-1">Contact us</a>
  </section>

  <!-- INDUSTRIES -->
  <section class="industries">
    <div class="grid">
      <div class="card-grid">
        <div class="icon-card">
          <!-- <i class="fa-regular fa-comments icon"></i> -->
          <h5 class="card-title">Hightech production</h5>
        </div>
        <div class="icon-card">
          <!-- <i class="fa-regular fa-comments icon"></i> -->
          <h5 class="card-title">Direct store delivery</h5>
        </div>
        <div class="icon-card">
          <!-- <i class="fa-regular fa-comments icon"></i> -->
          <h5 class="card-title">Maintain & Inspect</h5>
        </div>
        <div class="icon-card">
          <!-- <i class="fa-regular fa-comments icon"></i> -->
          <h5 class="card-title">Warehousing</h5>
        </div>
        <div class="icon-card">
          <!-- <i class="fa-regular fa-comments icon"></i> -->
          <h5 class="card-title">Retail</h5>
        </div>
        <div class="icon-card">
          <!-- <i class="fa-regular fa-comments icon"></i> -->
          <h5 class="card-title">Transport & Logistics </h5>
        </div>
        <div class="icon-card">
          <!-- <i class="fa-regular fa-comments icon"></i> -->
          <h5 class="card-title">Pack and Ship</h5>
        </div>
        <div class="icon-card">
          <!-- <i class="fa-regular fa-comments icon"></i> -->
          <h5 class="card-title">Home delivery</h5>
        </div>
      </div>
      <div class="information-col">
        <h1 class="heading">
          industry and applications we <span class="highlight-gr">serve</span>
        </h1>
        <p class="content">
          We cater to a diverse range of industries and applications, offering tailored solutions to meet specific needs.  Whether you are navigating the challenges of warehousing, maintenance and inspection, or final leg of delivery, Almasons provides innovative tools and technology to meet the demands of a dynamic business environment. Our commitment to excellence ensures that our services seamlessly align with the unique requirements of each industry we serve. Because of our dedication to quality, we can guarantee that our services will perfectly fit the particular needs of every industry we work with. Discover how Almasons' solutions can improve your productivity across a variety of applications.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import history from "../assets/aboutus-history.png";
import missionImg from "../assets/mission-img.png";

export default {
  data() {
    return {
      history: history,
      missionImg: missionImg,
    };
  },
  mounted(){
    window.scrollTo({
            top: 0
          });
  }
};
</script>

<!-- GENERAL -->
<style scoped>
.content {
  font-size: 16px;
  color: #505050;
  text-align: justify;
  line-height: 28px;
}

.heading {
  font-size: 35px;
  font-weight: 500;
  text-transform: uppercase;
}

.highlight-gr {
  background: linear-gradient(90deg, #f7941d 0%, #f15b2b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-cta-1 {
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  border-radius: 3px;
  background: linear-gradient(90deg, #f7941d 0%, #f04e37 100%);
  transition: padding 0.3s ease-in-out;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .content {
    line-height: normal;
  }

  .heading {
    font-size: 24px;
  }
}
</style>

<!-- HERO SECTION -->
<style scoped>
.hero-section {
  margin-top: 65px;
  padding: 100px 120px;
  background: url("../assets/aboutus-hero.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-section .title {
  font-size: 55px;
  font-weight: 400;
  margin-bottom: 20px;
}

.hero-section .content {
  width: 60%;
  text-align: center;
  margin: 0 auto;
}

@media screen and (max-width: 576px) {
  .hero-section {
    padding: 40px;
    display: flex;
  }

  .hero-section .title {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .hero-section .content {
    width: 100%;
  }
}
</style>

<!-- HISTORY -->
<style scoped>
.history {
  padding: 50px 120px;
}

.history .grid {
  display: grid;
  grid-template-columns: 1fr 3fr;
  /* gap: 50px; */
}

.grid .history-img {
  width: 100%;
  margin: 50px auto 0;
  grid-column: 1/5;
}

@media screen and (max-width: 576px) {
  .history {
    padding: 40px;
  }

  .history .grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .grid .history-img {
    width: 100%;
    margin: 20px auto 0;
    grid-column: 1/2;
  }
}
</style>

<!-- MISSION -->
<style scoped>
.mission {
  background: url("../assets/aboutus-mission.png");
  background-size: cover;
  padding: 50px 120px;
}

.mission .grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  gap: 50px;
}

.mission .heading {
  margin-bottom: 30px;
}

.mission .mission-img {
  width: 100%;
  text-align: right;
}

@media screen and (max-width: 576px) {
  .mission {
    padding: 40px;
  }

  .mission .grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
</style>

<!-- TEAM -->
<style scoped>
.meet-our-team {
  padding: 50px 120px;
}

.meet-our-team .heading {
  margin-bottom: 30px;
}

.meet-our-team .img-grid {
  display: grid;
  gap: 40px;
  grid-template-columns: repeat(8, 1fr);
  margin: 30px 0 0;
}

.meet-our-team .profile-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.profile-card .social-icon-box {
  width: 100%;
  background: #00000055;
  padding: 10px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  color: #f7941d;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.profile-card:hover .social-icon-box {
  opacity: 1;
}

.meet-our-team .profile-img {
  width: 100%;
}

.meet-our-team .profile-name {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 20px;
}

.meet-our-team .profile-designation {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: #f7941d;
}

.meet-our-team .profile-card:nth-child(1) {
  grid-column: 1/3;
}
.meet-our-team .profile-card:nth-child(2) {
  grid-column: 3/5;
}
.meet-our-team .profile-card:nth-child(3) {
  grid-column: 5/7;
}
.meet-our-team .profile-card:nth-child(4) {
  grid-column: 7/9;
}
.meet-our-team .profile-card:nth-child(5) {
  grid-column: 2/4;
}
.meet-our-team .profile-card:nth-child(6) {
  grid-column: 4/6;
}
.meet-our-team .profile-card:nth-child(7) {
  grid-column: 6/8;
}

@media screen and (max-width: 576px) {
  .meet-our-team {
    padding: 40px;
  }

  .meet-our-team .heading {
    margin-bottom: 10px;
  }

  .meet-our-team .img-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    margin: 30px 0 0;
  }

  .meet-our-team .profile-name {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 20px;
  }

  .meet-our-team .profile-designation {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    color: #f7941d;
  }

  .meet-our-team .profile-card:nth-child(1),
  .meet-our-team .profile-card:nth-child(2),
  .meet-our-team .profile-card:nth-child(3),
  .meet-our-team .profile-card:nth-child(4),
  .meet-our-team .profile-card:nth-child(5),
  .meet-our-team .profile-card:nth-child(6),
  .meet-our-team .profile-card:nth-child(7) {
    grid-column: 1/2;
  }
}
</style>

<!-- BANNER -->
<style scoped>
.banner {
  padding: 50px 120px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;
  background: url("../assets/aboutus-banner.png");
  background-size: cover;
}

.banner .content {
  text-align: center;
  font-size: 17px;
  color: #272827;
}

@media screen and (max-width: 576px) {
  .banner {
    padding: 40px;
    gap: 20px;
  }

  .banner .content {
    font-size: 16px;
  }
}
</style>

<!-- INDUSTRY -->
<style scoped>
.industries {
  padding: 50px 120px;
}

.industries .grid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 80px;
  align-items: center;
}

.industries .card-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
}

.icon-card {
  background: #fff;
  /* border-radius: 3px; */
  box-shadow: -5px -5px 0 #f7941d33, 5px 5px 0 #27282733;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;
}

.icon-card .icon {
  font-size: 60px;
  color: #272827dd;
}

.icon-card .card-title {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  text-align: center;
}

.industries .heading {
  margin-bottom: 30px;
}

@media screen and (max-width: 576px) {
  .industries {
    padding: 40px;
  }

  .industries .grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .industries .card-grid {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .icon-card {
    text-align: center;
    gap: 20px;
  }

  .icon-card .icon {
    font-size: 40px;
  }

  .industries .heading {
    margin-bottom: 10px;
  }
}
</style>