import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "../views/LandingPage.vue";
import ContactUs from "../views/ContactUs.vue";
import AboutUs from "../views/AboutUs.vue";
import Voice from "../views/Voice.vue";
import Workflows from "../views/Workflows.vue";
import LMAndDashboards from "../views/LMAndDashboards.vue";
import Esign from "../views/Esign.vue";
import SmartConnect from "../views/SmartConnect.vue";
import MaintainAndInspect from "../views/MaintainAndInspect.vue";
import VoiceDevelopmentKit from "../views/VoiceDevelopmentKit.vue";
import Resources from "../views/Resources.vue";
import PostPage from "../views/PostPage.vue";
import MockLandingpage from "../views/MockLandingpage.vue";
import WordMocklanding from "../views/WordMocklanding.vue"

const routes = [
  // {
  //   path: "/",
  //   name: "Landing",
  //   component: LandingPage,
  // },
  {
    path: "/real",
    name: "MockLandingpage",
    component: MockLandingpage,
  },
  {
    path: "/",
    name: "WordMocklanding",
    component: WordMocklanding,
  },
  {
    path: "/about-us",
    name: "About Us",
    component: AboutUs,
    meta: { title: "About Us" },
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: ContactUs,
    meta: { title: "Contact Us" },
  },
  {
    path: "/gini-voice",
    name: "Almasons Gini Voice",
    component: Voice,
    meta: { title: "GiNi Voice" },
  },
  {
    path: "/gini-workflows",
    name: "Almasons Gini Workflows",
    component: Workflows,
    meta: { title: "GiNi Workflows" },
  },
  {
    path: "/gini-lm-and-dashboards",
    name: "Almasons Gini Labor Management and Dashboards",
    component: LMAndDashboards,
    meta: { title: "Almasons Gini Labor Management and Dashboards" },
  },
  {
    path: "/gini-m&i",
    name: "Maintain And Inspect",
    component: MaintainAndInspect,
  },
  {
    path: "/gini-esign",
    name: "Gini eSign",
    component: Esign,
  },
  {
    path: "/gini-smart-connect",
    name: "Gini Smart Connect",
    component: SmartConnect,
  },
  {
    path: "/voice-development-kit",
    name: "VoiceDevelopmentKit",
    component: VoiceDevelopmentKit,
    meta: { title: "Voice Dev Kit" },
  },
  {
    path: "/resources/:filterCategory",
    name: "AlmasonsResources",
    component: Resources,
    props: true,
    meta: { title: "Almasons Resources" },
  },
  {
    path: "/resources/post/:id",
    name: "AlmasonsResourcesPost",
    component: PostPage,
    props: true,
    meta: { title: "Almasons Post" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//This is called navigatioin gaurd:
//  While going from one view to other view it changes the document title as set in the above routes.
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Almasons Technologies";
  next(); // This is important to indicate that the navigation should proceed
});

export default router;
