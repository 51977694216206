<template>
  <!-- CONTACT US FORM -->
  <section class="contact-us" id="contact">
    <div class="information-section">
      <img :src="almasonswhite" alt="" class="almasons-white-logo" />
      <!-- <div class="information"> -->
      <p class="highlight-points">
        <span class="highlight-text">Supply Chain Solutions Reimagined  </span>
      </p>

      <p class="content" style="text-align: left">
        Drive higher throughput, accuracy, and safety with Almasons.
      </p>
      <p class="content" style="text-align: left">
        Give us 30 minutes and we will explain how to achieve up to 30%
        improvements in as little as 30 days.
      </p>
      <div class="social-media">
        <div class="icon-box">
          <a href="https://www.youtube.com/@almasons"> <i class="fa-brands fa-youtube" style="color: white;"></i> </a>
        </div>
        <div class="icon-box">
          <a href="https://www.linkedin.com/company/almasons/mycompany/"> <i class="fa-brands fa-linkedin" style="color: white;"></i> </a>
        </div>
        <!-- <div class="icon-box">
              <i class="fa-brands fa-square-twitter"></i>
            </div> -->
        <!-- <div class="icon-box">
              <i class="fa-brands fa-blogger"></i>
            </div> -->
      </div>
      <!-- </div> -->
    </div>
    <div class="form-area">
      <h2 class="heading">Get in <span class="highlight-gr">Touch</span></h2>
      <h4 class="sub-heading">
        24/7 We will answer your questions and problems
      </h4>
      <form id="contactForm" @submit.prevent="getFormData()">
        <div class="form-grid">
          <div class="input-container">
            <i class="fa-solid fa-user icon"></i>
            <input
              class="input-field"
              type="text"
              placeholder="Name"
              name="name"
              pattern="[A-Za-z\s]+"
              required
              title="The name should contain only the alphabets.."
              autocomplete="off"
              v-model="userName"
            />
          </div>
          <div class="input-container">
            <i class="fa-solid fa-briefcase icon"></i>
            <input
              class="input-field"
              type="text"
              placeholder="Company Name"
              name="c-name"
              pattern="[A-Za-z\s]+"
              title="The Company name should contain only the alphabets.."
              autocomplete="off"
              v-model="companyName"
            />
          </div>
          <div class="input-container">
            <i class="fa-solid fa-at icon"></i>
            <input
              class="input-field"
              type="email"
              placeholder="Email"
              v-model="email"
              ref="emailfield"
              @input="handleInput"
              name="email"
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
              required
              title="The Email ID must match the standard format."
              autocomplete="off"
            />
          </div>
          <div class="input-container">
            <i class="fa-solid fa-phone icon"></i>
            <select
              class="input-field-ph"
              name="countryCode"
              required
              ref="countrycode"
              v-model="userCountryCode"
            >
              <option value="" disabled selected>--</option>
              <template v-for="code in countryCodes" :key="code">
                <option :data-countryCode="code" :value="code">
                  {{ code }}
                </option>
              </template>
            </select>
            <input
              class="input-field"
              type="number"
              placeholder="Phone"
              name="phone"
              v-model="phone"
              required
              @input="handleInput"
              ref="phonefield"
              autocomplete="off"
            />
          </div>
          <div class="input-container">
            <i class="fa-solid fa-desktop icon"></i>
            <select
              id="options"
              name="options[]"
              class="input-field"
              v-model="selectedSolution"
              required
            >
              <option value="" disabled selected>Select a Solution</option>
              <option>Almasons GiNi&#x2122; Workflows</option>
              <option>Almasons GiNi&#x2122; Voice</option>
              <option>Almasons GiNi&#x2122; LM & Dashboards</option>
              <option>Almasons GiNi&#x2122; Maintain and Inspect</option>
              <option>Almasons GiNi&#x2122; eSign</option>
              <option>Almasons GiNi&#x2122; Smart Connect</option>
              <option>Almasons GiNi&#x2122; Voice Dev Kit</option>
              <option>Other Topics</option>
            </select>
          </div>
          <div class="input-container">
            <i class="fas fa-address-card icon"></i>
            <input
              class="input-field"
              type="text"
              placeholder="Job Title"
              name="jobtitle"
              pattern="[A-Za-z\s]+"
              title="The Job title should contain only the alphabets.."
              autocomplete="off"
              v-model="jobTitle"
            />
          </div>
          <div class="input-container">
            <textarea
              name=""
              class="form-textarea"
              rows="4"
              placeholder="Message..."
              v-model="userMessage"
            ></textarea>
          </div>
          <div class="captcha-container">
                    <h2>Verify that you are human</h2>
                    <p>Type the following CAPTCHA:</p>
                    <canvas id="captchaCanvas" width="200" height="50"></canvas>
                    <input
                      type="text"
                      id="userAnswer"
                      placeholder="Your answer"
                      required
                     
                    />
                    <!-- <button onclick="checkCaptcha()">Submit</button> -->
                  </div>
          <div class="submit-button">
            <button class="btn-form">SUBMIT</button>
          </div>
        </div>
      </form>
    </div>
  </section>

</template>

<script>
export default{
    name:"ContactForm",
    data(){
        return{
            countryCodes: [
        // "--code--",
        "USA (+1)",
        "UK (+44)",
        "Algeria (+213)",
        "Andorra (+376)",
        "Angola (+244)",
        "Anguilla (+1264)",
        "Antigua & Barbuda (+1268)",
        "Argentina (+54)",
        "Armenia (+374)",
        "Aruba (+297)",
        "Australia (+61)",
        "Austria (+43)",
        "Azerbaijan (+994)",
        "Bahamas (+1242)",
        "Bahrain (+973)",
        "Bangladesh (+880)",
        "Barbados (+1246)",
        "Belarus (+375)",
        "Belgium (+32)",
        "Belize (+501)",
        "Benin (+229)",
        "Bermuda (+1441)",
        "Bhutan (+975)",
        "Bolivia (+591)",
        "Bosnia Herzegovina (+387)",
        "Botswana (+267)",
        "Brazil (+55)",
        "Brunei (+673)",
        "Bulgaria (+359)",
        "Burkina Faso (+226)",
        "Burundi (+257)",
        "Cambodia (+855)",
        "Cameroon (+237)",
        "Canada (+1)",
        "Cape Verde Islands (+238)",
        "Cayman Islands (+1345)",
        "Central African Republic (+236)",
        "Chile (+56)",
        "China (+86)",
        "Colombia (+57)",
        "Comoros (+269)",
        "Congo (+242)",
        "Cook Islands (+682)",
        "Costa Rica (+506)",
        "Croatia (+385)",
        "Cuba (+53)",
        "Cyprus North (+90392)",
        "Cyprus South (+357)",
        "Czech Republic (+42)",
        "Denmark (+45)",
        "Djibouti (+253)",
        "Dominica (+1809)",
        "Dominican Republic (+1809)",
        "Ecuador (+593)",
        "Egypt (+20)",
        "El Salvador (+503)",
        "Equatorial Guinea (+240)",
        "Eritrea (+291)",
        "Estonia (+372)",
        "Ethiopia (+251)",
        "Falkland Islands (+500)",
        "Faroe Islands (+298)",
        "Fiji (+679)",
        "Finland (+358)",
        "France (+33)",
        "French Guiana (+594)",
        "French Polynesia (+689)",
        "Gabon (+241)",
        "Gambia (+220)",
        "Georgia (+7880)",
        "Germany (+49)",
        "Ghana (+233)",
        "Gibraltar (+350)",
        "Greece (+30)",
        "Greenland (+299)",
        "Grenada (+1473)",
        "Guadeloupe (+590)",
        "Guam (+671)",
        "Guatemala (+502)",
        "Guinea (+224)",
        "Guinea - Bissau (+245)",
        "Guyana (+592)",
        "Haiti (+509)",
        "Honduras (+504)",
        "Hong Kong (+852)",
        "Hungary (+36)",
        "Iceland (+354)",
        "India (+91)",
        "Indonesia (+62)",
        "Iran (+98)",
        "Iraq (+964)",
        "Ireland (+353)",
        "Israel (+972)",
        "Italy (+39)",
        "Jamaica (+1876)",
        "Japan (+81)",
        "Jordan (+962)",
        "Kazakhstan (+7)",
        "Kenya (+254)",
        "Kiribati (+686)",
        "Korea North (+850)",
        "Korea South (+82)",
        "Kuwait (+965)",
        "Kyrgyzstan (+996)",
        "Laos (+856)",
        "Latvia (+371)",
        "Lebanon (+961)",
        "Lesotho (+266)",
        "Liberia (+231)",
        "Libya (+218)",
        "Liechtenstein (+417)",
        "Lithuania (+370)",
        "Luxembourg (+352)",
        "Macao (+853)",
        "Macedonia (+389)",
        "Madagascar (+261)",
        "Malawi (+265)",
        "Malaysia (+60)",
        "Maldives (+960)",
        "Mali (+223)",
        "Malta (+356)",
        "Marshall Islands (+692)",
        "Martinique (+596)",
        "Mauritania (+222)",
        "Mayotte (+269)",
        "Mexico (+52)",
        "Micronesia (+691)",
        "Moldova (+373)",
        "Monaco (+377)",
        "Mongolia (+976)",
        "Montserrat (+1664)",
        "Morocco (+212)",
        "Mozambique (+258)",
        "Myanmar (+95)",
        "Namibia (+264)",
        "Nauru (+674)",
        "Nepal (+977)",
        "Netherlands (+31)",
        "New Caledonia (+687)",
        "New Zealand (+64)",
        "Nicaragua (+505)",
        "Niger (+227)",
        "Nigeria (+234)",
        "Niue (+683)",
        "Norfolk Islands (+672)",
        "Northern Marianas (+670)",
        "Norway (+47)",
        "Oman (+968)",
        "Palau (+680)",
        "Panama (+507)",
        "Papua New Guinea (+675)",
        "Paraguay (+595)",
        "Peru (+51)",
        "Philippines (+63)",
        "Poland (+48)",
        "Portugal (+351)",
        "Puerto Rico (+1787)",
        "Qatar (+974)",
        "Reunion (+262)",
        "Romania (+40)",
        "Russia (+7)",
        "Rwanda (+250)",
        "San Marino (+378)",
        "Sao Tome & Principe (+239)",
        "Saudi Arabia (+966)",
        "Senegal (+221)",
        "Serbia (+381)",
        "Seychelles (+248)",
        "Sierra Leone (+232)",
        "Singapore (+65)",
        "Slovak Republic (+421)",
        "Slovenia (+386)",
        "Solomon Islands (+677)",
        "Somalia (+252)",
        "South Africa (+27)",
        "Spain (+34)",
        "Sri Lanka (+94)",
        "St. Helena (+290)",
        "St. Kitts (+1869)",
        "St. Lucia (+1758)",
        "Sudan (+249)",
        "Suriname (+597)",
        "Swaziland (+268)",
        "Sweden (+46)",
        "Switzerland (+41)",
        "Syria (+963)",
        "Taiwan (+886)",
        "Tajikstan (+7)",
        "Thailand (+66)",
        "Togo (+228)",
        "Tonga (+676)",
        "Trinidad & Tobago (+1868)",
        "Tunisia (+216)",
        "Turkey (+90)",
        "Turkmenistan (+7)",
        "Turkmenistan (+993)",
        "Turks & Caicos Islands (+1649)",
        "Tuvalu (+688)",
        "Uganda (+256)",
        "Ukraine (+380)",
        "United Arab Emirates (+971)",
        "Uruguay (+598)",
        "Uzbekistan (+7)",
        "Vanuatu (+678)",
        "Vatican City (+379)",
        "Venezuela (+58)",
        "Vietnam (+84)",
        "Virgin Islands - British (+1284)",
        "Virgin Islands - US (+1340)",
        "Wallis & Futuna (+681)",
        "Yemen (North)(+969)",
        "Yemen (South)(+967)",
        "Zambia (+260)",
        "Zimbabwe (+263)",
      ],

      email: "",
      phone: "",
      userName: "",
      companyName: "",
      userCountryCode: "",
      selectedSolution: "",
      userMessage: "",
      jobTitle:"",
      captchastatus:false,
        }
    },
    mounted(){
    //generate captcha on page load
    this.generateCaptcha();
    },
    methods:{
    getFormData() {
      this.checkCaptcha();
      if(this.captchastatus){
      console.log("email", this.email);
      console.log("phone", this.phone);
      console.log("userName", this.userName);
      console.log("companyName", this.companyName);
      console.log("userCountryCode", this.userCountryCode);
      console.log("selectedSolution", this.selectedSolution);
      console.log("JobTitle", this.jobTitle);
      console.log("userMessage", this.userMessage);

      const formdata = {
        from_name: this.userName,
        email: this.email,
        countrycode: this.userCountryCode,
        phone: this.phone,
        companyName: this.companyName,
        selectedSolution: this.selectedSolution,
        userMessage: this.userMessage,
        previos_url: window.location.pathname,
      };
      emailjs
        .send("service_5yf3dqc", "template_pkat10n", formdata)
        .then(
          window.alert(
            "Thank you for the mail, will reach out to you as soon as possible..!"
          )
        );
      this.$router.push("/");
    } else{
      window.alert('You have entered a wrong captcha.. Please try Again')
      this.generateCaptcha();
    }
    },
    handleInput() {
      //making email or phone as required
      if (this.email != "") {
        this.$refs.phonefield.removeAttribute("required");
        this.$refs.countrycode.removeAttribute("required");
      }
      if (this.phone != "") {
        this.$refs.emailfield.removeAttribute("required");
      }
    },
    generateCaptcha() {
      var captchaText = this.generateRandomLetters();
      var canvas = document.getElementById("captchaCanvas");
      var ctx = canvas.getContext("2d");
      // Clear previous content
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // Draw complex background
      this.drawComplexBackground(ctx, canvas.width, canvas.height);
      // Draw distorted text on the canvas with increased distortion
      ctx.font = "bold 30px Arial";
      ctx.fillStyle = "#333";
      this.drawDistortedText(ctx, captchaText, 20, canvas.height / 2, 15);
      // Set the captcha result as a data attribute for later verification
      canvas.dataset.captchaResult = captchaText;
    },
    generateRandomLetters() {
      var length = Math.random() * (6 - 3) + 3;
      var result = "";
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    },
    drawComplexBackground(ctx, width, height) {
      // Draw more random lines for a complex background
      ctx.strokeStyle = "#ddd";
      for (var i = 0; i < 30; i++) {
        ctx.beginPath();
        ctx.moveTo(Math.random() * width, Math.random() * height);
        ctx.lineTo(Math.random() * width, Math.random() * height);
        ctx.stroke();
      }
    },
    drawDistortedText(ctx, text, x, y, distortion) {
      for (var i = 0; i < text.length; i++) {
        var char = text.charAt(i);
        ctx.save();
        ctx.translate(x + i * 30, y);
        ctx.rotate(Math.random() * 0.4 - 0.2); // Increased rotation
        ctx.translate(-x - i * 30, -y);
        ctx.fillText(
          char,
          x + i * 30 + Math.random() * distortion,
          y + Math.random() * distortion
        ); // Increased distortion
        ctx.restore();
      }
    },
    checkCaptcha() {
      var userAnswer = document.getElementById("userAnswer").value;
      var captchaResult =
        document.getElementById("captchaCanvas").dataset.captchaResult;
      if (userAnswer == captchaResult) {
        // alert("Captcha is correct. You are human!");
        this.captchastatus = true;
        userAnswer = "";
      } else {
        // alert("Captcha is incorrect. Please try again.");
        this.captchastatus=false;
        userAnswer = "";
       
      }
    },
    }
}
</script>

<!-- CONTACT US FORM  -->
<style scoped>
.contact-us {
  display: grid;
  grid-template-columns: 2fr 5fr;
  /* height: calc(100vh - 120px); */
}
.information-section {
  background: url("../assets/contactus-bkg.png");
  background-position: right center;
  background-size: cover;
  padding: 40px 40px 40px 120px;
  color: #fff;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 20px;
  align-items: start;
}

.information-section .highlight-points {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 300;
}

.information-section .highlight-text {
  font-weight: 600;
  text-transform: uppercase;
}

.information-section .content {
  color: #fff;
  opacity: 0.9;
}

.information-section .social-media {
  display: flex;
  justify-content: left;
  gap: 50px;
  width: 100%;
}

.information-section .icon-box {
  font-size: 30px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.information-section .icon-box:hover {
  transform: scale(1.1);
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.3));
}

.form-area {
  padding: 40px 120px 40px 40px;
  background: url("../assets/contact-form.png");
  background-size: cover;
  background-position: right center;
}

/* .form-area .heading {
    color: #272827;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  } */

.form-area .sub-heading {
  color: #505050;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
  margin-bottom: 20px;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

.sup {
  vertical-align: super;
  font-size: smaller;
}
.input-container {
  display: flex;
  width: 100%;
}

.input-container:nth-child(7),
.submit-button {
  grid-column-start: 1;
  grid-column-end: 3;
}

.icon {
  padding: 10px;
  background: white;
  color: #27282733;
  min-width: 50px;
  text-align: center;
  border-width: 2px 0px 2px 2px;
  border-style: solid;
  border-color: #27282733;
  border-radius: 5px 0 0 5px;
}

.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
  border-width: 2px 2px 2px 0px;
  border-style: solid;
  border-color: #27282733;
  border-radius: 0 5px 5px 0;
}

.input-field-ph {
  width: 70px;
  padding: 10px;
  outline: none;
  border-width: 2px 0px 2px 0px;
  border-style: solid;
  border-color: #27282733;
  border-radius: 0;
}

.form-textarea {
  width: 100%;
  padding: 10px;
  outline: none;
  border-width: 2px;
  border-style: solid;
  border-color: #27282733;
  border-radius: 5px;
}

.input-field:focus {
  border: 2px solid #f7941d;
}

.btn-form {
  /* width: 100% !important; */
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-weight: 800;
  border: none;
  text-decoration: none;
  color: #fff;
  border-radius: 3px;
  background: linear-gradient(90deg, #f7941d 0%, #f04e37 100%);
  transition: padding 0.3s ease-in-out;
}

@media screen and (max-width: 576px) {
  .contact-us {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
  }
  .information-section {
    padding: 40px;
  }

  .contact-us .information-section .almasons-white-logo {
    margin-bottom: 30px;
  }

  .information-section .highlight-points {
    margin-bottom: 5px;
  }
  .contact-us .information-section .highlight-points:nth-child(3) {
    margin-bottom: 20px;
  }

  .information-section .content {
    margin-bottom: 20px;
  }

  .information-section .social-media {
    justify-content: flex-start;
    gap: 30px;
  }

  .form-area {
    padding: 40px;
    background: none;
  }

  .form-area .sub-heading {
    font-size: 15px;
    text-transform: uppercase;
  }

  .form-grid {
    grid-template-columns: 1fr !important;
    gap: 20px;
  }

  .input-container {
    display: flex;
    width: 100% !important;
  }

  .input-container:nth-child(n) {
    grid-column: 1/3;
  }

  .btn-form {
    width: 100% !important;
  }
}
</style>

<!-- captcha style -->
<style scoped>
canvas {
  border: 2px solid #333;
  margin: 20px 0;
  background-color: #fff;
}

.captcha-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 1 / 3;
  justify-content: center;
}
</style>