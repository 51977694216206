<template>
  <section :class="[background ? 'background' : 'dark', 'two-grid']">
    <div class="information-section">
      <h1 class="section-heading">
        {{ heading1 }} <span class="highlight-gr">{{ heading2 }}</span>
      </h1>
    </div>
    <div class="card-section">
      <div class="card" v-for="stat in stats" :key="stat.marking">
        <h4 class="stat-head">{{ stat.marking }}</h4>
        <p class="stat-name">{{ stat.markSub }}</p>
      </div>
    </div>
  </section>
</template>
      
      <script>
import placeholder from "../assets/vdk.png";

export default {
  data() {
    return {
      placeholder: placeholder,
    };
  },
  props: {
    background: Boolean,
    heading1: String,
    heading2: String,
    stats: Object,
  },
};
</script>
      
      <style scoped>
.two-grid {
  padding: 40px 120px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0px;
  align-items: center;
}

.two-grid.dark {
  background: #272827 !important;
  color: #f0f0f0 !important;
}

.stat-head {
  font-size: 30px;
  font-weight: 600;
  background: linear-gradient(90deg, #f7941d 0%, #f15b2b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
}

.stat-name {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
}

.information-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-heading {
  text-align: justify;
  font-family: Poppins;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.section-content {
  color: rgba(39, 40, 39, 0.7);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
}

.card-section {
  /* display: grid;
  grid-template-columns: repeat(5, 1fr); */
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.card {
  width: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.section-img {
  width: 400px;
}

.highlight-gr {
  background: linear-gradient(90deg, #f7941d 0%, #f15b2b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background {
  background: url(../assets/Voice/stats-bkg.png);
}

@media screen and (max-width: 576px) {
  .two-grid {
    padding: 40px;
    display: grid;
  }

  .stat-head {
    font-size: 30px;
  }

  .stat-name {
    font-size: 18px;
  }

  .section-heading {
    text-align: center;
    font-size: 24px;
  }

  .card-section {
    flex-direction: column;
  }

}
</style>