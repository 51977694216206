<template>
  <div class="navbar">
    <div class="navbar-brand">
      <router-link to="/"
        ><img :src="almasonsLogo" alt="Almasons Technology" class="logo"
      /></router-link>
      <ul class="nav-links"></ul>
    </div>

    <div class="fas fa-bars hamburger" @click="showMenu()"></div>
    <div class="menu" ref="menu">
      <ul class="nav-links">
        <li class="nav-link">
          <router-link to="/" class="no-underline"
            ><a class="nav-item" @click="showMenu()">Home</a></router-link
          >
        </li>
        <!-- @mouseover="showsub()" @mouseleave="showsub()" -->
        <li class="nav-link">
          <a class="nav-item" @click="showsub()">Solutions</a>
          <div class="dropdown" ref="submenu">
            <router-link to="/gini-voice" class="no-underline"
              ><a class="dropdown-item" @click="showMenu()"
                >Voice</a
              ></router-link
            >
            <router-link to="/gini-lm-and-dashboards" class="no-underline"
              ><a class="dropdown-item" @click="showMenu()"
                >LM & Dashboards</a
              ></router-link
            >

            <router-link to="/gini-m&i" class="no-underline"
              ><a class="dropdown-item" @click="showMenu()"
                >Maintain & Inspect</a
              ></router-link
            >
            <router-link to="/gini-workflows" class="no-underline"
              ><a class="dropdown-item" @click="showMenu()"
                >Workflows</a
              ></router-link
            >




            <router-link to="/gini-esign" class="no-underline"
              ><a class="dropdown-item" @click="showMenu()"
                >eSign</a
              ></router-link
            >
            <router-link to="/gini-smart-connect" class="no-underline"
              ><a class="dropdown-item" @click="showMenu()"
                >Smart Connect</a
              ></router-link
            >
            <router-link to="/voice-development-kit" class="no-underline"
              ><a class="dropdown-item" @click="showMenu()"
                >Voice Dev Kit</a
              ></router-link
            >
            <!-- add more products link -->
          </div>
        </li>
        <li class="nav-link">
          <router-link to="/about-us" class="no-underline"
            ><a class="nav-item" @click="showMenu()">About Us</a></router-link
          >
        </li>
        <li class="nav-link">
          <router-link to="/contact-us" class="no-underline"
            ><a class="nav-item" @click="showMenu()">Contact Us</a></router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import almasonsLogo from "../assets/almasons-logo.png";

export default {
  data() {
    return {
      almasonsLogo: almasonsLogo,
      navShown: false,
    };
  },
  methods: {
    showMenu() {
      if (this.navShow) {
        this.navShow = false;
        this.$refs.menu.style.left = "-100vw";
      } else {
        this.navShow = true;
        this.$refs.menu.style.left = "0";
      }
    },
    showsub(){
      console.log('wi',window.innerWidth)
      if(window.innerWidth<=576){
      if(this.$refs.submenu.style.display === 'block'){
        this.$refs.submenu.style.display = 'none'
      }else{
        this.$refs.submenu.style.display = 'block'
      }
    }
    }
  },
};
</script>



<style scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 120px;
  background: #fff;
  z-index: 100000000;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
}

.navbar .navbar-brand {
  display: flex;
  align-items: center;
  gap: 50px;
}

.navbar .logo {
  height: 45px;
  width: auto;
}

.navbar .nav-links {
  list-style: none;
  display: flex;
  gap: 30px;
}

.navbar .nav-link {
  position: relative;
}

.navbar .nav-links .nav-link .nav-item {
  color: #272827;
  opacity: 0.5;
  letter-spacing: 0.1px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  transition: opacity 0.2s ease-in;
  display: inline-block;
  position: relative;
}

.navbar .nav-links .nav-link .nav-item::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: -10px;
  left: 0;
  border-radius: 6px;
  background: linear-gradient(90deg, #f7941d 0%, #f04e37 100%);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.navbar .nav-links .nav-link .nav-item:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.navbar .nav-links .nav-link .nav-item:hover {
  opacity: 0.9;
}

.navbar .nav-links .nav-link .btn-sp {
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px 18px;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  border-radius: 3px;
  background: linear-gradient(90deg, #f7941d 0%, #f04e37 100%);
  transition: padding 0.3s ease-in-out;
}

.navbar .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  /* margin-top: 15px; */
  left: 0;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  min-width: 200px;
  z-index: 1;
}

.navbar .nav-link:hover .dropdown {
  display: block;
}

.navbar .dropdown-item {
  padding: 12px;
  text-decoration: none;
  display: block;
  color: #333;
  font-size: 14px;
}

.navbar .dropdown-item:hover {
  background-color: #ddd;
}

.hamburger {
  display: none;
}

.no-underline {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 576px) {
  .hamburger {
    display: block;
  }
  .navbar {
    padding: 10px 40px;
    width: 100vw;
    background-color: white;
  }

  .navbar .menu {
    position: absolute;
    background-color: white;
    top: 85px;
    width: 100vw;
    left: -100vw;
    padding: 40px 20px;
    transition: left 1s ease-in-out;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
    transition: left 1s ease-in-out, height 0.5s ease-in-out;
  }

  .navbar .nav-links {
    display: flex;
    flex-direction: column;
  }

  .navbar .logo {
    height: 55px;
    width: auto;
  }

  .navbar .dropdown {
    position: relative;
    top: 10px;
    left: 0;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
}


</style>

<!-- Screens over 1500 px width -->
<style scoped>
@media screen and (min-width: 1500px) {
  .navbar {
    padding: 10px calc((100vw - 1450px)/2);
  }
}
</style>
