<template>
  <section class="hero-section">
    <div class="information-col">
      <h1 class="heading">
         <span style="color: black;"> ALMASONS GiNi™ </span><span class="highlight-gr">MAINTAIN AND INSPECT</span>
      </h1>
      <h2 class="subheading">Convert To Voice-Driven Checklists, Now Hands-Free, To Speed Up The M&I
        Process. Ensures Steps Are Completed As, And When, Directed.</h2>
     
      <p class="content">
        Transform your operations with our solution, designed to modernize and
        streamline equipment checklists. Embrace the ease of digitization and
        hands-free control, elevating efficiency and precision. Your tasks can
        be made more efficient using the convenience of voice interaction.
      </p>
      <!-- kijskjds -->
      <!-- <h1 class="heading">Almasons GiNi™ Maintain and inspect</h1>
      <h2 class="sub-heading">
        Convert to voice-driven checklists, now hands-free, to speed up the M&I
        process. Ensures steps are completed as, and when, directed.
      </h2>
      <p class="content">
        Transform your operations with our solution, designed to modernize and
        streamline equipment checklists. Embrace the ease of digitization and
        hands-free control, elevating efficiency and precision. Your tasks can
        be made more efficient using the convenience of voice interaction.
      </p> -->
    </div>

    <div class="image-col">
      <img :src="bannerImg" alt="" class="section-img" />
    </div>
  </section>

  <div class="key-features">
    <h1 class="heading">
      key <span class="highlight-gr">characteristics</span>
    </h1>
    <div class="points-grid">
      <div class="point">
        <img :src="play" alt="" class="icon" />
        <p class="content">Voice enabled in 72 languages/dialects</p>
      </div>
      <div class="point">
        <img :src="play" alt="" class="icon" />
        <p class="content">Converts paper to digital records</p>
      </div>
      <div class="point">
        <img :src="play" alt="" class="icon" />
        <p class="content">Image, Video capture and streaming</p>
      </div>
      <div class="point">
        <img :src="play" alt="" class="icon" />
        <p class="content">Ensures OSHA compliance</p>
      </div>
      <div class="point">
        <img :src="play" alt="" class="icon" />
        <p class="content">Checklists are easy to setup & modify</p>
      </div>
      <div class="point">
        <img :src="play" alt="" class="icon" />
        <p class="content">Runs on Android rugged handhelds</p>
      </div>
    </div>
  </div>
  <section class="osha">
    <h1 class="heading">osha <span class="highlight-gr">checklists</span></h1>
    <p class="content">
      Go digital with OSHA compliance checks on your supply chain equipment such
      as forklifts. Eliminate paper with digital records. Ensure inspectors
      follow the correct steps for each make/model, do the work as scheduled,
      and capture photos and videos to document work required and completions.
    </p>
  </section>
  <section class="two-grid">
    <div class="info-col">
      Ensure your M&I processes follow industry best practices and requirements.
      Store records digitally to ease access and reporting.
    </div>
    <div class="icon-col">
      <h1 class="icon-desc">Industry Compliance</h1>
      <img :src="icon1" alt="" class="icon" />
    </div>
    <div class="icon-col reverse">
      <h1 class="icon-desc">Risk-Free Trial</h1>
      <img :src="icon3" alt="" class="icon" />
    </div>
    <div class="info-col reverse">
      Our risk-free trial lets you explore our features and functionality, so
      you see firsthand how easy Almasons M&I is to setup and use.
    </div>
    <div class="info-col">
      Almasons reduces the financial burden of moving from easily-lost paper
      checklists to a well-documented digital future. Have records at hand when
      regulators demand it!
    </div>
    <div class="icon-col">
      <h1 class="icon-desc">No Upfront Investment</h1>
      <img :src="icon2" alt="" class="icon" />
    </div>
  </section>
  <section class="tabs">
    <h1 class="heading">
      Unlocking Productivity:
      <span class="highlight-gr"
        >The advantages of voice-enabled applications for efficient, confident,
        and focused work</span
      >
    </h1>

    <button class="tablink" @click="openPage('Home', 0, '#F7941D')">
      Simpler
    </button>
    <button class="tablink" @click="openPage('News', 1, '#F7941D')" id="first">
      Quicker
    </button>
    <button class="tablink" @click="openPage('Contact', 2, '#F7941D')">
      safe
    </button>
    <button class="tablink" @click="openPage('About', 3, '#F7941D')">
      precise
    </button>
    <button class="tablink" @click="openPage('Media', 4, '#F7941D')">
      Media Integration
    </button>

    <div id="Home" class="tabcontent">
      <div class="icon-col">
        <img :src="iconsimpler" class="icon" alt="" />
      </div>
      <p>
        Voice-directed systems are easier for workers to learn. They receive
        commands and can respond naturally in their language of choice. New
        workers feel more confident from day one that they can handle the
        demands of the job, aiding in retention.
      </p>
    </div>

    <div id="News" class="tabcontent">
      <div class="icon-col">
        <img :src="iconquicker" class="icon" alt="" />
      </div>
      <p>
        Voice-enabled applications are faster to work with, increasing
        productivity. Hands are free to handle the work to be done. Faster to
        train new workers.
      </p>
    </div>

    <div id="Contact" class="tabcontent">
      <div class="icon-col">
        <img :src="iconsafe" class="icon" alt="" />
      </div>
      <p>
        When workers don’t have to shift their visual attention to read screens
        or peck at keys, they can pay closer attention to where they are walking
        and working.
      </p>
    </div>

    <div id="About" class="tabcontent">
      <div class="icon-col">
        <img :src="iconprecise" class="icon" alt="" />
      </div>
      <p>
        Keeping workers focused on the work at hand instead of the gear in their
        hands, commonly improves accuracy. Voice-enabled workflows in the
        service area help ensure every inspection is done correctly each time.
      </p>
    </div>

    <div id="Media" class="tabcontent">
      <div class="icon-col">
        <img :src="iconmediaintegration" class="icon" alt="" />
      </div>
      <p>
        Easily call up images & videos to guide the M&I process. Capture images
        & videos to document the completed work.
      </p>
    </div>
  </section>
  <section class="cards">
    <h1 class="heading">
      Optimized for Challenging Environments and Tailored Workflows​.
    </h1>
    <div class="card-deck">
      <div class="card">
        <h1 class="card-title">Adaptability to Extreme Conditions</h1>
        <p class="card-content">
          Almasons M&I gear and solutions thrive in extreme conditions. Rugged
          Handhelds and specially-designed noise-canceling headsets are no
          problem in dirty, wet, cold, high-heat environments.
        </p>
      </div>
      <div class="card">
        <h1 class="card-title">Workflow Customization</h1>
        <p class="card-content">
          Easily customized means no need for extensive IT support. Keep your
          processes and equipment lists always up to date with our DIY system.
        </p>
      </div>
      <div class="card">
        <h1 class="card-title">Cloud-Based Solution</h1>
        <p class="card-content">
          Ensures broad accessibility and real-time updates. No need for special
          server hardware. View M&I status from anywhere at any time.
        </p>
      </div>
    </div>
  </section>
<ContactForm/>
</template>

<script>
// import bannerImg from "../assets/m&i/banner-img.png";
import bannerImg from "../assets/m&i/MandIheadsetoverlay.png";
import icon1 from "../assets/m&i/icon-1.png";
import icon2 from "../assets/m&i/icon-2.png";
import icon3 from "../assets/m&i/icon-3.png";
import play from "../assets/m&i/play.png";
import iconsimpler from "../assets/m&i/tab-icon.png";
import iconquicker from "../assets/m&i/quicker.png";
import iconsafe from "../assets/m&i/safe.png";
import iconprecise from "../assets/m&i/precise.png";
import iconmediaintegration from "../assets/m&i/media-integration.png";
import ContactForm from "@/components/ContactForm.vue"
export default {
  data() {
    return {
      bannerImg: bannerImg,
      icon1: icon1,
      icon2: icon2,
      icon3: icon3,
      play: play,
      iconsimpler: iconsimpler,
      iconquicker: iconquicker,
      iconsafe: iconsafe,
      iconprecise: iconprecise,
      iconmediaintegration: iconmediaintegration,
    };
  },
  components:{
    ContactForm,
  },
  methods: {
    openPage(pageName, elmnt, color) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      tablinks = document.getElementsByClassName("tablink");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].style.backgroundColor = "";
      }

      document.getElementById(pageName).style.display = "grid";

      tablinks[elmnt].style.backgroundColor = color;
    },
  },
  mounted() {
    document.getElementById("first").click();

    window.scrollTo({
      top: 0,
    });
  },
};
</script>

<!-- TABS -->
<style scoped>
.tabs {
  padding: 40px 120px;
  background: url("../assets/m&i/hero-bkg.png");
  background-size: cover;
}

.tabs .heading {
  color: #272827;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin: 0 0 20px;
}
.tablink {
  background-color: #999;
  border: 2px solid #888;
  color: white;
  float: left;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  /* border: none; */
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-size: 17px;
  width: 20%;
}



.tablink:hover {
  background-color: #777;
}

.tabcontent {
  display: none;
  grid-template-columns: 1fr 3fr;
  padding: 40px 20px;
  align-items: center;
  height: 100%;
}

.tabcontent p {
  color: #272827;
  text-align: justify;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tabcontent .icon {
  width: 150px;
  height: 130px;
}

@media screen and (max-width: 576px) {
  .tabs {
    padding: 40px;
  }

  .tabs .heading {
    font-size: 20px;
    margin: 0 0 20px;
  }

  .tablink {
    text-transform: capitalize;
    letter-spacing: normal;
    padding: 10px 5px;
    font-size: 14px;
  }

  .tabcontent {
    grid-template-columns: 1fr;
    padding: 40px 20px;
    gap: 10px;
  }

  .tabcontent p {
    text-align: left;
    font-size: 18px;
  }
}
</style>

<!-- GENERAL -->
<style scoped>
.highlight-gr {
  background: linear-gradient(90deg, #f7941d 0%, #f15b2b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

<!-- HERO SECTION STYLES -->
<style scoped>
.hero-section {
  margin-top: 65px;
  padding: 60px 120px;
  background: url("../assets/m&i/hero-bkg.png");
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 30px;
}

/* .hero-section .heading {
  color: #3c3c3c;
  text-align: left;
  font-family: Poppins;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.hero-section .sub-heading {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  background: linear-gradient(
    90deg,
    #f15b2b -0.8%,
    #f36b27 64.31%,
    #f7941d 97.81%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 20px 0;
}

.hero-section .content {
  color: #464646;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
} */

.hero-section .heading {
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: uppercase; */
  /* background: linear-gradient(0deg, #f15b2b 14.88%, #f7941d 30.67%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: black;
}

.hero-section .subheading{
  font-family: Poppins;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: uppercase; */
  color: #000000c5 ;
}

.hero-section .content {
  color: #171717;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem; /* 160% */
  margin-top: 10px;
}

.hero-section .image-col {
  text-align: right;
}

.hero-section .image-col .section-img {
  width: 100%;
}

@media screen and (max-width: 576px) {
  .hero-section {
    margin-top: 65px;
    padding: 40px;
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .hero-section .information-col .heading {
    font-size: 24px;
  }

  .hero-section .information-col .subheading {
    font-family: Poppins;
    font-size: 18px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* text-transform: uppercase; */
    color: #000000c5;
}

  .hero-section .information-col .content {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 160% */
  }

  .hero-section .image-col {
    text-align: center;
  }

  .hero-section .image-col img {
    width: 100%;
  }
}
</style>

<!-- BANNER -->
<style scoped>
.banner {
  padding: 40px 120px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;
}

.banner .heading {
  color: #272827;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.banner .image-col {
  text-align: right;
}

@media screen and (max-width: 576px) {
  .banner {
    padding: 40px;
    grid-template-columns: 1fr;
  }

  .banner .heading {
    font-size: 24px;
  }
}
</style>

<!-- TWO GRID -->
<style scoped>
.two-grid {
  padding-top: 40px;
  padding-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 40px;
}

.two-grid .icon-col {
  grid-column: 5/7;
  padding-left: 0px;
  padding-right: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.two-grid .icon-col .icon-desc {
  color: #2b2b2b;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.two-grid .info-col {
  display: flex;
  align-items: center;
  padding-left: 120px;
  padding-right: 40px;
  grid-column: 1/5;
  border-radius: 0px 5000px 5000px 0px;
  background: linear-gradient(
    180deg,
    rgba(247, 148, 29, 0.4) 0%,
    rgba(241, 91, 43, 0.4) 100%
  );
}

.two-grid .info-col {
  color: #000;
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.two-grid .icon-col.reverse {
  grid-column: 1/3 !important;
  padding-left: 120px;
  padding-right: 0px;
}

.two-grid .info-col.reverse {
  grid-column: 3/7;
  padding-left: 70px;
  padding-right: 120px;
  border-radius: 5000px 0px 0px 5000px;
  background: linear-gradient(
    180deg,
    rgba(247, 148, 29, 0.4) 0%,
    rgba(241, 91, 43, 0.4) 100%
  );
}

@media screen and (max-width: 576px) {
  .two-grid {
    padding-top: 40px;
    padding-bottom: 40px;
    display: grid;
    grid-template-columns: 1fr;
  }

  .two-grid .icon-col {
    grid-column: 1/2;
    padding: 0px;
    display: none;
  }

  .two-grid .icon-col .icon-desc {
    font-size: 20px;
  }

  .two-grid .info-col {
    justify-content: center;
    padding: 40px;
    width: 100%;
    grid-column: 1/2;
  }

  .two-grid .info-col {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .two-grid .icon-col.reverse {
    grid-column: 1/2 !important;
    padding: 0px;
  }

  .two-grid .info-col.reverse {
    grid-column: 1/2;
    padding: 40px;
  }
}
</style>

<!-- CARDS -->
<style scoped>
.cards {
  padding: 60px 120px;
}

.cards .heading {
  color: #272827;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
}

.cards .card-deck {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 60px;
}

.cards .card-deck .card {
  border-top: 5px solid #f7941d;
  background: linear-gradient(
    180deg,
    rgba(247, 148, 29, 0.9) 0%,
    rgba(247, 148, 29, 0.29) 0.01%,
    rgba(247, 148, 29, 0) 100%
  );
  box-shadow: -4px 4px 4px 0px rgba(0, 0, 0, 0.25),
    4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
}

.cards .card-deck .card .card-title {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}
.cards .card-deck .card .card-content {
  color: #000;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 576px) {
  .cards {
    padding: 40px;
  }

  .cards .heading {
    font-size: 20px;
    margin-bottom: 30px;
  }

  .cards .card-deck {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}
</style>

<!-- KEY FEATURES -->
<style scoped>
.key-features {
  padding: 40px 120px;
  background: url("../assets/m&i/key-bkg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.key-features .heading {
  color: #000;
  text-align: justify;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.key-features .content {
  color: #000;
  text-align: justify;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.key-features .points-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.key-features .points-grid .point {
  display: flex;
  align-items: center;
  gap: 20px;
}

.key-features .points-grid .point img {
  height: 25px;
}

@media screen and (max-width: 576px) {
  .key-features {
    padding: 40px;
  }

  .key-features .heading {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .key-features .content {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
  }

  .key-features .points-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .key-features .points-grid .point {
    display: flex;
    gap: 20px;
  }
}
</style>

<!-- OSHA -->
<style scoped>
.osha {
  padding: 60px 120px;
  background: url("../assets/m&i/hero-bkg.png");
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 120px;
  align-items: center;
}

.osha .heading {
  color: #272827;
  text-align: justify;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.osha .content {
  color: #000;
  text-align: justify;
  font-family: Poppins;
  font-size: 1rem;
  line-height: 2rem;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
}

@media screen and (max-width: 576px) {
  .osha {
    padding: 40px;
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .osha .heading {
    text-align: left;
    font-size: 24px;
  }

  .osha .content {
    text-align: left;
    font-size: 1rem;
  }
}
</style>
