<template>
  <section class="hero-section">
    <HighlightedPost :post="highlightPost" />

    <div class="featured-section">
      <h4 class="title">Featured Posts</h4>
      <FeaturedCard
        v-for="blog in blogs.slice(-4)"
        :key="blog.id"
        :detail="blog"
      />
    </div>
  </section>
</template>

  <script>
import HighlightedPost from "./HighlightedPost.vue";
import FeaturedCard from "./FeaturedCard.vue";

export default {
  data() {
    return {
      highlightPost: "",
    };
  },
  components: {
    HighlightedPost,
    FeaturedCard,
  },
  props: {
    blogs: Array,
  },
  created() {
    for (let i = 0; i < this.blogs.length; i++) {
      if (this.highlightPost == "") {
        if (this.blogs[i].highlight) {
          this.highlightPost = this.blogs[i];
        }
      }
    }
  },
};
</script>

  <style scoped>
.hero-section {
  margin-top: 65px;
  padding: 30px 120px;
  display: grid;
  gap: 30px;
  grid-template-columns: 2fr 1fr;
}

.featured-section {
  padding: 20px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.featured-section .title {
  font-size: 16px;
  color: #272827;
  opacity: 0.7;
  font-weight: 600;
}
</style>