<template>
  <!-- HERO SECTION -->
  <div class="hero-section">
    <div class="information-col">
      <h1 class="heading">
         <span style="color: black;"> ALMASONS GiNi™ </span><span class="highlight-gr">LABOR MANAGEMENT & DASHBOARDS</span>
      </h1>
      <h2 class="subheading">Efficient Team Management, Insightful Dashboards.</h2>
     
      <p class="content">
        Capture second-by-second data on each worker's status. Use our Analytics
        engine to discover wasted motion, workflows that need to be streamlined,
        and study how one worker, team, or site compares to others.
      </p>
      <!-- jnjdvd -->
      <!-- <h2 class="product-name">
        ALMASONS GiNi™ <br />
        labor management and dashboards
        
      </h2>
      <h1 class="heading">Efficient Team Management, Insightful Dashboards.</h1>
      <p class="content">
        Capture second-by-second data on each worker's status. Use our Analytics
        engine to discover wasted motion, workflows that need to be streamlined,
        and study how one worker, team, or site compares to others.
      </p> -->
    </div>
    <div class="image-col">
      <img :src="heroImg" alt="" class="hero-img" />
    </div>
  </div>

  <!-- TEXT BLOCK -->
  <div class="text-block bkg-1">
    <p class="content">
      <span class="highlight-gr">Almasons Labor Management Solution</span>
      includes tools to track worker activity at a detailed step-by-step,
      second-by-second level. This data is analyzed and presented in table data
      format and in graphical dashboard styles of your choosing. We have
      multiple dashboard templates created which we easily adapt to your unique
      requirements and various viewer types, including dashboards for worker
      viewing in your Warehouse or Factory Floor.
    </p>
  </div>

  <!-- KPI -->
  <div class="kpi">
    <h1 class="heading">
      capture data<span class="highlight-gr"> second by second </span>
    </h1>

    <div class="information-col">
      <img :src="kpi" alt="" class="kpi-icon" />
      <p class="content">
        Capture worker status second by second into buckets of time to study
        where time and effort are wasted, to compare one worker, team, site vs.
        another. Supports data-based Quota setting and worker coaching efforts.
      </p>
    </div>
    <div class="card-col">
      <div class="card">
        <p class="text">Assigned Work</p>
      </div>
      <div class="card">
        <p class="text">Travel Time</p>
      </div>
      <div class="card">
        <p class="text">Palletizing</p>
      </div>
      <div class="card">
        <p class="text">Waiting To Move</p>
      </div>
      <div class="card">
        <p class="text">Training</p>
      </div>
      <div class="card">
        <p class="text">Equipment Inspection</p>
      </div>
      <div class="card">
        <p class="text">Replenishment</p>
      </div>
      <div class="card">
        <p class="text">Temporary Assignment</p>
      </div>
      <div class="card">
        <p class="text">Housekeeping</p>
      </div>
      <div class="card">
        <p class="text">Break</p>
      </div>
      <div class="card">
        <p class="text">Lunch</p>
      </div>
      <div class="card">
        <p class="text">Idle Time</p>
      </div>
    </div>
  </div>

  <!-- TEXT IMAGE -->
  <ImageTextSection
    backgroundImg="one"
    alignment="right"
    heading1="Interactive Dashboards for Insightful "
    heading2="Team management"
    subheading=""
    content="Almasons GiNi™ Labor Management’s interactive dashboards provide a comprehensive and visually appealing overview of team activities. Navigating through these dashboards through our user-friendly interface."
    :image="graph1"
    :buttons="[]"
  />

  <!-- TEXT IMAGE -->
  <ImageTextSection
    backgroundImg=""
    alignment="left"
    heading1="CUSTOMISABLE DASHBOARDS "
    heading2="SOLUTIONS"
    subheading=""
    content="Almasons allows tailored dashboards, prioritizing diverse client needs. Empower team leaders and members with customized insights, enabling a focused approach to address specific challenges and opportunities efficiently."
    :image="graph2"
    :buttons="[]"
  />

  <!-- TEXT IMAGE -->
  <ImageTextSection
    backgroundImg="one"
    alignment="right"
    heading1="REAL TIME "
    heading2="mETRICS"
    subheading=""
    content="Real-time metrics offer dynamic insights that turn your business into an organized, data-driven force. Monitor inventory, optimize processes, and enhance customer engagement promptly with our real time data feature."
    :image="graph3"
    :buttons="[]"
  />

  <!-- TEXT IMAGE -->
  <ImageTextSection
    backgroundImg=""
    alignment="left"
    heading1="DATA DRIVEN "
    heading2="Decision making"
    subheading=""
    content="Almasons GiNi™ Labor Management enables swift decision-making in the  dynamic environment of the warehouses. Optimize tasks, address exceptions, and adjust resources promptly with real-time metrics for peak efficiency."
    :image="graph4"
    :buttons="[]"
  />

  <!-- BANNER -->
  <div class="banner-1">
    <p class="content">
      Unlock a free trial/demo. Experience heightened team productivity and
      elevate your warehouse management - Seize the opportunity today!
    </p>
    <!-- <ButtonPrimary
      :icon="true"
      text="Free trial"
      iconClass="fa-regular fa-calendar"
      btnLink="/contact-us"
    /> -->
  </div>

  <!-- BANNER -->

  <div class="banner-2">
    <h1 class="heading">
      Contact Us for Your <span class="highlight-gr">transformation</span>
    </h1>
    <p class="content">
      Ready to revolutionize your team engagement and productivity with GiNi
      LM?<br />Contact us for inquiries, support, or sales
    </p>
    <ButtonPrimary
      text="Contact Us"
      iconClass=""
      btnLink="/contact-us"
    />
  </div>

  <!-- CONTACT US -->
  <ContactForm/>
</template>

<script>
import heroImg from "../assets/LM&D/lm&dash-hero.png";
import graph1 from "../assets/LM&D/graph01.png";
import graph2 from "../assets/LM&D/graph2.png";
import graph3 from "../assets/LM&D/graph3.png";
import graph4 from "../assets/datadrivendecisionmaking.png";
import kpi from "../assets/LM&D/kpi.svg";

import ImageTextSection from "@/components/ImageTextSection.vue";
import ButtonPrimary from "@/components/ButtonPrimary.vue";
import ContactForm from "@/components/ContactForm.vue"
export default {
  data() {
    return {
      kpi: kpi,
      heroImg: heroImg,
      graph1: graph1,
      graph2: graph2,
      graph3: graph3,
      graph4: graph4,
    };
  },
  components: {
    ImageTextSection,
    ButtonPrimary,
    ContactForm,
  },
  mounted() {
    window.scrollTo({
      top: 0,
    });
  },
};
</script>

<style scoped>
.highlight-gr {
  background: linear-gradient(90deg, #f7941d 0%, #f15b2b 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

<!-- HERO SECTION -->
<style scoped>
.hero-section {
  margin-top: 70px;
  padding: 60px 120px;
  /* height: calc(100vh - 100px); */
  background: url("../assets/LM&D/lm&d-hero-bkg.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center right;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 120px;
}

.hero-section .information-col{
    display: flex;
    flex-direction: column;
    gap: 22px;
  }

.hero-section .heading {
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: uppercase; */
  /* background: linear-gradient(0deg, #f15b2b 14.88%, #f7941d 30.67%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: black;
}

.hero-section .subheading{
  font-family: Poppins;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: uppercase; */
  color: #000000c5 ;
}

.hero-section .content {
  color: #171717;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem; /* 160% */
  margin-top: 10px;
}



/* .hero-section .heading {
  color: #3f3f3f;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px; 
  text-transform: uppercase;
  margin-bottom: 20px;
} */

/* .hero-section .product-name {
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  background: linear-gradient(92deg, #f15b2b 1.54%, #f7941d 78.68%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
} */

/* .hero-section .content {
  margin-top: 20px;
  color: #292929;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; 
  text-transform: capitalize;
} */

.hero-section .image-col {
  text-align: right;
}

.hero-section .image-col .hero-img {
  width: 100%;
}

@media screen and (max-width: 576px) {
  .hero-section {
    padding: 40px;
    background-size: cover;
    background-position: center left;
    grid-template-columns: 1fr;
    gap: 40px;
  }



  .hero-section .information-col .heading {
    font-size: 24px;
  }

  .hero-section .information-col .subheading {
    font-family: Poppins;
    font-size: 18px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* text-transform: uppercase; */
    color: #000000c5;
}

  .hero-section .information-col .content {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 160% */
  }

  /* .hero-section .heading {
    font-size: 20px;
    line-height: normal;
  }

  .hero-section .product-name {
    font-size: 24px;
  }

  .hero-section .content {
    font-size: 18px;
    line-height: normal;
  } */

  .hero-section .image-col {
    text-align: center;
  }
}
</style>

<!-- TEXT BLOCK -->
<style scoped>
.text-block {
  padding: 40px 120px;
}

.text-block.bkg-1 {
  background: url("../assets/LM&D/section-bkg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.text-block .content {
  text-align: center;
  font-family: Poppins;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (max-width: 576px) {
  .text-block {
    padding: 40px;
  }

  .text-block .content {
    text-align: left;
    font-size: 18px;
    line-height: 28px;
  }
}
</style>

<!-- KPI -->
<style scoped>
.kpi .heading {
  color: #272827;
  text-align: justify;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  grid-column: 1/3;
  grid-row: 1/2;
  text-align: center;
}

.kpi {
  padding: 60px 120px;
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  gap: 60px;
}

.kpi .information-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 30px;
}

.kpi .information-col .content {
  color: #272827;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.kpi .card-col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
}

.kpi .card-col .card {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 4px 4px 4px 0px #f7941d, -4px -4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
}

.kpi .kpi-icon {
  height: 180px;
  margin-bottom: 20px;
}

@media screen and (max-width: 576px) {
  .kpi .heading {
    font-size: 24px;
    grid-column: 1/2;
    text-align: center;
  }

  .kpi {
    padding: 40px;
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .kpi .information-col {
    gap: 20px;
  }

  .kpi .card-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
}
</style>

<!-- BANNER 1 & 2 -->
<style scoped>
.banner-1 {
  padding: 40px 120px;
  background: linear-gradient(
    180deg,
    rgba(247, 148, 29, 0.45) 0%,
    rgba(241, 91, 43, 0.45) 100%
  );
  text-align: center;
}

.banner-1 .content {
  color: #131313;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 30px;
}

.banner-2 {
  padding: 40px 120px;
  text-align: center;
}

.banner-2 .heading {
  color: #272827;
  font-size: 38px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.banner-2 .content {
  color: #343434;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 20px;
}

@media screen and (max-width: 576px) {
  .banner-1 {
    padding: 40px;
    text-align: center;
  }

  .banner-1 .content {
    font-size: 20px;
  }

  .banner-2 {
    padding: 40px;
    text-align: center;
  }

  .banner-2 .heading {
    font-size: 24px;
  }

  .banner-2 .content {
    font-size: 18px;
  }
}
</style>
